import _ from 'lodash'
import { useCallback, useId, useMemo, useState } from 'react'
import { projectHelper, userHelper } from '../common/src/helpers'
import { copyToClipboard } from '../common/src/helpers/clipboardUtils'
import {
  useCreateNewProject,
  useCreateNewProjectFromCSV,
  useImportCSVToProject,
} from '../common/src/hooks/projectHooks'
import { useSendStepEventIfNeeded } from './onboardingHooks'
import { uriDataCreator } from '../common/src/helpers/URLHelper'
import { getCSVExportURL, PM_API_RESOURCE_TYPE, PM_API_URLS, SUBSCRIBE_CALENDAR_URL } from '../common/src/constants'
import { linkSubject } from '../reactions/linkSubject'
import { OnboardingStepKeys } from '../actions/onboardingActions'
import { useShowToastDidCopyLinkToClipboard } from './clipboardHooks'
import { Trans, useTranslation } from 'react-i18next'
import { getRelativeURLKeepingQuerySearch } from '../helpers/routeHelper'
import { useRouter } from './useRouterHook'
import { useDispatch } from 'react-redux'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../common/src/eventTracking/amplitudeEvents'
import { extendPMHeaders, getCredentialsConfig } from '../common/src/helpers/requestHelper'
import { Link, useToastController } from '@fluentui/react-components'
import { FluentToast } from '../components/toast/FluentToast'
import { sortProjectIDs } from '../common/src/actions/projectsAPI'
import { useDownloadFiles } from '@/hooks/filesHooks'

export const useCreateNewProjectCustom = () => {
  const toastId = useId()
  const { dispatchToast, updateToast } = useToastController()
  const createNewProject = useCreateNewProject()
  const sendStepEventIfNeeded = useSendStepEventIfNeeded()
  const { t } = useTranslation()

  const onCreateByDefault = useCallback(serverID => {
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, serverID)
    linkSubject.next({ urlData })
  }, [])

  return useCallback(
    async ({ open = true, notify = true, delayToOpen = 0, onCreate = onCreateByDefault, ...rest }) => {
      if (notify) {
        dispatchToast(<FluentToast>{t('project.creating')}</FluentToast>, {
          toastId,
          intent: 'success',
          timeout: -1,
        })
      }

      const response = await createNewProject(rest)
      const { error, payload } = response
      if (!error) {
        // Open project
        if (open) {
          const projectID = projectHelper.getIdd(payload)
          if (delayToOpen > 0) {
            setTimeout(() => {
              onCreate(projectID)
            }, delayToOpen)
          } else {
            onCreate(projectID)
          }
        }

        // Send onboarding step event
        sendStepEventIfNeeded(OnboardingStepKeys.CREATE_FIRST_PROJECT)
      }

      if (notify) {
        updateToast({
          toastId,
          content: (
            <FluentToast>{error ? t('project.created_unsuccessfully') : t('project.created_successfully')}</FluentToast>
          ),
          intent: error ? 'error' : 'success',
          timeout: 5000,
        })
      }

      return response
    },
    [createNewProject, dispatchToast, onCreateByDefault, sendStepEventIfNeeded, t, toastId, updateToast]
  )
}

export const useCreateNewProjectFromCSVAdvanced = () => {
  const { dispatchToast, updateToast } = useToastController()
  const createNewProjectFromCSV = useCreateNewProjectFromCSV()
  const sendStepEventIfNeeded = useSendStepEventIfNeeded()
  const { t } = useTranslation()
  const toastId = useId()

  const onCreateByDefault = useCallback(serverID => {
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, serverID)
    linkSubject.next({ urlData })
  }, [])

  return useCallback(
    async ({ open = true, notify = true, delayToOpen = 0, onCreate = onCreateByDefault, ...rest }) => {
      if (notify) {
        dispatchToast(<FluentToast>{t('project.importing_csv_loading')}</FluentToast>, {
          toastId,
          intent: 'info',
          timeout: -1,
        })
      }

      const response = await createNewProjectFromCSV(rest)
      const { error, payload } = response
      if (!error) {
        // Open project
        if (open) {
          const projectID = projectHelper.getIdd(payload)
          if (delayToOpen > 0) {
            setTimeout(() => {
              onCreate(projectID)
            }, delayToOpen)
          } else {
            onCreate(projectID)
          }
        }

        // Send onboarding step event
        sendStepEventIfNeeded(OnboardingStepKeys.CREATE_FIRST_PROJECT)
      }

      if (notify) {
        updateToast({
          toastId,
          content: (
            <FluentToast>{error ? t('project.importing_csv_failure') : t('project.importing_csv_success')}</FluentToast>
          ),
          intent: error ? 'error' : 'success',
          timeout: 5000,
        })
      }

      return response
    },
    [createNewProjectFromCSV, dispatchToast, onCreateByDefault, sendStepEventIfNeeded, t, toastId, updateToast]
  )
}

export const useImportCSVToProjectWithToast = () => {
  const { dispatchToast, updateToast } = useToastController()
  const importCSVToProject = useImportCSVToProject()
  const { t } = useTranslation()
  const toastId = useId()

  return useCallback(
    async ({ notify = true, ...rest }) => {
      if (notify) {
        dispatchToast(<FluentToast>{t('project.importing_csv_loading')}</FluentToast>, {
          toastId,
          intent: 'success',
          timeout: -1,
        })
      }

      const response = await importCSVToProject(rest)
      const { error } = response

      if (notify) {
        updateToast({
          toastId,
          content: (
            <FluentToast>{error ? t('project.importing_csv_failure') : t('project.importing_csv_success')}</FluentToast>
          ),
          intent: error ? 'error' : 'success',
          timeout: 5000,
        })
      }

      return response
    },
    [dispatchToast, importCSVToProject, t, toastId, updateToast]
  )
}

const secretEmailAddressMenuKeys = {
  byDefault: 'byDefault',
}

export const useSubMenuProjectSecretEmailAddress = (project, prefix = '') => {
  const isValid = !!projectHelper.getIdd(project)
  const { t } = useTranslation()

  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const createOnClickForQuadrant = useCallback(
    (q, user) => () => {
      const userID = userHelper.getID(user) || ''
      const userHash = userHelper.getUserHash(user)
      const link = projectHelper.getSecretEmailAddress(project, q, userHash, userID)
      copyToClipboard(link)
      showToastDidCopyLinkToClipboard()
    },
    [project, showToastDidCopyLinkToClipboard]
  )

  return useMemo(() => {
    if (!isValid) {
      return null
    }

    const defaultKey = `${prefix}_${secretEmailAddressMenuKeys.byDefault}`
    const projectItem = {
      id: defaultKey,
      key: defaultKey,
      text: t('project.menu.for_this_project'),
      onClick: createOnClickForQuadrant(3),
    }

    const quadrantsItems = _.map([0, 1, 2, 3], q => {
      const key = `${defaultKey}_` + q
      return {
        id: key,
        key: key,
        text: t('project.menu.for_quadrant') + ` ${q + 1}...`,
        onClick: createOnClickForQuadrant(q),
      }
    })

    return {
      items: [projectItem, ...quadrantsItems],
    }
  }, [isValid, prefix, t, createOnClickForQuadrant])
}

export const useOpenProject = () => {
  const navigateToProject = useCallback(project => {
    // Here, we still use linkSubject for backward compatibility.
    // The direct navigation is implemented in useFastProjectsMenu.js to handle
    // the specific case of the left panel navigation.
    const id = projectHelper.getIdd(project)
    const urlData = uriDataCreator(1, 'project', id)
    linkSubject.next({ urlData })
  }, [])

  return useCallback(
    project => {
      navigateToProject(project)
    },
    [navigateToProject]
  )
}

export const useShowCreateProjectView = mode => {
  const { history, location } = useRouter()
  const dispatch = useDispatch()
  return useCallback(() => {
    const path = getRelativeURLKeepingQuerySearch.newProjectApp()
    history.push(path, { from: location.pathname })
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.CREATE_PROJECT, { mode }))
  }, [dispatch, history, location.pathname, mode])
}

export const useLinkToCreateProject = mode => {
  const dispatch = useDispatch()
  return useMemo(
    () => ({
      to: getRelativeURLKeepingQuerySearch.newProjectApp(),
      onClick: () => dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.CREATE_PROJECT, { mode })),
    }),
    [dispatch, mode]
  )
}

const getCalendarLinkFromAPI = projectID =>
  fetch(_.template(PM_API_URLS.CREATE_CALENDAR_LINK)({ id: projectID }), {
    credentials: getCredentialsConfig(),
    headers: extendPMHeaders(),
    method: 'GET',
  })

export const useObtainCalendarLink = projectID => {
  const [calendarLink, setCalendarLink] = useState({})
  const [isLoadingCalendarLink, setIsLoadingCalendarLink] = useState(false)
  const { dispatchToast } = useToastController()
  const { t } = useTranslation()

  const toastContent = useMemo(
    () => (
      <div className="text-pm-white">
        <Trans
          t={t}
          i18nKey="project_detail.subscribe_calendar_body"
          components={{
            mylink: <Link href={SUBSCRIBE_CALENDAR_URL} target="_blank" />,
          }}
        />
      </div>
    ),
    [t]
  )

  const calendarLinkForProject = calendarLink[projectID]
  const getCalendarLink = useCallback(async () => {
    if (_.isNil(projectID)) {
      return null
    }
    if (!calendarLinkForProject) {
      setIsLoadingCalendarLink(true)
      const response = await getCalendarLinkFromAPI(projectID)
      if (!response.ok) {
        setIsLoadingCalendarLink(false)
        return null
      }
      const responseText = await response.text()
      setIsLoadingCalendarLink(false)
      setCalendarLink(links => ({ ...links, [projectID]: responseText }))
      return responseText
    }
    return calendarLinkForProject
  }, [projectID, calendarLinkForProject])

  const copyCalendarLink = useCallback(async () => {
    const link = await getCalendarLink()
    if (!link) {
      return
    }
    copyToClipboard(link)
    dispatchToast(<FluentToast body={toastContent}>{t('project_detail.subscribe_calendar_title')}</FluentToast>, {
      timeout: -1,
      intent: 'success',
    })
  }, [getCalendarLink, dispatchToast, toastContent, t])

  return { copyCalendarLink, calendarLink: calendarLink[projectID], getCalendarLink, isLoadingCalendarLink }
}

export const useOnProjectDragEnd = ({ currentProjects, setCurrentProjects }) => {
  const dispatch = useDispatch()
  return useCallback(
    ({ draggableId, destination, source, ...rest }) => {
      if (!destination || _.isEqual(source, destination)) {
        return
      }

      const fromIndex = source.index
      const toIndex = destination.index

      const nextProjects = [...currentProjects]
      nextProjects.splice(toIndex, 0, nextProjects.splice(fromIndex, 1)[0])
      setCurrentProjects(nextProjects)

      const projectIDs = nextProjects.map(projectHelper.getIdd)
      dispatch(sortProjectIDs(projectIDs))
    },
    [currentProjects, dispatch, setCurrentProjects]
  )
}

export const useExportToCSV = project => {
  const { commonDownload: downloadFiles } = useDownloadFiles()
  const dispatch = useDispatch()
  const projectName = projectHelper.getName(project)
  const urlExportToCSV = getCSVExportURL(projectHelper.getIdd(project))

  return () => {
    downloadFiles([{ filename: `${projectName.replace(/ /g, '')}.csv`, url: urlExportToCSV }])
    dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.CSV_EXPORT_PROJECT))
  }
}
