import { useCallback, useMemo, useReducer, useState } from 'react'
import _ from 'lodash'
import { SearchItemsPanel } from '../../searchPanel/SearchItemsPanel'
import { ItemLinkPanelContext } from '../../../contexts'
import { getRelatedItems } from '../../../common/src/actions/itemsAPI'
import { itemHelper, stateHelper, URLHelper } from '../../../common/src/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { updateLinks } from '../../../common/src/actions/linksAPI'
import { CreateItemLinkSection } from '../../../views/modal/createItem/CreateItemLinkSection'

const baseState = {
  show: false,
  itemId: undefined,
  linkedItem: { dependency: 0 },
}

export const LINK_PANEL_ACTION_TYPES = {
  SHOW_ADD_LINK: 'init',
  SET_LINKED_ITEM: 'set_linked_item',
  DISMISS: 'dismiss',
  SHOW_EDIT_DEPENDENCY: 'edit_dependency',
}

const reducer = (state, action) => {
  switch (action.type) {
    case LINK_PANEL_ACTION_TYPES.SHOW_ADD_LINK:
      return {
        show: true,
        itemId: action.payload.itemId,
        linkedItem: { dependency: state.linkedItem?.dependency },
      }
    case LINK_PANEL_ACTION_TYPES.SHOW_EDIT_DEPENDENCY:
      return {
        show: true,
        itemId: action.payload.itemId,
        linkedItem: action.payload.linkedItem,
      }
    case LINK_PANEL_ACTION_TYPES.SET_LINKED_ITEM:
      return { ...state, linkedItem: action.payload.link }
    case LINK_PANEL_ACTION_TYPES.DISMISS:
      return { ...state, show: false }
    default:
      return state
  }
}

export const ItemLinkPanelSingleton = ({ children }) => {
  const [state, localDispatch] = useReducer(reducer, baseState)
  const [loading, setLoading] = useState(false)
  const contextValue = { dispatch: localDispatch, loading }
  const { show, itemId, linkedItem } = state
  const fetchRelatedItems = useCallback(() => getRelatedItems(itemId), [itemId])
  const getRelatedItemsFromState = useMemo(() => _.partial(stateHelper.getRelatedItems, _, itemId), [itemId])
  const handleDismiss = useCallback(() => localDispatch({ type: LINK_PANEL_ACTION_TYPES.DISMISS }), [localDispatch])
  const getLinksSelector = useCallback(state => stateHelper.getAssociatedLinksForItem(state, itemId), [itemId])
  const links = useSelector(getLinksSelector)
  const linksItems = useMemo(() => links.toJSON(), [links])
  const dispatch = useDispatch()
  const dependency = linkedItem?.dependency
  const linkedItemUri = URLHelper.resourceURIParser(linkedItem?.linked_item)
  const linkedItemId = linkedItemUri?.id
  const linkedItemObject = useSelector(state => stateHelper.getItem(state, linkedItemId))
  const currentItem = useSelector(state => stateHelper.getItem(state, itemId))

  const onChange = useCallback(
    async link => {
      const oldLinks = link.id ? _.filter(linksItems, l => l.id !== link.id) : linksItems
      const newLinks = [...oldLinks, link]
      setLoading(true)
      await dispatch(updateLinks(itemId, newLinks))
      setLoading(false)
    },
    [dispatch, itemId, linksItems]
  )

  const onAddItem = useCallback(
    async itemToLink => {
      const { dependency } = linkedItem
      const itemLink = {
        name: itemHelper.getName(itemToLink),
        linked_item: itemHelper.getResourceURI(itemToLink),
        dependency: dependency,
      }
      onChange(itemLink)
      localDispatch({ type: LINK_PANEL_ACTION_TYPES.DISMISS })
    },
    [localDispatch, linkedItem, onChange]
  )

  const onChangeDependency = useCallback(
    (ev, newDependency) => {
      const link = { ...linkedItem, dependency: newDependency }
      localDispatch({ type: LINK_PANEL_ACTION_TYPES.SET_LINKED_ITEM, payload: { link } })
      if (link.id) {
        onChange(link)
      }
    },
    [localDispatch, linkedItem, onChange]
  )

  const subHeader = (
    <CreateItemLinkSection
      onChangeDependency={onChangeDependency}
      linkedItem={currentItem}
      itemBelow={linkedItemObject}
      dependency={dependency}
      explanationKeyPrefix="links_section.link_item_explanation"
      reversed
    />
  )

  return (
    <ItemLinkPanelContext.Provider value={contextValue}>
      <SearchItemsPanel
        hideItemId={itemId}
        isOpen={show}
        onDismiss={handleDismiss}
        onAddItem={onAddItem}
        onSelectItemProp={onAddItem} // onSelect will open the item until we fix the ItemPanel to allow load the correct item
        getInitialItems={fetchRelatedItems}
        getInitialItemsFromState={getRelatedItemsFromState}
        subHeader={subHeader}
      />
      {children}
    </ItemLinkPanelContext.Provider>
  )
}
