import React, { useCallback, useContext, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator'
import { itemHelper, linkDependencyHelper, stateHelper, URLHelper } from '../../common/src/helpers'
import { externalLinkHandler } from '../../utils/externalLinkHandler'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { deleteLink } from '../../common/src/actions/linksAPI'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { AlertModal } from '../../components/modal/AlertModal'
import { useTranslation } from 'react-i18next'
import { DateForResource } from './FilesSectionContent'
import { ItemLinkPanelContext } from '../../contexts'
import { LINK_PANEL_ACTION_TYPES } from '../../components/panels/item/ItemLinkPanelSingleton'
import { ResourcesSectionCard, Placeholder, SectionHeader, SectionTitle } from './resourcesSectionCommon'
import {
  Button,
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Link,
  TableCellLayout,
  Toolbar,
  ToolbarButton,
  Tooltip,
} from '@fluentui/react-components'
import { ClipboardLink, Delete, DocumentTextLink, Edit, Link as LinkIcon } from '../../components/BundledIcons'
import { useShowToastDidCopyLinkToClipboard } from '@/hooks/clipboardHooks'
import { copyToClipboard } from '@/common/src/helpers/clipboardUtils.js'

const LinksSectionCard = styled(ResourcesSectionCard)`
  & .actionButtonCell {
    padding: 0 4px;
    &:first-child {
      padding-left: 8px;
    }
  }
`

const TypeHeader = () => {
  const { t } = useTranslation()
  return <span className="pl-2">{t('links_section.type_column')}</span>
}
const LinkHeader = () => {
  const { t } = useTranslation()
  return t('links_section.name_column')
}

const CopyButton = ({ link }) => {
  const { t } = useTranslation()
  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const copyLink = useCallback(
    (event, link) => {
      copyToClipboard(link)
      showToastDidCopyLinkToClipboard()
    },
    [showToastDidCopyLinkToClipboard]
  )
  return (
    <Tooltip content={t('item.file.copy_link')} relationship="label">
      <Button appearance="subtle" icon={<ClipboardLink />} onClick={event => copyLink(event, link.link)} />
    </Tooltip>
  )
}

const COLUMN_WIDTH = {
  type: 50,
  actions: 100,
}

export const LinksSectionContent = ({
  item,
  readOnly = false,
  selectedLink,
  setSelectedLink,
  loading: ownLoading,
  setLoading,
  setShowLinkModalToEdit,
  linksItems,
  className,
}) => {
  const [state, setState] = useMergeState({
    showItemPanelToEdit: false,
    showAlertModalToDelete: false,
    lastSelectedDependency: 0,
  })
  const itemId = itemHelper.getId(item)
  const links = useSelector(state => stateHelper.getAssociatedLinksForItem(state, itemId))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showAlertModalToDelete } = state
  const { dispatch: itemLinkDispatch, loading: itemLinkPanelLoading } = useContext(ItemLinkPanelContext)
  const loading = ownLoading || itemLinkPanelLoading

  const onClickAddLink = useCallback(() => {
    setShowLinkModalToEdit(true)
    setSelectedLink({})
  }, [setShowLinkModalToEdit, setSelectedLink])

  const onClickAddItem = useCallback(() => {
    itemLinkDispatch({ type: LINK_PANEL_ACTION_TYPES.SHOW_ADD_LINK, payload: { itemId } })
  }, [itemId, itemLinkDispatch])

  const wantToDeleteSelectedLink = useCallback(
    link => {
      setState({ showAlertModalToDelete: true })
      setSelectedLink(link)
    },
    [setState, setSelectedLink]
  )

  const deleteSelectedLink = useCallback(async () => {
    setLoading(true)
    await dispatch(deleteLink(itemId, selectedLink.id))
    setLoading(false)
  }, [setLoading, dispatch, itemId, selectedLink.id])

  const columns = useMemo(
    () => [
      createTableColumn({
        columnId: 'type',
        renderHeaderCell: () => <TypeHeader />,
        renderCell: link => {
          const dependencyKind = link.linked_item ? linkDependencyHelper.getDependencyKind(link.dependency) : 'Link'
          return <div className="pl-2 italic text-pm-neutral-dark">{dependencyKind}</div>
        },
      }),
      createTableColumn({
        columnId: 'link',
        renderHeaderCell: () => <LinkHeader />,
        renderCell: link => {
          const onClickLink = link => {
            if (link.linked_item) {
              const r = URLHelper.resourceURIParser(link.linked_item)
              if (r?.id) {
                linkSubject.next({ urlData: r, source: SOURCES.ITEM_RESOURCES })
                return true
              }
            }
            return externalLinkHandler(link.link)
          }
          const onClick = evt => {
            const handled = onClickLink(link)
            if (handled) {
              evt.preventDefault()
            }
          }
          const completion = link?.linked_item_details?.completionPercentage
          const completionCN = cn({ pm_hidden: !completion })
          return (
            <TableCellLayout
              description={
                <>
                  <DateForResource timestamp={link.timestamp} />
                  <span className={completionCN}>&nbsp;-&nbsp;({completion}%)</span>
                </>
              }
            >
              <Link href={link.link} onClick={onClick} title={`${link.name} (${link.link})`} target="_blank">
                {link.name}
              </Link>
            </TableCellLayout>
          )
        },
      }),
      createTableColumn({
        columnId: 'actions',
        renderHeaderCell: () => null,
        renderCell: link => {
          const onClickEdit = () => {
            if (link.linked_item) {
              itemLinkDispatch({
                type: LINK_PANEL_ACTION_TYPES.SHOW_EDIT_DEPENDENCY,
                payload: { itemId, linkedItem: link },
              })
            } else {
              setShowLinkModalToEdit(true)
              setSelectedLink(link)
            }
          }
          const onClickDelete = () => wantToDeleteSelectedLink(link)
          return (
            <div className="flex w-full justify-end">
              <Tooltip content={t('item_detail.edit_link')} relationship="label">
                <Button appearance="subtle" onClick={onClickEdit} icon={<Edit />} />
              </Tooltip>
              <Tooltip content={t('item_detail.delete_link')} relationship="label">
                <Button appearance="subtle" onClick={onClickDelete} icon={<Delete />} />
              </Tooltip>
              <CopyButton link={link} />
            </div>
          )
        },
      }),
    ],
    [itemId, itemLinkDispatch, setSelectedLink, setShowLinkModalToEdit, t, wantToDeleteSelectedLink]
  )

  const alertModalSubText = useMemo(() => {
    const name = selectedLink.name || ''
    if (selectedLink.linked_item) {
      return t('links_section.delete_confirmation_linked_item', { name })
    }
    return t('links_section.delete_confirmation_linked_item', { name })
  }, [selectedLink, t])

  const placeholder = !readOnly && <Placeholder>{t('links_section.placeholder')}</Placeholder>

  return (
    <LinksSectionCard className={className}>
      <SectionHeader>
        <SectionTitle>{t('item_detail.links_section_title')}</SectionTitle>
        {!readOnly && (
          <Toolbar>
            <ToolbarButton disabled={loading} onClick={onClickAddItem} icon={<DocumentTextLink />}>
              {t('links_section.add_item')}
            </ToolbarButton>
            <ToolbarButton disabled={loading} onClick={onClickAddLink} icon={<LinkIcon />}>
              {t('links_section.add_link')}
            </ToolbarButton>
          </Toolbar>
        )}
      </SectionHeader>
      {loading && <ProgressIndicator />}
      {links.size > 0 ? (
        <DataGrid items={linksItems} columns={columns} noNativeElements={false} as="table">
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell, columnId }) => (
                <DataGridHeaderCell
                  className={columnId === 'actions' ? 'print:!hidden' : undefined}
                  style={{ width: COLUMN_WIDTH[columnId] }}
                >
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody>
            {({ item, rowId }) => (
              <DataGridRow key={rowId}>
                {({ renderCell, columnId }) => (
                  <DataGridCell
                    className={columnId === 'actions' ? 'print:!hidden' : undefined}
                    style={{ width: COLUMN_WIDTH[columnId] }}
                  >
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      ) : (
        placeholder
      )}
      <AlertModal
        title={t('links_section.delete_confirmation_title')}
        subText={alertModalSubText}
        open={showAlertModalToDelete}
        onDismiss={() => {
          setState({ showAlertModalToDelete: false })
          setSelectedLink({})
        }}
        primaryActionText={t('general.delete')}
        onPrimaryActionClick={deleteSelectedLink}
      />
    </LinksSectionCard>
  )
}
