import { Extension } from '@tiptap/react'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { find } from 'linkifyjs'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import { Node } from '@tiptap/pm/model'
import { captureLinkClick } from '@/utils/externalLinkHandler'
import { linkSubject, SOURCES } from '@/reactions/linkSubject'

function createDecorations(doc: Node) {
  const decorations: Decoration[] = []
  doc.descendants((node, pos) => {
    if (!node.isText) {
      return
    }
    const results = find(node.text ?? '', { defaultProtocol: 'https' })
    for (const result of results) {
      decorations.push(
        Decoration.inline(pos + result.start, pos + result.end, {
          nodeName: 'a',
          href: result.href,
          target: '_blank',
          rel: 'noopener noreferrer',
        })
      )
    }
  })
  return DecorationSet.create(doc, decorations)
}

export const Link = Extension.create({
  name: 'link',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('link'),
        state: {
          init(_, { doc }) {
            return createDecorations(doc)
          },
          apply(transaction, oldState) {
            if (!transaction.docChanged) {
              return oldState
            }
            return createDecorations(transaction.doc)
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)
          },
          handleDOMEvents: {
            // Avoid changing the selection when clicking on a link
            mousedown(view, event) {
              if (event.target instanceof HTMLAnchorElement) {
                event.preventDefault()
              }
            },
            // Force link to open: inside contenteditable, anchor elements are not clickable by default
            click(view, event) {
              if (event.target instanceof HTMLAnchorElement) {
                // @ts-expect-error
                captureLinkClick(urlData => {
                  // @ts-expect-error
                  linkSubject.next({ urlData, source: SOURCES.CHAT })
                })(event)
              }
            },
          },
        },
      }),
    ]
  },
})
