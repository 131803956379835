import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { cn } from '@appfluence/classnames'
import { withRouter } from 'react-router'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateMultipleUrlParams } from '../../hooks/filterHooks'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent, EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { Coachmark } from '@fluentui/react/lib/Coachmark'
import { DirectionalHint } from '@fluentui/react/lib/Callout'
import { projectHelper } from '../../common/src/helpers'
import { Default } from '../../helpers/responsiveHelpers'
import { TopRightBar } from '../../components/layout/TopRightBar'
import { ProjectLevelDropdown } from '../../components/dropdown/ProjectLevelDropdown'
import { ProjectMembersPanel } from '../../components/project/ProjectMembersPanel'
import { FilterButton } from '../../components/buttons/FilterButton'
import { ProjectModeSegmented } from '../../components/segmentedControls/ProjectModeSegmented'
import {
  getFiltersPanelByMode,
  getFiltersTypeByMode,
  getPathProjectModeFromPathname,
  pathProjectModeToMode,
  PROJECT_MODE,
  projectModeToPathMode,
} from '../../utils/projectModesUtils'
import {
  getAbsoluteURL,
  getPathnameKeepingQuerySearch,
  getRelativeURLKeepingQuerySearch,
} from '../../helpers/routeHelper'
import { setDefaultFilterItems, setItemsTextFilter } from '../../common/src/actions/filtersActions'
import { MainLayoutMainContext } from '../../contexts'
import { subMenuQuadrantItems } from '../../components/menus/SubMenuQuadrantItems'
import { SelectedProjectTitle } from '../../components/project/SelectedProjectTitle'
import { ProjectModeButton } from '../../components/buttons/ProjectModeButton'
import { useProjectMembersForProject } from '../../common/src/hooks/usersHooks'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { getProjectFeedLink, PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject } from '../../reactions/linkSubject'
import { neededFiltersTip } from '../../reactions'
import { OnboardingCoachmark } from '../../components/onboarding/OnboardingCoachmark'
import { OnboardingStepKeys } from '../../actions/onboardingActions'
import { useCreateOnboardingStep } from '../../hooks/onboardingHooks'
import {
  getItemFiltersCount,
  getItemsQuadrantFilter,
  getItemsTextFilter,
} from '../../common/src/selectors/filtersSelectors'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { MATRIX_MODE } from '../../reducers/uiKeys'
import { setMatrixViewMode } from '../../actions/uiActions'
import { Fns } from '../../common/src/helpers/functionalUtils'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { useSearchKeyPress } from '../../hooks/useSearchKeyPress'
import { useTranslation } from 'react-i18next'
import { AddItemButtonWithTooltip } from '../../components/buttons/AddItemButtonWithTooltip'
import { SearchBoxWithShortcut } from '../../components/SearchBoxWithShortcut'
import { ShortcutTooltipContent } from '../../tooltip/ShortcutTooltipHost'
import { SEARCH_KEYS } from '../../constants/shortcutKeys'
import { useTextSearch } from '../../hooks/useTextSearch'
import { TopBarMultiSelectButton } from '../../components/buttons/TopBarMultiSelectButton'
import { useRouteId } from '../../hooks/useRouteId'
import { ROUTE_ID } from '../../routes/routeIdList'
import {
  Button,
  Menu,
  MenuItem,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tag,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { InfoRegular, PersonRegular, Search16Regular } from '@fluentui/react-icons'
import { IconWithCount } from '../../components/IconWithCount'
import { isPrintHotKey, useHotkey } from '../../hooks/useHotkey'
import { ProjectShareButton } from '@/components/project/ProjectShareButton'
import { Print } from '@/components/BundledIcons'

const MIN_WIDTH = 768

const FiltersButtonSection_ = ({ filtersCount, onClick, clearFilters, disabled = false }) => {
  const [showFiltersTip, setShowFiltersTip] = useState(false)
  const [filterButtonElement, setFilterButtonElement] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    const subscription = neededFiltersTip.subscribe(needed => {
      setShowFiltersTip(needed)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [setShowFiltersTip])

  const onAnimationOpenEnd = useCallback(() => {
    setShowFiltersTip(false)
  }, [setShowFiltersTip])

  // Create a wrapper function to debug clearFilters
  const handleClearFilters = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  const filterButtonTooltip = t('project_level_top_bar.filter_button_tooltip')

  return (
    <>
      <FilterButton
        size={16}
        id="projectLevelTopBar_filterButton"
        ref={setFilterButtonElement}
        tooltip={filterButtonTooltip}
        filtersCount={filtersCount}
        onClear={handleClearFilters}
        onClick={onClick}
        disabled={disabled}
      />
      {showFiltersTip && (
        <Coachmark
          target={filterButtonElement}
          positioningContainerProps={{
            directionalHint: DirectionalHint.bottomCenter,
            doNotLayer: false,
          }}
          onAnimationOpenEnd={onAnimationOpenEnd}
        />
      )}
    </>
  )
}
const FiltersButtonSection = memo(FiltersButtonSection_)

const ShareButton = ({ printMain, project }) => {
  const projectId = projectHelper.getIdd(project)
  const routeId = useRouteId()
  const { t } = useTranslation()

  const print = useCallback(() => {
    if (routeId === ROUTE_ID.FEED_APP) {
      window.open(getProjectFeedLink(projectId, 30), '_blank')
    } else if (printMain) {
      printMain()
    } else {
      window.open(getAbsoluteURL.printProject(projectId), '_blank')
    }
  }, [printMain, projectId, routeId])

  useHotkey(isPrintHotKey, print)

  return (
    <ProjectShareButton
      project={project}
      printOption={
        routeId === ROUTE_ID.FEED_APP ? (
          <Menu positioning={{ autoSize: true }}>
            <MenuTrigger disableButtonEnhancement>
              <MenuItem icon={<Print />}>{t('general.print')}</MenuItem>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {[7, 30, 90, 365].map(days => (
                  <MenuItem
                    key={days}
                    as="a"
                    href={getProjectFeedLink(projectId, days)}
                    target="_blank"
                    className="no-underline"
                  >
                    {t('project_top_bar.print_feed.days', { count: days })}
                  </MenuItem>
                ))}
              </MenuList>
            </MenuPopover>
          </Menu>
        ) : printMain ? (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuItem icon={<Print />}>{t('general.print')}</MenuItem>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItem icon={<Print />} onClick={printMain}>
                  {t('project_top_bar.print_view')}
                </MenuItem>
                <MenuItemLink
                  className="no-underline"
                  icon={<Print />}
                  href={getAbsoluteURL.printProject(projectId)}
                  target="_blank"
                >
                  {t('project_top_bar.print_all_items')}
                </MenuItemLink>
              </MenuList>
            </MenuPopover>
          </Menu>
        ) : (
          <MenuItemLink
            className="no-underline"
            icon={<Print />}
            href={getAbsoluteURL.printProject(projectId)}
            target="_blank"
          >
            {t('general.print')}
          </MenuItemLink>
        )
      }
    />
  )
}
const MemoizedShareButton = memo(ShareButton)

const ProjectLevelTopBar_ = memo(
  ({
    selectedMode,
    project,
    getNavigationURLForProjectId = getRelativeURLKeepingQuerySearch.matrixForProjectId,
    history,
    projectSelectorEnabled = true,
    readOnly = false,
    location,
    items,
    printMain,
  }) => {
    const dispatch = useDispatch()
    const filtersType = getFiltersTypeByMode(selectedMode)
    const filtersCount = useSelector(state => getItemFiltersCount(state, filtersType))
    const clearFilters = useCallback(() => {
      // Reset filters in Redux store
      dispatch(setDefaultFilterItems(filtersType))
      
      // Clear all filter parameters from URL
      try {
        // Create an object with all filter parameters set to null
        const params = {
          q: null,
          item_state: null,
          owners: null,
          tags: null,
          projects: null,
          project_ids: null,
          quadrant: null,
          tag_filter: null,
          filter_preset: null
        }
        
        // Update URL parameters (all will be deleted)
        updateMultipleUrlParams(params);
      } catch (error) {
        console.error('Error clearing URL parameters:', error)
      }
    }, [dispatch, filtersType])
    const textFilter = useSelector(state => getItemsTextFilter(state, filtersType))
    const quadrantFilter = useSelector(state => getItemsQuadrantFilter(state, filtersType))
    const [showSearchBox, setShowSearchBox] = useState(textFilter && textFilter.length > 0)
    const searchBoxRef = useRef(null)
    const setTextFilter = useCallback(text => dispatch(setItemsTextFilter(text, filtersType)), [dispatch, filtersType])
    const [showFiltersPanel, setShowFiltersPanel] = useState(false)
    const { t } = useTranslation()
    const { textSearch, onSearchBoxChange, onSearch } = useTextSearch({ 
      setTextFilter, 
      globalTextSearch: textFilter,
      updateURL: true,
      filterType: filtersType 
    })

    const createItemModal = useCreateItemModal()
    const narrow = useNarrowWidth()
    const { width: mainWidth } = useContext(MainLayoutMainContext)

    const [isOpenMembersPanel, setIsOpenMembersPanel] = useState(false)
    const membersCount = useProjectMembersForProject({ project }).size

    const isFeedSelected = PROJECT_MODE.FEED === selectedMode
    const contactButtonOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.SHARE_PROJECT)
    const addButtonOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.CREATE_ITEMS)

    const showMultiSelectButton = selectedMode === PROJECT_MODE.MATRIX || selectedMode === PROJECT_MODE.LIST

    const onClickMode = useCallback(
      mode => {
        const currentPathname = location.pathname
        const currentModePath = getPathProjectModeFromPathname(currentPathname)
        const pathname = _.replace(currentPathname, currentModePath, projectModeToPathMode(mode))
        const path = getPathnameKeepingQuerySearch(pathname)
        if (_.find(MATRIX_MODE, Fns.equals(mode))) {
          dispatch(setMatrixViewMode(mode))
        }
        dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.SET_MATRIX_VIEW_MODE, { mode }))
        history.push(path)
      },
      [history, location, dispatch]
    )

    const onClickSearch = useCallback(() => {
      setShowSearchBox(true)

      setTimeout(() => {
        searchBoxRef.current.focus()
      }, 100)
    }, [])

    useSearchKeyPress(onClickSearch)

    const onEdit = useCallback(() => {
      const projectID = projectHelper.getIdd(project)
      const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.PROJECT, projectID)
      linkSubject.next({ urlData })
    }, [project])

    const showMembersPanel = useCallback(() => {
      setIsOpenMembersPanel(true)
    }, [setIsOpenMembersPanel])

    const hideMembersPanel = useCallback(() => {
      setIsOpenMembersPanel(false)
    }, [setIsOpenMembersPanel])

    const onClear = () => {
      setShowSearchBox(false)
    }

    const onClickFilters = useCallback(() => {
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.SHOW_FILTER))
      setShowFiltersPanel(true)
    }, [dispatch])

    const onNewItemOnQuadrant = useCallback(
      q => {
        createItemModal({
          mode: EVENT_EXTRA.CREATE_ITEM.MODE.MATRIX_TOP_BAR,
          initialProject: project,
          quadrant: q,
        })
      },
      [project, createItemModal]
    )

    const addButtonProps = useMemo(() => {
      if (quadrantFilter < 0) {
        return { menuProps: subMenuQuadrantItems(project, onNewItemOnQuadrant) }
      }
      return { onClick: () => onNewItemOnQuadrant(quadrantFilter) }
    }, [quadrantFilter, project, onNewItemOnQuadrant])

    const hideProjectTitle = projectSelectorEnabled && narrow
    const projectSelector = projectSelectorEnabled ? (
      <ProjectLevelDropdown
        title={t('project_level_top_bar.projects_dropdown_tooltip')}
        getNavigationURLForProjectId={getNavigationURLForProjectId}
      />
    ) : (
      <SelectedProjectTitle className="mx-1" />
    )

    const leftSubcomponents = (
      <>
        {!hideProjectTitle && projectSelector}
        {readOnly && (
          <Tag size="extra-small" className="ml-1">
            {t('project_level_top_bar.read_only')}
          </Tag>
        )}
        {!readOnly && (
          <Tooltip content={t('project_level_top_bar.edit_button_tooltip')} relationship="label">
            <Button
              data-testid="ProjectLevelTopBar_InfoButton"
              appearance="subtle"
              icon={<InfoRegular />}
              onClick={onEdit}
            />
          </Tooltip>
        )}
        {(contactButtonOnboardingStep.isVisible || !narrow) && (
          <div ref={contactButtonOnboardingStep.ref}>
            <Tooltip content={t('project_level_top_bar.projects_members_button_tooltip')} relationship="label">
              <Button
                appearance="subtle"
                icon={
                  <IconWithCount count={membersCount}>
                    <PersonRegular />
                  </IconWithCount>
                }
                onClick={readOnly ? undefined : showMembersPanel}
                disabled={readOnly}
              />
            </Tooltip>
            {contactButtonOnboardingStep.isVisible && (
              <OnboardingCoachmark
                stepKey={contactButtonOnboardingStep.key}
                target={contactButtonOnboardingStep.ref.current}
                onDismiss={contactButtonOnboardingStep.hideCoachmark}
                positioningContainerProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
              />
            )}
          </div>
        )}
      </>
    )

    const projectModeProps = {
      selectedMode,
      onClick: onClickMode,
      readOnly,
    }

    const searchButtonTooltip = t('project_level_top_bar.search_button_tooltip')
    const filtersDisabled = selectedMode === PROJECT_MODE.REPORTS

    const searchButton = !isFeedSelected && (
      <>
        {!showSearchBox && (
          <Tooltip
            content={<ShortcutTooltipContent label={searchButtonTooltip} tooltipKeys={SEARCH_KEYS} />}
            relationship="label"
          >
            <Button
              icon={<Search16Regular />}
              id="projectLevelTopBar_searchButton"
              aria-label={searchButtonTooltip}
              onClick={onClickSearch}
              disabled={filtersDisabled}
            />
          </Tooltip>
        )}
        <SearchBoxWithShortcut
          id="projectLevelTopBar_searchBox"
          ref={searchBoxRef}
          value={textSearch}
          placeholder={t('common_localized_strings.search_items')}
          onClear={onClear}
          onChange={onSearchBoxChange}
          onSearch={onSearch}
          className={cn(showSearchBox ? 'flex' : 'hidden')}
        />
      </>
    )
    const rightComponents = (
      <div className="ml-1 flex items-center gap-1">
        {mainWidth < MIN_WIDTH ? (
          <ProjectModeButton className="p-0" {...projectModeProps} />
        ) : (
          <ProjectModeSegmented {...projectModeProps} />
        )}
        <MemoizedShareButton project={project} printMain={printMain} />
        {!readOnly && showMultiSelectButton && <TopBarMultiSelectButton allItems={items} />}
        {!readOnly && (
          <>
            <div ref={addButtonOnboardingStep.ref}>
              <AddItemButtonWithTooltip
                id={'projectLevelTopBar_addItemButton'}
                text={t('item.create_item')}
                height={32}
                {...addButtonProps}
              />
            </div>
            {addButtonOnboardingStep.isVisible && (
              <OnboardingCoachmark
                stepKey={addButtonOnboardingStep.key}
                target={addButtonOnboardingStep.ref.current}
                onDismiss={addButtonOnboardingStep.hideCoachmark}
              />
            )}
          </>
        )}
        {!isFeedSelected && (
          <FiltersButtonSection
            filtersCount={filtersCount}
            onClick={onClickFilters}
            clearFilters={clearFilters}
            disabled={filtersDisabled}
          />
        )}
        <Default>{searchButton}</Default>
      </div>
    )

    const FiltersPanel = useMemo(() => getFiltersPanelByMode(selectedMode), [selectedMode])
    return (
      <>
        <TopRightBar
          className="pr-1.5"
          leftSubcomponents={leftSubcomponents}
          rightSubcomponents={rightComponents}
          styles={{ rightContainer: { minWidth: 'auto' } }}
        />
        <ProjectMembersPanel project={project} onDismiss={hideMembersPanel} isOpen={isOpenMembersPanel} />
        <FiltersPanel isOpen={showFiltersPanel} onDismiss={() => setShowFiltersPanel(false)} />
      </>
    )
  }
)

export const ProjectLevelTopBar = withRouter(
  connect((state, own) => {
    const path = own.match.path
    const pathMode = getPathProjectModeFromPathname(path)
    const mode = pathProjectModeToMode(pathMode)
    return {
      selectedMode: mode,
    }
  })(ProjectLevelTopBar_)
)

ProjectLevelTopBar.propTypes = {
  project: PropTypes.any,
}
