import React, { useCallback, useContext } from 'react'
import { Dialog, DialogType } from '@fluentui/react/lib/Dialog'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { TextField } from '@fluentui/react/lib/TextField'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import styled from 'styled-components'
import { cn } from '@appfluence/classnames'
import { copyToClipboard } from '../../common/src/helpers/clipboardUtils'
import { ViewSizeContext } from '../../contexts'
import { useShowToastDidCopyLinkToClipboard } from '../../hooks/clipboardHooks'
import { ErrorText } from '../text/ErrorText'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  position: relative;
  min-height: 30px;
`

const LinkContainer = styled.div`
  display: flex;
`

const Link = styled(TextField)`
  flex: 1;
`

const CopyButton = styled(PrimaryButton)`
  margin-left: 8px;
`

const SErrorText = styled(ErrorText)`
  margin-top: 8px;
`

const StyledLoading = styled(Spinner).attrs({
  size: SpinnerSize.medium,
  labelPosition: 'right',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  visibility: hidden;
  &.loading {
    opacity: 1;
    visibility: visible;
  }
  transition:
    visibility 0.3s,
    opacity 0.3s;
`

export const CopyToClipboardModal = ({
  title = 'Copy to clipboard',
  subText,
  loadingMessage = 'Preparing link...',
  link,
  loading = false,
  errorMessage,
  onCopy,
  children,
  ...rest
}) => {
  const { onDismiss } = rest
  const { narrowWidth } = useContext(ViewSizeContext)
  const { t } = useTranslation()

  const showToastDidCopyLinkToClipboard = useShowToastDidCopyLinkToClipboard()
  const onCopyClick = useCallback(() => {
    copyToClipboard(link)
    onDismiss()
    onCopy?.()
    showToastDidCopyLinkToClipboard()
  }, [link, onDismiss, onCopy, showToastDidCopyLinkToClipboard])

  const loadingClassName = cn({ loading })
  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        subText: subText,
      }}
      modalProps={{
        isBlocking: false,
        styles: {
          main: {
            maxWidth: '500px!important',
            ...(!narrowWidth && { width: '50vw!important' }),
          },
        },
      }}
      {...rest}
    >
      <Container>
        {loading ? (
          <StyledLoading className={loadingClassName} label={loadingMessage} labelPosition="right" />
        ) : (
          <LinkContainer name={t('item.link')}>
            <Link readOnly value={link} />
            <CopyButton
              id="copyToClipboard_copyButton"
              text={t('general.copy')}
              disabled={!link}
              onClick={onCopyClick}
            />
          </LinkContainer>
        )}
        {errorMessage && <SErrorText>{errorMessage}</SErrorText>}
        {children}
      </Container>
    </Dialog>
  )
}
