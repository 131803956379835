import _ from 'lodash'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../common/src/eventTracking/amplitudeEvents'
import { getPathnameKeepingQuerySearch } from '../helpers/routeHelper'

export const navigationPaths = {
  projects: "/app/projects/",
  search: "/app/search/",
  inbox: '/app/inbox/',
  globalFeed: '/app/global_feed/',
  globalCalendar: '/app/global_calendar/',
  globalReports: '/app/global_reports/',
  globalGantt: '/app/global_gantt/',
  one_on_one: '/app/one_on_one/',
  alertsHome: '/app/home/',
  workload: '/app/planning/effort/',
}

export const to = _.mapValues(navigationPaths, p => getPathnameKeepingQuerySearch(p))

export const toWithParams = () => _.mapValues(navigationPaths, p => getPathnameKeepingQuerySearch(p))

export const generateTrackingHandler = dispatch => {
  const pages = {
    inbox: AMPLITUDE_ACTION_TYPES.OPEN_INBOX,
    home: AMPLITUDE_ACTION_TYPES.OPEN_HOME,
    feed: AMPLITUDE_ACTION_TYPES.OPEN_FEED,
    calendar: AMPLITUDE_ACTION_TYPES.OPEN_CALENDAR,
    reports: AMPLITUDE_ACTION_TYPES.OPEN_REPORTS,
    one_on_one: AMPLITUDE_ACTION_TYPES.OPEN_ONE_ON_ONE,
    search: AMPLITUDE_ACTION_TYPES.OPEN_SEARCH_VIEW,
    workload: AMPLITUDE_ACTION_TYPES.OPEN_WORKLOAD_MANAGEMENT,
    webinars: AMPLITUDE_ACTION_TYPES.OPEN_WEBINARS_LINK,
  }
  return _.mapValues(pages, evt => () => dispatch(dispatchEvent(evt)))
}
