import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from 'pm-translations:en'
import { initReactI18next } from 'react-i18next'
import { getSentry } from './getSentry'
import { DEVELOPMENT } from './common/src/environment'

const Sentry = getSentry()
const LOADED_SUFIX = '-LOADED'

const TranslationImport = {
  de: () => import("pm-translations:de"),
  it: () => import("pm-translations:it"),
  es: () => import("pm-translations:es"),
  pt: () => import("pm-translations:pt"),
  fr: () => import("pm-translations:fr"),
  zh: () => import("pm-translations:zh"),
  ja: () => import("pm-translations:ja"),
}

const SUPPORTED_NON_DEFAULT_LANGS = Object.keys(TranslationImport)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        lookupQuerystring: 'lang',
      },
      fallbackLng: 'en',
      saveMissing: true,
      compatibilityJSON: 'v3',
      //lng: 'en',
      debug: DEVELOPMENT,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    },
    () => {
      const l = i18n.language
      if (l?.endsWith(LOADED_SUFIX)) {
        i18n.changeLanguage(l.substring(0, l.length - LOADED_SUFIX.length))
      }
    }
  )

i18n.on('languageChanged', lng => {
  if (!lng) {
    return
  }
  if (lng.endsWith(LOADED_SUFIX)) {
    return
  }
  const matchSupportedLang = SUPPORTED_NON_DEFAULT_LANGS.find(supportedLang => lng.startsWith(supportedLang))
  if (matchSupportedLang) {
    lazyLoadLang(matchSupportedLang)
  }
})

i18n.on('missingKey', function (lngs, namespace, key, res) {
  Sentry.captureException(new Error('Missing i18n key'), {
    level: 'warning',
    extra: {
      lngs,
      namespace,
      key,
      res,
    },
  })
})

i18n.addResourceBundle('en', 'translation', en, true, true)

const lazyLoadLang = async lang => {
  try {
    const translations = await TranslationImport[lang]().then(m => m.default)
    const loadedLocation = `${lang}-LOADED`
    i18n.addResourceBundle(loadedLocation, 'translation', translations, true, true)
    i18n.changeLanguage(loadedLocation) //By setting this, we enforce i18n.language to be different and trigger a refresh cycled
  } catch (err) {
    console.log(err)
    Sentry.captureException(err)
  }
}

export default i18n
