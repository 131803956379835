import React, { memo, Suspense, useCallback, useMemo, useState, useRef } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { ActionButton, IconButton, PrimaryButton, useTheme, mergeStyleSets } from '@fluentui/react'
import { Label } from '../../components/text/Text'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { Loading } from '../../components/basic/Loading'
import { MeetingModal } from './MeetingModal'
import meetingHelper from '../../common/src/helpers/meetingHelper'
import { TopBarAddButton } from '../../components/buttons/TopBarAddButton'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { UsersCollection } from '../../components/users/usersCollection/UsersCollection'
import { useIsInMeeting } from '../../hooks/msTeamsHooks'
import { BackButton, ForwardButton } from '../../components/buttons/NavButtons'
import { ENABLE_MEETINGS_NAVIGATION } from '../../environment'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { ICON_NAMES } from '../../common/src/msIcons'
import { SimpleModal } from '../../components/modal/SimpleModal'
import { reloadNotesMeeting } from '../../common/src/actions/meetingsAPI'
import { ElementTypes as NotesEditorElementTypes } from '../../components/notesEditor/NotesEditorTypes'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { SURFACE_TYPE } from '../modal/CreationModalCommon'
import { useTranslation } from 'react-i18next'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { retry } from '../../common/src/retry'
import { PrintableComponent } from '../../components/PrintableComponent'
import { useReactToPrint } from 'react-to-print'
import { formatDate } from '../../utils/datefns'

const NotesEditor = React.lazy(() => retry(() => import('../../components/notesEditor/NotesEditorExport.js')))

const displayName = 'MeetingDetail'

const classNames = _.mapValues(
  {
    container: "container",
    section: "section",
    sectionBody: "sectionBody",
    participants: "participants",
    participantsTitle: "participantsTitle",
    row: "row",
    editIcon: "editIcon",
    teamsIcon: "teamsIcon",
    notes: "notes",
    error: "error",
  },
  v => `${displayName}-${v}`
)

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: ${p => p.theme.palette.black};
  font-weight: 600;
  font-size: large;

  @media print {
    display: none;
  }
`

const Section = styled.div`
  @media print {
    flex: none;
    & * {
      overflow-y: hidden !important;
    }
  }
`

const SectionBody = styled.div`
  @media print {
    flex: none;
  }
`

const Row = styled.div`
  @media print {
    display: none;
  }
`

const useClassNames = ({ narrow = false, fetching = false, inMeeting = false }) => {
  const theme = useTheme()
  const { palette } = theme
  return useMemo(() => {
    return mergeStyleSets({
      container: [
        classNames.container,
        {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          boxSizing: 'border-box',
          paddingTop: 20,
          background: 'inherit',
        },
        narrow && {
          marginRight: 0,
        },
        fetching && {
          justifyContent: 'center',
        },
        inMeeting && {
          paddingTop: 0,
        },
      ],
      section: [
        classNames.section,
        {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          boxSizing: 'border-box',
        },
      ],
      sectionBody: [
        classNames.sectionBody,
        {
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      ],
      participants: [
        classNames.participants,
        {
          marginBottom: 8,
        },
      ],
      participantsTitle: [
        classNames.participantsTitle,
        {
          paddingTop: 0,
          paddingBottom: 0,
          marginBottom: 8,
        },
      ],
      row: [
        classNames.row,
        theme.fonts.large,
        {
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          marginBottom: 8,
        },
      ],
      editIcon: [classNames.editIcon, { marginLeft: 8 }],
      teamsIcon: [classNames.teamsIcon, { marginLeft: 4 }],
      notes: [
        classNames.notes,
        {
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      ],
      error: [
        classNames.error,
        {
          marginTop: 8,
          color: palette.negative,
        },
      ],
    })
  }, [narrow, fetching, inMeeting, theme.fonts.large, palette])
}

const EditIcon = styled(IconButton).attrs(p => ({
  iconProps: { iconName: ICON_NAMES.Edit },
  styles: {
    root: {
      color: p.theme.palette.themePrimary,
    },
    rootHovered: {
      color: p.theme.palette.themeDark,
    },
  },
}))``

const TeamsIcon = styled(IconButton).attrs(p => ({
  iconProps: { iconName: ICON_NAMES.TeamsLogo },
  styles: {
    root: {
      color: p.theme.palette.themePrimary,
    },
    rootHovered: {
      color: p.theme.palette.themeDark,
    },
  },
}))``

const identifiers = {
  notesEditor: "notesEditor",
}

const FlexibleElement = styled.div`
  flex: 1;
`

const SActionButton = styled(ActionButton).attrs({
  styles: {
    label: {
      margin: 0,
    },
  },
})`
  height: auto;

  color: ${p => p.theme.palette.themePrimary};
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${p => p.theme.palette.themeDark};
    }
  }
`

const reloadNotesModalStyles = {
  modal: {
    main: {
      minWidth: '320px',
      maxWidth: '460px',
      boxSizing: 'border-box',
    },
  },
}

const useButtonProps = () =>
  useMemo(
    () => ({
      add: { iconName: 'CircleAddition' },
      reload: { iconName: 'SyncStatus' },
      search: { iconName: 'Search' },
      print: { iconName: 'Print' },
    }),
    []
  )

export const AddButton = styled(TopBarAddButton)`
  margin-right: 8px;
`

export const MeetingDetail = memo(
  ({
    meeting,
    notes,
    onChangeNotes,
    onBlurNotes,
    onOpenLink,
    onCopyLink,
    onSearch,
    onAddItem,
    onNavigateToMeeting,
    isValid,
    loading,
    fetching,
    error,
    notesEditorRef,
    ...rest
  }) => {
    const narrow = useNarrowWidth()
    const { t } = useTranslation()
    const inMeeting = useIsInMeeting()
    // const [isOpenMeetingModal, { setTrue: showMeetingModal, setFalse: hideMeetingModal }] = useBoolean(false)
    const meetingId = meeting?.get('id')
    const buttonProps = useButtonProps()
    const printRef = useRef()

    const title = useMemo(() => {
      return meetingHelper.getTitle(meeting) || t('meeting.meeting_title')
    }, [meeting, t])
    const startDate = meetingHelper.getStartDate(meeting)
    const dateString = useMemo(() => startDate ? formatDate('ShortenedNormal')(startDate) : '', [startDate])
    const participants = useMemo(() => (meeting ? meetingHelper.getParticipants(meeting).toArray() : []), [meeting])
    const meetingUrl = useMemo(() => meetingHelper.getMeetingUrl(meeting), [meeting])
    const meetingOutlookLink = useMemo(() => meetingHelper.getLink(meeting), [meeting])
    const [showReloadNotesModal, setShowReloadNotesModal] = useState(false)
    const [reloading, setReloading] = useState(false)
    const dispatch = useDispatch()
    const { palette } = useTheme()

    const createItemModal = useCreateItemModal()
    const onClickAddButton = useCallback(() => {
      createItemModal({
        quadrant: 0,
        mode: EVENT_EXTRA.CREATE_ITEM.MODE.MEETING_AGENDA,
        displayProjectSelector: true,
        surface: SURFACE_TYPE.PANEL,
      })
    }, [createItemModal])

    const onClickPrint = useReactToPrint({
      content: () => printRef.current,
    })

    const onReloadNotesDone = useCallback(async () => {
      setReloading(true)
      const response = await dispatch(reloadNotesMeeting(meetingId))
      if (response?.payload) {
        const notes = response.payload.notes
        if (!notesEditorRef.current) {
          return
        }
        const notesEditor = notesEditorRef.current
        const element = { newText: notes, type: NotesEditorElementTypes.newText }
        if (notesEditor.addElement(element)) {
          console.log('notes reloaded')
        }
      }
      setReloading(false)
      setShowReloadNotesModal(false)
    }, [dispatch, meetingId, notesEditorRef])
    const confirmReloadNotesbutton = (
      <PrimaryButton key={'reloadNotes'} onClick={onReloadNotesDone}>
        Update notes
      </PrimaryButton>
    )

    const prevMeetingId = meetingHelper.getPrevMeetingId(meeting)
    const nextMeetingId = meetingHelper.getNextMeetingId(meeting)
    const isSerie = prevMeetingId || nextMeetingId
    const hasPrevious = !!prevMeetingId
    const hasNext = !!nextMeetingId
    const classNames = useClassNames({ narrow, fetching, inMeeting })

    const onBack = useCallback(() => {
      if (!prevMeetingId) {
        return
      }
      onNavigateToMeeting(prevMeetingId)
    }, [onNavigateToMeeting, prevMeetingId])

    const onNext = useCallback(() => {
      if (!nextMeetingId) {
        return
      }
      onNavigateToMeeting(nextMeetingId)
    }, [nextMeetingId, onNavigateToMeeting])

    const openMeeting = useCallback(() => {
      if (!meetingUrl) {
        return
      }
      window.open(meetingUrl, '_blank')
    }, [meetingUrl])

    const openOutlookLink = useCallback(() => {
      if (!meetingOutlookLink) {
        return
      }
      window.open(meetingOutlookLink, '_blank')
    }, [meetingOutlookLink])

    const notesLabel = useMemo(() => {
      return inMeeting ? title : t('meeting.notes')
    }, [inMeeting, t, title])

    if (error) {
      const placeholder = {
        title: t('meeting.not_found_placeholder.title'),
        message: t('meeting.not_found_placeholder.message'),
        type: IVIEW_PLACEHOLDER_TYPES.NOT_FOUND,
      }
      return (
        <Suspense fallback={<Loading />}>
          <LazyIViewPlaceholder {...placeholder} />
        </Suspense>
      )
    }

    if (loading) {
      return <Loading />
    }

    const backProps = { disabled: !hasPrevious, onClick: onBack, style: { marginRight: 8 } }
    const nextProps = { disabled: !hasNext, onClick: onNext, style: { marginLeft: 8 } }
    const showTitle = false
    const inTeams = isEmbeddedOnTeams()
    const showNavigation = ENABLE_MEETINGS_NAVIGATION && !inMeeting && !!isSerie
    const showDate = !!showNavigation || !inTeams
    const showHeader = showTitle || showNavigation
    return (
      <div className={classNames.container} {...rest}>
        <PrintableComponent ref={printRef}>
          <Section className={classNames.section}>
            {showHeader && <SectionHeader>{showTitle && <div>{title}</div>}</SectionHeader>}
            <SectionBody className={classNames.sectionBody}>
              {!inTeams && !inMeeting && (
                <div className={classNames.participants}>
                  <Label className={classNames.participantsTitle}>{t('meeting.participants')}</Label>
                  <UsersCollection max={10} users={participants} showAddCell={false} readOnly={true} />
                </div>
              )}
              <Row className={classNames.row}>
                {!inMeeting && (
                  <>
                    {showNavigation && (
                      <div>
                        <BackButton title={t('meeting.prev_meeting_tooltip')} {...backProps} />
                      </div>
                    )}
                    {!!showDate && <div style={{ color: palette.themePrimary }}>{dateString}</div>}
                    {showNavigation && (
                      <div>
                        <ForwardButton title={t('meeting.next_meeting_tooltip')} {...nextProps} />
                      </div>
                    )}
                    {!inTeams && (
                      <>
                        {!!meetingOutlookLink && (
                          <EditIcon
                            // onClick={showMeetingModal}
                            onClick={openOutlookLink}
                            title={t('meeting.edit_in_outlook')}
                            className={classNames.editIcon}
                          />
                        )}
                        {!!meetingUrl && (
                          <TeamsIcon
                            onClick={openMeeting}
                            title={t('meeting.open_in_teams')}
                            className={classNames.teamsIcon}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                <FlexibleElement />
              </Row>
              <Row className={classNames.row}>
                <Label>{notesLabel}</Label>
                <FlexibleElement />
                <SActionButton
                  iconProps={buttonProps.print}
                  text={narrow ? null : t('meeting.print_button_title')}
                  title={t('meeting.print_button_tootip')}
                  onClick={onClickPrint}
                />
                <SActionButton
                  iconProps={buttonProps.reload}
                  text={narrow ? null : t('meeting.reload_notes_button')}
                  title={t('meeting.reload_notes_button_tooltip')}
                  onClick={() => setShowReloadNotesModal(true)}
                />
                <SActionButton
                  iconProps={buttonProps.add}
                  text={narrow ? null : t('meeting.add_item_button')}
                  title={t('meeting.add_item_button_tooltip')}
                  onClick={onClickAddButton}
                />
                {narrow && (
                  <SActionButton
                    iconProps={buttonProps.search}
                    text={narrow ? null : t('meeting.search_items_button')}
                    title={t('meeting.search_items_button_tooltip')}
                    onClick={onSearch}
                  />
                )}
              </Row>
              <Suspense fallback={<Loading />}>
                <NotesEditor
                  key={meetingId}
                  id={identifiers.notesEditor}
                  value={notes}
                  onChange={onChangeNotes}
                  onBlur={onBlurNotes}
                  onOpenLink={onOpenLink}
                  onCopyLink={onCopyLink}
                  className={classNames.notes}
                  meeting={meeting}
                  meetingId={meetingId}
                  componentRef={notesEditorRef}
                  showFullToolbar={!inMeeting}
                />
              </Suspense>
            </SectionBody>
          </Section>
        </PrintableComponent>
        <MeetingModal
          // isOpen={isOpenMeetingModal}
          // onDismiss={hideMeetingModal}
          meeting={meeting}
        />
        <SimpleModal
          title={t('meeting.reload_notes_modal_title')}
          isBlocking={true}
          isOpen={showReloadNotesModal}
          subtitle={t('meeting.reload_notes_modal_subtitle')}
          onDismiss={() => {
            setShowReloadNotesModal(false)
          }}
          buttons={confirmReloadNotesbutton}
          loading={reloading}
          styles={reloadNotesModalStyles}
        />
      </div>
    )
  }
)
