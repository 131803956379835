import { safeCallFn } from '../../common/src/helpers/functionalUtils'
import { ContainerPanel } from '../../components/panels/ContainerPanel'
import { ProjectsFiltersView } from './ProjectsFiltersView'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export const ProjectsFiltersPanel = ({ onOpened, mode, ...rest }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  
  const localOnOpened = () => {
    // When panel opens, apply URL parameters to ensure filters are up to date
    try {
      const params = new URLSearchParams(window.location.search);
      if (params.has('project_tags') || params.has('project_sort')) {
        // Import the function dynamically to avoid circular dependencies
        import('../../hooks/filterHooks').then(module => {
          const { applyProjectFiltersFromUrl } = module;
          applyProjectFiltersFromUrl(dispatch);
          window.dispatchEvent(new Event('filterchange'));
        });
      }
    } catch (err) {
      console.error("Error applying filters in ProjectsFiltersPanel:", err);
    }
    
    // Call original callback
    safeCallFn(onOpened);
  }
  
  const nextProps = {
    ...rest,
    headerText: t('project_filters.header_title'),
    onOpened: localOnOpened,
  }

  return (
    <ContainerPanel {...nextProps}>
      <ProjectsFiltersView mode={mode} />
    </ContainerPanel>
  )
}
