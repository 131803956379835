import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  SearchBox,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { forwardRef, type MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { projectHelper, stateHelper } from '@/common/src/helpers'
import { cn } from '@/modules/classnames'
import {
  QuadrantSelector as ColoredQuadrantSelector,
  QuadrantSelector,
} from '@/components/input/quadrant/QuadrantSelector'
import { useTranslation } from 'react-i18next'

type Project = any

export type ProjectSelectorDrawerProps = {
  onSelectProject: (event: MouseEvent<HTMLButtonElement>, project: unknown) => void
  selectedProject: Project | undefined | 'keep'
  showKeepSameOption?: boolean
  className?: string
}

export const ProjectSelectorDrawer = ({
  onSelectProject,
  selectedProject,
  showKeepSameOption = false,
  className,
}: ProjectSelectorDrawerProps) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const projectsMap = useSelector(stateHelper.getAllProjects)
  const notArchivedProjects = useMemo(() => projectsMap.filterNot(projectHelper.isArchived), [projectsMap])
  const filteredProjects = useMemo(() => {
    const searchQueryLowerCase = searchQuery.toLowerCase()
    return notArchivedProjects
      .filter((project: any) => projectHelper.getName(project).toLowerCase().includes(searchQueryLowerCase))
      .toArray()
  }, [notArchivedProjects, searchQuery])
  const [open, setOpen] = useState(false)

  const selectedProjectRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (open && selectedProjectRef.current) {
      selectedProjectRef.current.scrollIntoView({ block: 'center' })
    }
  }, [open])

  const onDismiss = () => setOpen(false)

  const handleOptionClick = (event: MouseEvent<HTMLButtonElement>, project: unknown) => {
    onSelectProject(event, project)
    onDismiss()
  }

  return (
    <>
      <button
        className={cn(
          'flex h-8 items-center rounded-sm border border-solid border-pm-neutral-secondary bg-transparent text-pm-black dark:text-white',
          className
        )}
        onClick={() => setOpen(true)}
      >
        {selectedProject !== 'keep' && <ColoredQuadrantSelector size={20} project={selectedProject} readOnly />}
        <span className="ml-2 truncate text-sm">
          {selectedProject === 'keep'
            ? t('item.multiple_selection.move.keep_same_project')
            : selectedProject
              ? projectHelper.getName(selectedProject)
              : t('left_panel.inbox_button_name')}
        </span>
      </button>
      <OverlayDrawer
        open={open}
        onOpenChange={(event, data) => {
          if (!data.open) {
            onDismiss()
          }
        }}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={<Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} onClick={onDismiss} />}
          >
            Select a Project
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody className="flex h-full flex-col">
          <SearchBox className="w-full" value={searchQuery} onChange={(event, data) => setSearchQuery(data.value)} />
          <ul className="m-0 flex flex-1 flex-col gap-1 overflow-y-auto p-0 py-2">
            {showKeepSameOption && (
              <ProjectOption project="keep" isSelected={selectedProject === 'keep'} onClick={handleOptionClick} />
            )}
            <ProjectOption project={undefined} isSelected={!selectedProject} onClick={handleOptionClick} />
            <span className="ml-3 text-base text-pm-theme-primary">{t('left_panel.projects_button_name')}</span>
            {filteredProjects.map((project: any) => {
              const isSelected = projectHelper.getIdd(project) === projectHelper.getIdd(selectedProject)
              return (
                <ProjectOption
                  key={projectHelper.getIdd(project)}
                  project={project}
                  isSelected={isSelected}
                  onClick={handleOptionClick}
                  ref={isSelected ? selectedProjectRef : undefined}
                />
              )
            })}
          </ul>
        </DrawerBody>
      </OverlayDrawer>
    </>
  )
}

type ProjectOptionProps = {
  project: any
  isSelected: boolean
  onClick: (event: MouseEvent<HTMLButtonElement>, project: unknown) => void
}

const ProjectOption = forwardRef<HTMLButtonElement, ProjectOptionProps>(({ project, isSelected, onClick }, ref) => {
  const { t } = useTranslation()
  return (
    <button
      className={cn('flex w-full gap-2 rounded border-none bg-transparent py-1.5', isSelected && 'bg-pm-appfluence')}
      onClick={event => onClick(event, project)}
      ref={ref}
    >
      {project !== 'keep' && <QuadrantSelector size={20} project={project} readOnly />}
      <span className="text-left text-sm text-black dark:text-white">
        {!project
          ? t('left_panel.inbox_button_name')
          : project === 'keep'
            ? t('item.multiple_selection.move.keep_same_project')
            : projectHelper.getName(project)}
      </span>
    </button>
  )
})
