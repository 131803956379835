import { Button } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { cn } from '@/modules/classnames'
import { forwardRef } from 'react'

export type DateShortcutsProps = {
  onDateChange: (newDate: Date) => void
  onDateTimeChange: (newDate: Date) => void
  onAllDayChange?: (newAllDay: boolean) => void
  disabled?: boolean
  className?: string
}

export const DateShortcuts = forwardRef<HTMLDivElement, DateShortcutsProps>(
  ({ onDateChange, onDateTimeChange, onAllDayChange, disabled, className }, ref) => {
    const { t } = useTranslation()
    return (
      <div className={cn('flex w-fit flex-wrap justify-center', className)} ref={ref}>
        <Button
          className="!min-w-0 !px-1.5 !py-0.5"
          appearance="transparent"
          onClick={ev => {
            const date = new Date()
            date.setHours(date.getHours() + 1)
            onAllDayChange?.(false)
            onDateTimeChange(date)
          }}
          disabled={disabled}
        >
          {t('date.shortcuts.next_hour')}
        </Button>
        <Button
          className="!min-w-0 !px-1.5 !py-0.5"
          appearance="transparent"
          onClick={() => {
            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)
            onDateChange(tomorrow)
          }}
          disabled={disabled}
        >
          {t('date.shortcuts.tomorrow')}
        </Button>
        <Button
          className="!min-w-0 !px-1.5 !py-0.5"
          appearance="transparent"
          onClick={() => {
            const date = new Date()
            date.setDate(date.getDate() + 7)
            onDateChange(date)
          }}
          disabled={disabled}
        >
          {t('date.shortcuts.next_week')}
        </Button>
        <Button
          className="!min-w-0 !px-1.5 !py-0.5"
          appearance="transparent"
          onClick={() => {
            const date = new Date()
            date.setDate(date.getDate() + 30)
            onDateChange(date)
          }}
          disabled={disabled}
        >
          {t('date.shortcuts.next_month')}
        </Button>
      </div>
    )
  }
)
