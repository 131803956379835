import { useCallback } from 'react'
import _ from 'lodash'
import { setItemProjectFilter } from '../common/src/actions/filtersActions'
import { FILTER_REDUCER_KEYS } from '../common/src/reducers/filtersKeys'
import { useSelector } from 'react-redux'
import { getItemsProjectFilter } from '../common/src/selectors/filtersSelectors'

/**
 * Hook to handle project filter with URL updates
 * @param {function} dispatch - Redux dispatch function
 * @param {string} filterType - The filter reducer key, default SEARCH
 * @returns {object} - Functions to handle project filter
 */
export const useProjectFilter = (dispatch, filterType = FILTER_REDUCER_KEYS.SEARCH) => {
  // Get current projects from filter state
  const projectIds = useSelector(state => getItemsProjectFilter(state, filterType))
  
  // Function to update project filter and URL
  const setProjectFilter = useCallback((projectIds) => {
    try {
      // First dispatch the action to update Redux state
      dispatch(setItemProjectFilter(projectIds, filterType))
      
      // Update URL with project_ids parameter
      const url = new URL(window.location.href)
      
      // Convert to an array regardless of input type
      let projectIdsArray = []
      
      if (projectIds) {
        // Handle both array and Immutable collection cases
        if (Array.isArray(projectIds)) {
          projectIdsArray = projectIds
        } else if (typeof projectIds.toArray === 'function') {
          projectIdsArray = projectIds.toArray()
        } else if (typeof projectIds.size === 'number') {
          // Handle Immutable List case differently
          projectIdsArray = []
          for (let i = 0; i < projectIds.size; i++) {
            projectIdsArray.push(projectIds.get(i))
          }
        }
      }
      
      // Debug logging for development only
      // console.log('Setting project filter:', projectIdsArray)
      
      // Check if we have any project IDs to add to URL
      if (projectIdsArray && projectIdsArray.length > 0) {
        const projectIdsString = projectIdsArray.join(',')
        if (projectIdsString) {
          // Use a cleaner approach to avoid percent-encoding
          // First, get the current search params as an object
          const searchParams = {}
          url.searchParams.forEach((value, key) => {
            searchParams[key] = value
          })
          
          // Update the project_ids parameter
          searchParams['project_ids'] = projectIdsString
          
          // Build a clean query string
          const queryString = Object.entries(searchParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
            
          // Update the URL with the clean query string
          const newUrl = `${url.origin}${url.pathname}?${queryString}`
          window.history.replaceState(null, '', newUrl)
          return // Skip the normal URL update
        }
      } else {
        // Remove parameter if no projects
        url.searchParams.delete('project_ids')
      }
      
      // Update URL without reloading
      window.history.replaceState(null, '', url.toString())
    } catch (error) {
      console.error('Error updating project filter or URL:', error)
    }
  }, [dispatch, filterType])
  
  return {
    projectIds,
    setProjectFilter,
  }
}