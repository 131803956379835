import React, { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { ActionButton, CommandButton } from '@fluentui/react/lib/Button'
import { Spinner } from '@fluentui/react-components'
import { PanelView } from '../../panels/PanelView'
import { UsersList } from '../usersList/UsersList'
import { UsersViewWithSearch } from '../usersList/UsersListWithSearch'
import { createUserOptions } from '../../../helpers/usersOptionsHelpers'
import { withCyAttr } from '../../../helpers/styleHelper'
import { CYPRESS_ID } from '../../../constants/cypress'
import { ErrorText } from '../../text/ErrorText'
import { useTranslation } from 'react-i18next'
import { ContainerDrawer } from '@/components/panels/ContainerDrawer'

const EditionButton = styled(ActionButton).attrs(
  withCyAttr({
    styles: {
      label: {
        margin: 0,
      },
    },
  })
)`
  height: auto;
  padding: 0;
`

export const MenuButton = styled(CommandButton).attrs({
  iconProps: {
    iconName: 'CollapseMenu',
  },
  styles: {
    menuIcon: {
      marginRight: 0,
    },
  },
})`
  height: auto;
  padding: 0;
`

const SErrorText = styled(ErrorText)`
  padding: 2px 0;
`

const UsersHeaderView = memo(
  ({
    header,
    users,
    onRemoveUser,
    loading,
    disableOnLoading = false,
    fromProject = false,
    onShowError,
    onlyRemovableMe,
    removeText,
  }) => {
    const { t } = useTranslation()
    if (users.length === 0) {
      return null
    }

    const usersOptions = createUserOptions(users, t)
    return (
      <>
        <div className="flex justify-between">
          <span className="font-semibold">{header}</span>
          {!onlyRemovableMe && <span className="italic">{removeText}</span>}
        </div>
        <UsersList
          usersOptions={usersOptions}
          onSelectUser={disableOnLoading && loading ? undefined : onRemoveUser}
          fromProject={fromProject}
          onShowError={onShowError}
          onlyClickableMe={onlyRemovableMe}
          myselfSecondaryText={onlyRemovableMe ? removeText : undefined}
        />
      </>
    )
  }
)

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-height: 20px;
  padding-bottom: 4px;
`

const UsersPanelView = memo(
  ({
    users,
    onSelectGroup,
    options,
    onSelectUser,
    onRemoveUser,
    loading,
    disableOnLoading = false,
    enabledEditing,
    onStartEditing,
    onEndEditing,
    editingErrorMessage,
    errorMessage,
    usersHeader,
    textFieldHeader,
    textFieldPlaceholder,
    invitationIconProps,
    doneText,
    editText,
    editSection,
    menuProps,
    fromProject,
    onShowError,
    onlyRemovableMe,
    removeText,
    ...rest
  }) => {
    const [editing, setEditing] = useState(false)
    const { t } = useTranslation()

    const toggleEditing = useCallback(() => {
      if (editing) {
        onEndEditing?.()
      } else {
        onStartEditing?.()
      }
      setEditing(!editing)
    }, [editing, onEndEditing, onStartEditing])

    const showEditionButtons = enabledEditing && !!editText
    const headerChildren = (
      <HeaderContainer>
        {loading && <Spinner size="tiny" />}
        {/* If we don't show the spinner, we have to show an empty div to make sure the button is on the right */}
        {!loading && <div />}
        {showEditionButtons && (
          <EditionButton cy={CYPRESS_ID.USER_PANEL_EDIT} onClick={toggleEditing}>
            {editing ? doneText : editText}
          </EditionButton>
        )}
        {menuProps && <MenuButton menuProps={menuProps} />}
      </HeaderContainer>
    )

    return (
      <PanelView headerChildren={headerChildren} {...rest}>
        {editing && editingErrorMessage && <SErrorText>{editingErrorMessage}</SErrorText>}
        {editing && editSection}
        {errorMessage && <SErrorText>{errorMessage}</SErrorText>}
        {!editing && (
          <UsersHeaderView
            header={usersHeader}
            users={users}
            onRemoveUser={onRemoveUser}
            loading={loading}
            fromProject={fromProject}
            onShowError={onShowError}
            onlyRemovableMe={onlyRemovableMe}
            removeText={removeText}
          />
        )}
        {!editing && (
          <UsersViewWithSearch
            textFieldHeaderText={t('users.add_members')}
            textFieldHeader={textFieldHeader}
            textFieldPlaceholder={textFieldPlaceholder ?? t('item_detail.followers_panel_text_field_placeholder')}
            invitationIconProps={invitationIconProps}
            selectedUsers={users}
            options={options}
            onSelectUser={disableOnLoading && loading ? undefined : onSelectUser}
            onSelectGroup={onSelectGroup}
          />
        )}
      </PanelView>
    )
  }
)

export const UsersPanel = memo(
  ({
    children,
    headerText,
    users,
    onSelectGroup,
    options,
    onSelectUser,
    onRemoveUser,
    loading,
    disableOnLoading = false,
    enabledEditing = true,
    onStartEditing,
    onEndEditing,
    editingErrorMessage,
    errorMessage,
    usersHeader,
    textFieldHeader,
    textFieldPlaceholder,
    invitationIconProps,
    doneText,
    editText,
    editSection,
    menuProps,
    onDismiss,
    fromProject = false,
    onShowError,
    onlyRemovableMe,
    removeText,
    ...rest
  }) => {
    const { t } = useTranslation()
    return (
      <ContainerDrawer headerText={headerText ?? t('users.choose_users')} onDismiss={onDismiss} {...rest}>
        <UsersPanelView
          users={users}
          onSelectGroup={onSelectGroup}
          options={options}
          onSelectUser={onSelectUser}
          onRemoveUser={onRemoveUser}
          loading={loading}
          disableOnLoading={disableOnLoading}
          enabledEditing={enabledEditing}
          onStartEditing={onStartEditing}
          onEndEditing={onEndEditing}
          editingErrorMessage={editingErrorMessage}
          errorMessage={errorMessage}
          usersHeader={usersHeader ?? t('users.current_users')}
          textFieldHeader={textFieldHeader}
          textFieldPlaceholder={textFieldPlaceholder}
          invitationIconProps={invitationIconProps}
          doneText={doneText ?? t('users.done')}
          editText={editText}
          editSection={editSection}
          menuProps={menuProps}
          fromProject={fromProject}
          onShowError={onShowError}
          onlyRemovableMe={onlyRemovableMe}
          removeText={removeText}
        />
        {children}
      </ContainerDrawer>
    )
  }
)
