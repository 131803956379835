import styled, { useTheme } from 'styled-components'
import * as styleHelper from '../../helpers/styleHelper'
import { Text } from '../text/Text'
import { LeftPanelSubcomponent } from './LeftPanelSubcomponent'
import { Link } from 'react-router-dom'
import { BubbleButton } from '../buttons/BubbleButton'
import { safeCallFn } from '../../common/src/helpers/functionalUtils'
import { openExternalLink } from '../../utils/externalLinkHandler'
import { getPathnameClean } from '../../helpers/routeHelper'

const SLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-decoration: none;
  color: ${p => p.theme.palette.always_white};
`

const TitleText = styled(Text).attrs({
  variant: 'tiny',
})`
  color: ${p => p.color};

  ${styleHelper.defaultMediaQueries.smallDevices`
    display: none;
  `}
`

const StyledBubbleButton = styled(BubbleButton)`
  color: ${p => p.color};
  fill: ${p => p.theme.palette.always_white};
`

export const LeftPanelButton = ({
  name,
  iconName,
  tooltip,
  tooltipPadding,
  count = 0,
  to,
  external = '',
  selected = false,
  children,
  onClick,
  color,
  ...rest
}) => {
  const theme = useTheme()
  const safeColor = color ?? theme.palette.always_white
  const finalChildren = children || (
    <>
      <StyledBubbleButton
        onlyIcon
        iconName={iconName}
        n={count}
        size={16}
        color={safeColor}
        aria-label={tooltip || name}
      />
      <TitleText color={safeColor}>{name}</TitleText>
    </>
  )

  const handleExternalNavigation = () => {
    safeCallFn(onClick)
    openExternalLink(external)
  }

  const finalOnClick = external ? handleExternalNavigation : onClick
  const subComponent = (
    <LeftPanelSubcomponent
      selected={selected}
      tooltip={tooltip ? tooltip : name}
      tooltipPadding={tooltipPadding}
      onClick={finalOnClick}
      {...rest}
    >
      {finalChildren}
    </LeftPanelSubcomponent>
  )

  if (to) {
    // Force clean URL with no search parameters
    return <SLink to={getPathnameClean(to.pathname || to)}>{subComponent}</SLink>
  } else {
    return <>{subComponent}</>
  }
}
