import React, { memo, useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { List } from '@fluentui/react/lib/List'
import { GhostItemCell } from '../itemCell/GhostItemCell'

const Container = styled(List)`
  background: ${p => (p.backgroundColor ? p.backgroundColor : 'inherited')};
`

export const GhostItemsList = memo(({ length = 5, shimmerColors, ...rest }) => {
  const items = _.range(length)
  const onRenderCell = useCallback(() => {
    return <GhostItemCell shimmerColors={shimmerColors} />
  }, [shimmerColors])

  const backgroundColor = shimmerColors?.background
  return (
    <Container
      data-is-scrollable={true}
      items={items}
      onRenderCell={onRenderCell}
      backgroundColor={backgroundColor}
      {...rest}
    />
  )
})
