// src/hooks/useNotificationCounts.js
import { useMemo } from 'react'
import { useAttentionNeededInSession } from '../common/src/hooks/itemHooks'
import { itemHelper } from '../common/src/helpers'

/**
 * Hook that provides counts of different types of notifications in the attention needed items
 * 
 * @returns {Object} Counts of different notification types
 */
export const useNotificationCounts = () => {
  const alerts = useAttentionNeededInSession()
  
  return useMemo(() => {
    const counts = {
      delegation: 0,
      invitation: 0,
      mention: 0,
      overdue: 0,
      other: 0,
      total: alerts.size
    }
    
    alerts.forEach(item => {
      const notification = itemHelper.getNotification(item)
      const hasUnreadMention = itemHelper.hasUnreadMention(item)
      const dueDate = itemHelper.getDueDate(item)
      const isOverdue = dueDate && dueDate < new Date() && !itemHelper.isCompleted(item)
      
      // Check if the item has an "Overdue" label
      const name = itemHelper.getName(item) || ''
      const hasOverdueLabel = name.includes('Overdue')
      
      // Special handling for payment items
      const isPaymentItem = name.toLowerCase().includes('payment')
      
      if (hasUnreadMention) {
        counts.mention++
      }
      
      if (notification === 'delegation') {
        counts.delegation++
      } else if (notification === 'invitation') {
        counts.invitation++
      } else if (isOverdue || hasOverdueLabel) {
        counts.overdue++
      } else if (isPaymentItem || (notification && !hasUnreadMention) || (!notification && !hasUnreadMention && !isOverdue && !hasOverdueLabel)) {
        counts.other++
      }
    })
    
    // Debug summary at the end
    
    return counts
  }, [alerts])
}