import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { ChoiceGroup, Dropdown, SearchBox } from '@fluentui/react'
import { Label } from '../../components/text/Text'
import {
  PROJECTS_SHOW_ARCHIVED_OPTIONS,
  PROJECTS_SORT_TYPE,
  setDefaultFilterProjects,
  setProjectsShowArchivedFilter,
  setProjectsTextFilter,
  setProjectsUserGroupFilter,
  TAGS_MODE_KEYS,
} from '../../common/src/actions/filtersActions'
import { 
  useProjectTagsFilter,
  useProjectTagsModeFilter,
  useProjectSortFilter
} from '../../hooks/filterHooks'
import { FiltersView } from './FiltersView'
import {
  getProjectsShowArchivedFilter,
  getProjectsTextFilter,
  getProjectsUserGroupFilter,
  isFilteringProjects,
} from '../../common/src/selectors/filtersSelectors'
import { UserGroupsDropdown } from '../../components/dropdown/UserGroupsDropdown'
import { useTranslation } from 'react-i18next'
import { SERVER_URLS } from '../../common/src/constants'
import { Link } from '@fluentui/react-components'
import { SuggestionsTagPicker } from '@/components/pickers/SuggestionsTagPicker'

const SSearchBox = styled(SearchBox)`
  margin-top: 5px;
`

const StyledDropdown = styled(Dropdown)``

const StyledTagsContainer = styled.div``

export const ProjectsFiltersView = ({ mode }) => {
  const textFilter = useSelector(state => getProjectsTextFilter(state, mode))
  const userGroupFilter = useSelector(state => getProjectsUserGroupFilter(state, mode))
  const showArchived = useSelector(state => getProjectsShowArchivedFilter(state, mode))

  const dispatch = useDispatch()
  
  // Use the new hooks for URL-synced filters
  const { sortType, setProjectSortFilter } = useProjectSortFilter(dispatch, mode)
  const { tagsFilter, setProjectTagsFilter } = useProjectTagsFilter(dispatch, mode)
  const { tagsMode, setProjectTagsModeFilter } = useProjectTagsModeFilter(dispatch, mode)

  // Add a manual re-render listener for filterchange events
  const [, setForceUpdate] = React.useState(0);
  
  const isFiltering = useSelector(state => isFilteringProjects(state, mode))
  const searchBoxRef = useRef(null)
  
  // Safely convert the Immutable.js List to a plain JavaScript array
  const selectedTags = useMemo(() => {
    // First check if we even have a tagsFilter
    if (!tagsFilter) return [];
    
    // If it's already an array, return it
    if (Array.isArray(tagsFilter)) return tagsFilter;
    
    // If it has a toJS method, use that (Immutable.js)
    if (typeof tagsFilter.toJS === 'function') return tagsFilter.toJS();
    
    // If it has a toArray method, use that (Immutable.js)
    if (typeof tagsFilter.toArray === 'function') return tagsFilter.toArray();
    
    // Last resort, convert to array if possible
    return Array.from(tagsFilter || []);
  }, [tagsFilter]); // Remove forceUpdate from dependencies
  
  // Listen for the custom filterchange event to force re-evaluation
  React.useEffect(() => {
    const handleFilterChange = () => {
      setForceUpdate(prev => prev + 1);
    };
    
    window.addEventListener('filterchange', handleFilterChange);
    
    // Apply URL filters on component mount if needed
    const params = new URLSearchParams(window.location.search);
    if (params.has('project_tags') || params.has('project_sort')) {
      // Directly apply tag filters if they exist in URL
      const projectTags = params.get('project_tags');
      if (projectTags && (!selectedTags || selectedTags.length === 0)) {
        const tagArray = projectTags.split(',');
        setProjectTagsFilter(tagArray);
      }
      
      handleFilterChange();
    }
    
    return () => {
      window.removeEventListener('filterchange', handleFilterChange);
    };
  }, [selectedTags, setProjectTagsFilter]);
  
  const { t } = useTranslation()

  const sortOptions = useMemo(() => {
    return [
      { key: PROJECTS_SORT_TYPE.INDEX, text: t('project_filters.manually') },
      { key: PROJECTS_SORT_TYPE.NAME, text: t('project_filters.alphabetically') },
      { key: PROJECTS_SORT_TYPE.TIMESTAMP, text: t('project_filters.modification_date') },
    ]
  }, [t])
  const showArchivedOptions = useMemo(() => {
    return [
      { key: PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ACTIVE, text: t('project_filters.archived_mode.only_active') },
      { key: PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ARCHIVED, text: t('project_filters.archived_mode.only_archived') },
      { key: PROJECTS_SHOW_ARCHIVED_OPTIONS.ALL, text: t('project_filters.archived_mode.all') },
    ]
  }, [t])

  const onClear = useCallback(() => {
    // Clear Redux filters
    dispatch(setDefaultFilterProjects(mode));
    
    // Clear URL parameters using the shared function
    import('../../hooks/filterHooks').then(module => {
      const { clearProjectFilterUrlParams } = module;
      clearProjectFilterUrlParams();
      
      // Trigger UI update
      window.dispatchEvent(new Event('filterchange'));
    });
  }, [dispatch, mode])

  const onClearText = useCallback(() => {
    dispatch(setProjectsTextFilter('', mode))
  }, [dispatch, mode])

  const onChangeText = useCallback(
    (evt, text) => {
      dispatch(setProjectsTextFilter(text, mode))
    },
    [dispatch, mode]
  )

  const onSearch = useCallback(
    text => {
      dispatch(setProjectsTextFilter(text, mode))
    },
    [dispatch, mode]
  )

  const onChangeUserGroup = useCallback(
    (event, option) => {
      const { groupID } = option
      dispatch(setProjectsUserGroupFilter(groupID, mode))
    },
    [dispatch, mode]
  )

  const onChangeSortType = useCallback(
    (event, item) => {
      setProjectSortFilter(item.key)
    },
    [setProjectSortFilter]
  )

  const onChangeShowArchived = useCallback(
    (event, item) => {
      dispatch(setProjectsShowArchivedFilter(item.key, mode))
    },
    [dispatch, mode]
  )

  const onInsertTagFilter = useCallback(
    tagName => {
      const currentTagNames = tagsFilter.toArray()
      const newTagNames = _.concat(currentTagNames, tagName)
      setProjectTagsFilter(newTagNames)
    },
    [tagsFilter, setProjectTagsFilter]
  )

  const onRemoveTagFilter = useCallback(
    removedTagName => {
      const currentTagNames = tagsFilter.toArray()
      _.remove(currentTagNames, tagName => tagName === removedTagName)
      setProjectTagsFilter(currentTagNames)
    },
    [tagsFilter, setProjectTagsFilter]
  )

  const onChangeTagsMode = useCallback(
    (e, option) => {
      setProjectTagsModeFilter(option.key)
    },
    [setProjectTagsModeFilter]
  )

  return (
    <FiltersView isFiltering={isFiltering} clearTitle={t('project_filters.clear_title')} onClear={onClear}>
      <SSearchBox
        id="projectFiltersView_searchBox"
        componentRef={searchBoxRef}
        value={textFilter}
        placeholder={t('project_filters.search_bar_placeholder')}
        onClear={onClearText}
        onChange={onChangeText}
        onSearch={onSearch}
      />
      <StyledDropdown
        id="projectFiltersView_dropdownSorting"
        label={t('project_filters.sort_type_label')}
        selectedKey={sortType}
        options={sortOptions}
        onChange={onChangeSortType}
      />
      <UserGroupsDropdown
        id="projectFiltersView_userGroupsDropdown"
        label={t('project_filters.user_group_label')}
        selectedID={userGroupFilter}
        onChange={onChangeUserGroup}
      />
      <StyledTagsContainer>
        <Label>{t('project_filters.tags_label')}</Label>
        <ChoiceGroup
          options={Object.values(TAGS_MODE_KEYS).map(key => ({
            key,
            text: t(`project_filters.tags_mode.${key}`),
            styles: { root: { margin: 0 } },
          }))}
          selectedKey={tagsMode}
          onChange={onChangeTagsMode}
          styles={{ flexContainer: 'flex gap-4', root: 'mb-2' }}
        />
        <SuggestionsTagPicker
          selectedTags={selectedTags}
          onAddTag={onInsertTagFilter}
          onRemoveTag={onRemoveTagFilter}
          type="project"
        />
      </StyledTagsContainer>
      <StyledDropdown
        id="projectFiltersView_archivedDropdown"
        label={t('project_filters.show_archived_label')}
        selectedKey={showArchived}
        options={showArchivedOptions}
        onChange={onChangeShowArchived}
      />
      <Link href={SERVER_URLS.RESTORE_PROJECTS} target="_blank" className="!mt-4">
        {t('project_filters.manage_deleted')}
      </Link>
    </FiltersView>
  )
}
