import React, { memo, Suspense, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { oneOnOneContainerGenerator } from '../../containers/oneOnOneContainer'
import { OneOnOneTopBar } from './OneOnOneTopBar'
import { graphStateHelper, itemHelper } from '../../common/src/helpers'
import { MainLayout } from '../../components/layout/MainLayout'
import { getRelativeURLKeepingQuerySearch, isInApp } from '../../helpers/routeHelper'
import { OfficeSplitView } from '../officeViews/officeSplit/OfficeSplitView'
import { OneOnOneContext } from '../../contexts'
import { NewItem1On1Panel } from '../../components/item/NewItem1On1Panel'
import { OneOnOnePlaceholderText } from './OneOnOnePlaceholderText'
import { RIGHT_PANEL } from '../../actions/uiActions'
import {
  baseState,
  localActions,
  localReducer,
  useMatchUrl,
  useMessageListener,
  useMountEffect,
  useReadLastRememberedCollaborator,
  useWatchCollaborator,
} from './OneOnOneUtils'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useMe } from '../../common/src/hooks/usersHooks'
import { useOrganizedUsersOptions } from '../../hooks/useUsersOption'
import { useConsumeDeepLink } from '../../hooks/msTeamsHooks'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { simulateLinkClick } from '../../common/src/utils'
import { getDidConsumeDeeplink } from '../../common/src/selectors/teamsSelectors'
import { setDidConsumeDeepLink } from '../../common/src/actions/teamsActions'
import { useParamsForItemsFilter } from '../../common/src/hooks/filtersHooks'
import { FILTER_REDUCER_KEYS, ITEM_FILTER_SECTION_KEY } from '../../common/src/reducers/filtersKeys'
import { getSearchItem, insertObjectInSearchWithID } from '../../common/src/actions/searchAPI'
import { getResourcesByText, getResourcesByUser } from '../../common/src/actions/graphAPI'
import { SEARCH_REDUCER_KEYS } from '../../common/src/reducers/searchKeys'
import {
  useExtendQueryParametersToCheckBannerInfo,
  useExtendQueryParametersWithSearchLoopback,
  useNeedToCheckSearchLookbackBanner,
} from '../../common/src/hooks/searchHooks'
import { SearchLookbackBannerForTable } from '../../components/table/SearchLookbackBannerForTable'
import styled from 'styled-components'
import { InviteUserButton } from '../../components/users/InviteUserButton'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../components/basic/Loading'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { TopBarAddButton } from '../../components/buttons/TopBarAddButton'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { useInitializeMsCollaborators, useRightPanel, useSelectedGraphItem } from '../../hooks/PMHooks'

const defaultLimit = 50
const MARGIN_SIDE = 8

const AddButton = TopBarAddButton
const StyledInviteUserButton = styled(InviteUserButton)`
  margin-left: ${MARGIN_SIDE}px;
`

const useTeamsLogic = history => {
  const didConsumeDeeplink = useSelector(getDidConsumeDeeplink)
  const { waiting, link, type } = useConsumeDeepLink()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!waiting && link && !didConsumeDeeplink) {
      history.replace(link)
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_TEAMS_DEEPLINK, {
          type,
        })
      )
      dispatch(setDidConsumeDeepLink())
    }
  }, [waiting, dispatch, didConsumeDeeplink, link, history, type])
}

const OneOnOne = memo(
  ({ searchedItems, selectedItem, match, history, appLocation, lastRememberedCollaborator, routeId }) => {
    const [state, localDispatch] = useReducer(localReducer, baseState)
    const dispatch = useDispatch()
    useInitializeMsCollaborators()
    const { selectedCollaborator, isOpenNewItemPanel, mode, text, loading, loadingGraph } = state

    const isGraphEnabled = useSelector(state => graphStateHelper.isGraphEnabled(state))
    const narrowWidth = useNarrowWidth()
    const inApp = isInApp(match.path)
    const isEmbedded = queryParamsHelper.isEmbedded()
    const isEmbeddedOnTeams = queryParamsHelper.isEmbeddedOnTeams()
    const isEmbeddedOnOutlook = queryParamsHelper.isEmbeddedOnOutlook()
    const canBeEnabledOffice = !isEmbedded
    const isEnabledOffice = isGraphEnabled && canBeEnabledOffice
    const showDetailView = !isEmbedded || isEmbeddedOnTeams || isEmbeddedOnOutlook
    const [bannerCount, setBannerCount] = useState(0)
    const needToCheckLookback = useNeedToCheckSearchLookbackBanner()
    const { t } = useTranslation()

    const selectItem = useCallback(
      itemId => {
        const getPath = inApp ? getRelativeURLKeepingQuerySearch.oneOnOneApp : getRelativeURLKeepingQuerySearch.oneOnOne
        history.push(getPath(selectedCollaborator, itemId))
      },
      [history, inApp, selectedCollaborator]
    )
    useMessageListener(selectItem)
    useTeamsLogic(history)
    useMountEffect({ match, localDispatch })
    useWatchCollaborator({
      appLocation,
      collaborator: selectedCollaborator,
    })
    useMatchUrl({
      collaborator: selectedCollaborator,
      text,
      history,
      routeId,
      itemId: itemHelper.getId(selectedItem),
    })
    useReadLastRememberedCollaborator({
      match,
      collaborator: lastRememberedCollaborator,
      localDispatch,
    })

    const me = useMe()
    const collaboratorOptions = useOrganizedUsersOptions({
      minusUsers: [me],
      addDivider: true,
    })

    const offsetRef = useRef(-1)
    const updateOffsetRef = useCallback(
      hasNext => {
        if (hasNext) {
          offsetRef.current = offsetRef.current + defaultLimit
        } else {
          offsetRef.current = -1
        }
      },
      [offsetRef]
    )

    const search = useCallback(
      async params => {
        const { user } = params
        if (!user) {
          return
        }
        const finalParams = {
          ...params,
          offset: offsetRef.current < 0 ? 0 : offsetRef.current,
          limit: defaultLimit,
        }
        localDispatch(localActions.setLoading(true))
        try {
          const result = await dispatch(getSearchItem(finalParams, SEARCH_REDUCER_KEYS.SECTION_ONE_ON_ONE))
          const hasNext = !!_.get(result, 'payload.meta.next')
          updateOffsetRef(hasNext)
        } finally {
          localDispatch(localActions.setLoading(false))
        }
      },
      [dispatch, localDispatch, offsetRef, updateOffsetRef]
    )
    const debouncedSearch = useMemo(() => _.debounce(search, 250, { trailing: true }), [search])

    const searchGraph = useCallback(
      async params => {
        const { q: text, user } = params
        if (_.isEmpty(text) && !user) {
          return
        }
        localDispatch(localActions.setLoadingGraph(true))
        try {
          const promiseByUser = user ? dispatch(getResourcesByUser(user)) : Promise.resolve()
          const promiseByText = text ? dispatch(getResourcesByText(text)) : Promise.resolve()
          await Promise.all([promiseByUser, promiseByText])
        } finally {
          localDispatch(localActions.setLoadingGraph(false))
        }
      },
      [dispatch, localDispatch]
    )
    const debouncedSearchGraph = useMemo(() => _.debounce(searchGraph, 250, { trailing: true }), [searchGraph])

    const baseParams = useParamsForItemsFilter(FILTER_REDUCER_KEYS.ONE_ON_ONE)
    const params = useExtendQueryParametersWithSearchLoopback(baseParams)
    const paramsWithUser = useMemo(() => {
      if (!selectedCollaborator) {
        return params
      }
      return { ...params, user: selectedCollaborator }
    }, [params, selectedCollaborator])
    const lookbackParams = useExtendQueryParametersToCheckBannerInfo(paramsWithUser)

    const checkLookbackItems = useCallback(async () => {
      try {
        const result = await dispatch(getSearchItem(lookbackParams, "lookback"))
        const totalCount = result?.payload?.meta?.total_count ?? 0
        setBannerCount(totalCount)
      } catch {}
    }, [dispatch, lookbackParams])

    useEffect(() => {
      offsetRef.current = 0
      debouncedSearch(paramsWithUser)
      if (isGraphEnabled) {
        debouncedSearchGraph(paramsWithUser)
      }
    }, [offsetRef, paramsWithUser, debouncedSearch, debouncedSearchGraph, isGraphEnabled])

    const selectedGraphItem = useSelectedGraphItem()
    const rightPanel = useRightPanel()
    const showingGraphItem = rightPanel === RIGHT_PANEL.GRAPH_RESOURCE

    const onSelectItem = useCallback(
      resource => {
        if (!isEmbedded && narrowWidth) {
          const itemID = itemHelper.getId(resource)
          const path = getRelativeURLKeepingQuerySearch.itemViewInAppForID(itemID)
          return history.push(path)
        }
        selectItem(itemHelper.getId(resource))

        // Desktop apps
        if (isEmbedded && !isEmbeddedOnOutlook) {
          const link = itemHelper.getProtocolLink(resource)
          simulateLinkClick(link)
        }
      },
      [isEmbedded, narrowWidth, selectItem, isEmbeddedOnOutlook, history]
    )

    const onSelectGraphItem = useCallback(
      graphItem => {
        if (narrowWidth) {
          const link = getRelativeURLKeepingQuerySearch.graphResourceViewInAppForID(graphItem.id)
          return history.push(link)
        }
        const getPath = inApp ? getRelativeURLKeepingQuerySearch.oneOnOneApp : getRelativeURLKeepingQuerySearch.oneOnOne
        history.push(getPath(selectedCollaborator, `graph-${graphItem.id}`))
      },
      [narrowWidth, inApp, history, selectedCollaborator]
    )

    const placeholders = useMemo(() => {
      return [
        {
          title: t('search.placeholder_0.title'),
          message: t('search.placeholder_0.message'),
          type: 1,
        },
      ]
    }, [t])

    const officePlaceholders = useMemo(() => {
      return [
        {
          title: t('search.office_placeholder_0.title'),
          message: t('search.office_placeholder_0.message'),
          type: 1,
        },
      ]
    }, [t])

    const onRenderLastItem = useCallback(() => {
      if (loading) {
        return
      }
      if (offsetRef.current < 0) {
        if (needToCheckLookback) {
          checkLookbackItems()
        }
        return
      }
      search(paramsWithUser)
    }, [loading, search, paramsWithUser, needToCheckLookback, checkLookbackItems])

    const placeholder = useRandomValueFromArray(placeholders)
    const officePlaceholder = useRandomValueFromArray(officePlaceholders)

    const showNewItemPanelFromTobBar = useCallback(() => {
      localDispatch(localActions.showNewItemPanelFromTobBar())
    }, [])

    const showNewItemPanelFromHeader = useCallback(() => {
      localDispatch(localActions.showNewItemPanelFromHeader())
    }, [])

    const hideNewItemPanel = useCallback(() => {
      localDispatch(localActions.hideNewItemPanel())
    }, [])

    const onChangeCollaborator = useCallback(e => {
      localDispatch(localActions.selectCollaborator(e))
    }, [])

    const onItemCreated = useCallback(
      id => {
        dispatch(insertObjectInSearchWithID(id, SEARCH_REDUCER_KEYS.SECTION_ONE_ON_ONE))
      },
      [dispatch]
    )

    const items = useMemo(() => {
      const array = searchedItems.toArray()
      if (bannerCount) {
        array.push(SearchLookbackBannerForTable(bannerCount))
      }
      return array
    }, [searchedItems, bannerCount])

    const graphItemsImmutable = useSelector(state =>
      graphStateHelper.getAllByUsernameAndText(state, selectedCollaborator, text)
    )
    const graphItems = useMemo(() => graphItemsImmutable.toArray(), [graphItemsImmutable])

    const components = {
      topBar: (
        <OneOnOneTopBar
          collaboratorOptions={collaboratorOptions}
          onChangeCollaborator={onChangeCollaborator}
          selected={selectedCollaborator}
          isEmbedded={isEmbedded}
          inApp={inApp}
          showAddButton={!isEnabledOffice}
          onCreateItem={showNewItemPanelFromTobBar}
          initialText={text}
          inviteUserButton={<StyledInviteUserButton email={selectedCollaborator} />}
          items={items}
        />
      ),
    }

    if (selectedCollaborator) {
      components.main = (
        <OfficeSplitView
          items={items}
          graphItems={graphItems}
          selectedGraphItem={showingGraphItem ? selectedGraphItem : null}
          onSelectItem={onSelectItem}
          onSelectGraphItem={onSelectGraphItem}
          onCreateItem={showNewItemPanelFromHeader}
          onRenderLastItem={onRenderLastItem}
          canBeEnabledOffice={canBeEnabledOffice}
          loading={loading}
          loadingGraph={loadingGraph}
          placeholder={placeholder}
          officePlaceholder={officePlaceholder}
          filterMode={ITEM_FILTER_SECTION_KEY.ONE_ON_ONE}
        />
      )
    } else {
      components.main = <OneOnOnePlaceholderText onChangeCollaborator={onChangeCollaborator} />
    }

    if (showDetailView) {
      const placeholderData = {
        title: t('one_on_one.item_detail_placeholder.title'),
        message: t('one_on_one.item_detail_placeholder.message'),
        type: IVIEW_PLACEHOLDER_TYPES.SELECTION,
      }
      const placeholderElement = (
        <Suspense fallback={<Loading />}>
          <LazyIViewPlaceholder {...placeholderData}>
            <AddButton
              id={'itemPlaceholder_addItemButton'}
              key={'AddButton'}
              text={t('one_on_one.add_shared_task')}
              onClick={showNewItemPanelFromHeader}
            />
          </LazyIViewPlaceholder>
        </Suspense>
      )
      const placeholder = selectedCollaborator && placeholderElement
      components.rightSide = <RightDetailView onItemCreated={onItemCreated} customPlaceholderElement={placeholder} />
    }

    const contextValue = { selectedCollaborator }
    return (
      <OneOnOneContext.Provider value={contextValue}>
        <MainLayout {...components} />
        <NewItem1On1Panel
          isOpen={isOpenNewItemPanel}
          onDismiss={hideNewItemPanel}
          collaborator={selectedCollaborator}
          mode={mode}
          onItemCreated={onItemCreated}
        />
      </OneOnOneContext.Provider>
    )
  }
)

export const OneOnOneView = oneOnOneContainerGenerator(OneOnOne)
