import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { fromJS } from 'immutable'
import { Stack } from '@fluentui/react/lib/Stack'
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { TextField } from '@fluentui/react/lib/TextField'
import { PersonaSize } from '@fluentui/react/lib/PersonaCoin'
import { CustomPersona } from '../users/CustomPersona'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { createItem1on1ContainerGenerator } from '../../containers/createItem1on1Container'
import { getAvatarURIForEmail, PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { resourceURICreator, uriDataCreator } from '../../common/src/helpers/URLHelper'
import { graphEventHelper, itemHelper, projectHelper } from '../../common/src/helpers'
import { AMPLITUDE_ACTION_TYPES } from '../../common/src/eventTracking/amplitudeEvents'
import { QuadrantsDropdown } from '../dropdown/QuadrantsDropdown'
import { ProjectsDropdown } from '../dropdown/ProjectsDropdown'
import { Spinner } from '../basic/Loading'
import { withCyAttr } from '../../helpers/styleHelper'
import { CYPRESS_ID } from '../../constants/cypress'
import { ErrorText } from '../text/ErrorText'
import { withTranslation } from 'react-i18next'
import { FlexColumn, FlexRow } from '../layout/FlexContainer'
import { ItemCreationNotes } from '../../views/modal/createItem/ItemCreationNotes'
import { linkSubject } from '../../reactions/linkSubject'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'
import { Label } from '@fluentui/react'
import { ProjectSelectorDrawer } from '@/views/modal/createItem/ProjectSelectorDrawer'

const isEmptyTrimming = _.flow([_.trim, _.isEmpty])

const OWNER = {
  ME: true,
  COLLABORATOR: false,
}

const switchButtonIcon = {
  iconName: 'Sync',
}

const SStack = styled(Stack)``
const stackTokens = { childrenGap: 8 }

const AddButtonSection = styled(FlexRow)`
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
`

const AddButton = styled(PrimaryButton).attrs(withCyAttr())`
  margin-left: 12px;
`

const SErrorText = styled(ErrorText)`
  text-align: end;
`

const styles = {
  switchButton: {
    root: {
      height: 40,
      width: 40,
    },
    icon: {
      fontSize: 25,
    },
  },
  notes: {
    fieldGroup: {
      minHeight: '10em',
    },
  },
}

const SwitchOwnerContainer = styled(FlexRow)`
  justify-content: center;
  align-items: center;
`

const AvatarContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`

class _CreateItem1on1 extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      project: 0,
      quadrant: 3,
      notes: '',
      owner: OWNER.COLLABORATOR,
      invalidMessage: null,
      creating: false,
      error: {
        name: '',
        creating: false,
      },
    }
    this._nameInput = React.createRef()
  }

  validate = () => {
    const { name } = this.state
    const invalidMessage = isEmptyTrimming(name) ? 'You must write a name!' : null
    this.setState({ invalidMessage })
    return !invalidMessage
  }

  onChangeName = evt => {
    const name = evt.target.value
    this.setState({ name })
  }

  onChangeNotes = value => {
    this.setState({ notes: value })
  }

  onKeyPressName = evt => {
    if (evt.charCode === KEY_CODE.ENTER) {
      evt.preventDefault()
      evt.stopPropagation()
      const input = this._nameInput.current
      if (input?.blur) {
        input.blur() //todo focus next field (if necessary)
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this._nameInput.current) {
        this._nameInput.current.focus()
      }
    })
    const { graphResource, getAllProjectsIfNeeded } = this.props
    getAllProjectsIfNeeded()
    if (graphResource) {
      const { name, descriptionText } = graphResource
      this.setState({
        name,
        notes: descriptionText,
      })
    }
  }

  onChangeProject = project => {
    this.setState({ project })
  }

  onQuadrantChanged = quadrant => {
    this.setState({ quadrant })
  }

  onPressCreate = async () => {
    if (this.state.creating) {
      return
    }
    const ok = this.validate()
    if (!ok) {
      return this.forceUpdate()
    }
    this.setState({ creating: true })
    const { name, project, quadrant, owner, notes } = this.state
    const { collaborator, graphResource } = this.props
    const _pseudoItem = {
      name,
      [itemHelper.KEYS.DESCRIPTION_TEXT]: notes,
    }
    if (graphResource) {
      _pseudoItem[itemHelper.KEYS.MAC_RESOURCE_URL] = graphResource.link || null
      if (graphEventHelper.isEvent(graphResource)) {
        _pseudoItem[itemHelper.KEYS.START_DATE] = graphEventHelper.getStartDateTimestamp(graphResource) || null
        _pseudoItem[itemHelper.KEYS.DUE_DATE] = graphEventHelper.getEndDateTimestamp(graphResource) || null
      }
    }
    if (project) {
      const projectID = projectHelper.getIdd(project)
      const uri = resourceURICreator(1, PM_API_RESOURCE_TYPE.PROJECT, projectID)
      _pseudoItem.projects = [uri]
      _pseudoItem[itemHelper.KEYS.QUADRANT] = quadrant
    }
    if (collaborator && owner === OWNER.COLLABORATOR) {
      _pseudoItem.owner_username = collaborator
    }
    const pseudoItem = fromJS(_pseudoItem)
    const itemResponse = await this.props.postItem(pseudoItem)
    if (itemResponse.error) {
      return this.setState({ error: itemResponse.error })
    }

    this.props.trackEvent(AMPLITUDE_ACTION_TYPES.CREATE_ITEM, { mode: this.props.mode })

    const item = itemResponse.payload
    if (owner !== OWNER.COLLABORATOR) {
      await this.props.inviteToItemWithId(item.id, collaborator)
    }

    linkSubject.next({ urlData: uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, item.id) })
    this.props.onItemCreated?.(item.id)
    this.props.dismiss()
  }

  onClickSwitch = () => {
    if (this.state.creating) {
      return
    }
    const owner = !this.state.owner
    this.setState({ owner })
  }

  getCustomPersona = (avatar, email) => (
    <CustomPersona
      size={PersonaSize.size32}
      imageUrl={avatar}
      hidePersonaDetails
      text={email}
      secondaryText={email}
      title={email}
    />
  )

  getCollaboratorPersona = () => {
    const { collaborator } = this.props
    return this.getCustomPersona(getAvatarURIForEmail(collaborator), collaborator)
  }

  getMePersona = () => {
    const { meEmail } = this.props
    return this.getCustomPersona(getAvatarURIForEmail(meEmail), meEmail)
  }

  render() {
    const { name, project, quadrant, owner, creating, notes, invalidMessage } = this.state
    const { commonProjects, collaborator, t } = this.props
    const ownerPersona = owner === OWNER.COLLABORATOR ? this.getCollaboratorPersona() : this.getMePersona()
    const followerPersona = owner === OWNER.ME ? this.getCollaboratorPersona() : this.getMePersona()
    return (
      <SStack tokens={stackTokens}>
        {collaborator && (
          <SwitchOwnerContainer className="ms-Grid-col ms-sm12" style={{ marginTop: '20px' }}>
            <AvatarContainer>
              {ownerPersona}
              <p>{t('one_on_one.owner')}</p>
            </AvatarContainer>
            <IconButton iconProps={switchButtonIcon} styles={styles.switchButton} onClick={this.onClickSwitch} />
            <AvatarContainer>
              {followerPersona}
              <p>{t('one_on_one.follower')}</p>
            </AvatarContainer>
          </SwitchOwnerContainer>
        )}
        <TextField
          multiline
          id="oneOnOneGrid_taskName"
          rows={3}
          value={name}
          required
          label={t('one_on_one.task_name')}
          resizable={false}
          onChange={this.onChangeName}
          componentRef={this._nameInput}
          onKeyPress={this.onKeyPressName}
          errorMessage={invalidMessage}
          disabled={creating}
          placeholder={t('item.title_placeholder')}
        />
        <ItemCreationNotes onChangeNotes={this.onChangeNotes} value={notes} placeholder={t('item.notes_placeholder')} />
        {hasTouchScreen() ? (
          <>
            <Label>{t('project.project')}</Label>
            <ProjectSelectorDrawer
              onSelectProject={(evt, project) => this.setState({ project })}
              selectedProject={project}
            />
          </>
        ) : (
          <ProjectsDropdown
            label={t('one_on_one.project')}
            enableInbox
            selectedProject={project}
            projects={commonProjects}
            onProjectChanged={this.onChangeProject}
            disabled={creating}
          />
        )}
        {!!project && (
          <QuadrantsDropdown
            label={t('one_on_one.quadrant')}
            project={project}
            quadrant={quadrant}
            onQuadrantChanged={this.onQuadrantChanged}
            disabled={creating}
          />
        )}
        <AddButtonSection>
          {creating && <Spinner size="medium" />}
          <AddButton onClick={this.onPressCreate} cy={CYPRESS_ID.ONE_ON_ONE_CREATE_TASK} text={t('general.add')} />
        </AddButtonSection>
        {this.state.error.creating && <SErrorText>Something went wrong! Try again later</SErrorText>}
      </SStack>
    )
  }
}

export const NewItem1On1 = withTranslation()(createItem1on1ContainerGenerator(_CreateItem1on1))

NewItem1On1.propTypes = {
  dismiss: PropTypes.func.isRequired,
  collaborator: PropTypes.string,
  graphResource: PropTypes.object,
  onItemCreated: PropTypes.func,
  mode: PropTypes.string,
}
