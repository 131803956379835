import styled from 'styled-components'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState, useMemo, memo, isValidElement } from 'react'
import { fromJS, List } from 'immutable'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import { BasicItemList } from '../item/basicList/BasicItemList'
import { QuadrantHeader, StyledAddButton } from './QuadrantHeader'
import { QuadrantPlaceholder } from './QuadrantPlaceholder'
import { itemHelper, userHelper } from '../../common/src/helpers'
import { mapValuesToJsArray } from '../../common/src/helpers/immutableHelpers'
import { scrollToItem } from '../../reactions'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useCellStyles } from '../itemCell/ItemCellStyles'
import { GhostItemsList } from '../list/GhostItemsList'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'
import { useTranslation } from 'react-i18next'
import { Column } from '../layout/FlexContainer'
import { ItemCell } from '@/components/itemCell/ItemCell'
import { GhostItemCell } from '@/components/itemCell/GhostItemCell'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'
import { Button, Input, Portal } from '@fluentui/react-components'
import { Add } from '@/components/BundledIcons'
import { useCreateItem, useGetIndexForNewItem } from '@/queries/items'
import { useMe } from '@/common/src/hooks/usersHooks'
import { useMutationState } from '@tanstack/react-query'
import { useSingleAndDoubleClick } from '@/common/src/hooks/useSingleAndDoubleClick'

const contextualMenuKeys = {
  new: 'new',
  createLinkProject: 'createLinkProject',
}

const StyledQuadrantHeader = styled(QuadrantHeader)``

const QuadrantContainer = styled(Column)`
  user-select: none;

  ${StyledAddButton} {
    display: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover ${StyledAddButton} {
      display: inherit;
      opacity: 1;
    }
  }
`

const ListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${p => p.background};
  position: relative;

  &.resizing {
    overflow: hidden;
  }
`

const useItemListStyles = (background, text) => {
  const defStyles = useCellStyles()
  return useMemo(() => {
    return {
      ...defStyles,
      text,
      background,
    }
  }, [background, defStyles, text])
}

const randomInt32 = () => {
  const array = new Uint32Array(1)
  crypto.getRandomValues(array)
  return array[0]
}

const Quadrant_ = ({
  items = List(),
  isFiltering = false,
  onClick,
  headerBackgroundColor,
  headerTextColor,
  bodyBackgroundColor,
  cellTextColor,
  name,
  selectedItemId,
  listClassName = '',
  idx,
  narrow = false,
  readOnly = false,
  loading = false,
  project,
  showOwner = true,
  onQuickItemCreated,
}) => {
  const virtualize = !hasTouchScreen()
  const listRef = useRef(null)
  const createItemModal = useCreateItemModal()
  const listStyles = useItemListStyles(bodyBackgroundColor, cellTextColor)
  const me = useMe()

  const [isAdding, setIsAdding] = useState(false)

  const [newItemName, setNewItemName] = useState('')
  const { mutate: createItem } = useCreateItem({
    open: false,
    above: false,
    projectIdd: project.get('idd'),
    quadrant: idx,
    onSuccess: onQuickItemCreated,
  })
  const getNewItemIndex = useGetIndexForNewItem(project.get('idd'), idx, false)
  const mutations = useMutationState({
    filters: {
      mutationKey: ['item', 'create', { projectIdd: project.get('idd'), quadrant: idx }],
      status: 'pending',
    },
  })

  const inlineAddItemInput = isAdding ? (
    <div className="flex p-1">
      <Input
        value={newItemName}
        data-testid="Quadrant_QuickItemInput"
        onChange={(ev, data) => setNewItemName(data.value)}
        appearance="underline"
        className="w-full min-w-0"
        autoFocus
        onKeyDown={event => {
          if (event.key === 'Escape') {
            setIsAdding(false)
          }
          if (event.key === 'Enter') {
            if (newItemName.trim() === '') {
              setIsAdding(false)
              return
            }
            setNewItemName('')
            createItem({
              name: event.target.value,
              projectId: project.get('idd'),
              quadrant: idx,
              idd: randomInt32(),
              index: getNewItemIndex(),
              eventProps: {
                mode: EVENT_EXTRA.CREATE_ITEM.MODE.QUADRANT,
              },
            })
          }
        }}
        onBlur={() => {
          if (newItemName.trim() === '') {
            setIsAdding(false)
          }
        }}
      />
    </div>
  ) : (
    <Button
      icon={<Add />}
      appearance="transparent"
      className="min-h-10 w-full !justify-start"
      onClick={() => setIsAdding(true)}
      data-testid={`Quadrant_QuickItemButtonQ${idx}`}
    >
      Add Item
    </Button>
  )

  const extraItems = mutations
    .filter(mutation => !items.some(item => item.get('idd') === mutation.variables.idd))
    .map(mutation =>
      fromJS({ ...mutation.variables, id: mutation.variables.idd, owner_username: userHelper.getEmail(me) })
    )
    .concat(inlineAddItemInput)

  const itemList = mapValuesToJsArray(items, _.identity)
  const itemListLength = itemList.length
  const renderList = itemList.concat(extraItems)

  useEffect(() => {
    const subscription = scrollToItem.subscribe(item => {
      const id = itemHelper.getId(item)
      const quadrant = itemHelper.getQuadrant(item)
      if (quadrant !== idx) {
        return
      }

      if (_.isNumber(id) && renderList) {
        const itemIdx = _.findIndex(renderList, i => itemHelper.getId(i) === id)
        if (itemIdx >= 0) {
          const element = listRef.current
          if (element?.scrollToIndex) {
            listRef.current.scrollToIndex({ index: itemIdx, align: 'center' })
          }
        }
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [idx, renderList])

  const { t } = useTranslation()
  const [showContextualMenu, setShowContextualMenu] = useState(false)
  const [contextualMenuTarget, setContextualMenuTarget] = useState()

  const handleShowContextualMenu = event => {
    event.preventDefault()
    event.stopPropagation()
    setContextualMenuTarget(event)
    setShowContextualMenu(true)
  }

  const handleHideContextualMenu = () => {
    setShowContextualMenu(false)
  }

  const openNewItemModal = useCallback(
    mode => {
      createItemModal({
        quadrant: idx,
        initialProject: project,
        mode,
      })
    },
    [createItemModal, idx, project]
  )

  const handleAddItem = () => {
    openNewItemModal(EVENT_EXTRA.CREATE_ITEM.MODE.CONTEXTUAL_MENU)
  }

  const handleCreateLinkProject = useCallback(() => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.CONTEXTUAL_MENU,
      initialProject: project,
      quadrant: idx,
      displayProjectSelector: false,
      showLinkedProjectSelector: true,
      open: false,
    })
  }, [createItemModal, idx, project])

  const contextualMenuOptions = [
    {
      id: 'itemList_new',
      key: contextualMenuKeys.new,
      text: t('item.create_item'),
      iconProps: { iconName: 'Add' },
      onClick: handleAddItem,
      hidden: readOnly,
    },
    {
      id: 'itemList_createLinkProject',
      key: contextualMenuKeys.createLinkProject,
      text: t('item.create_link_project'),
      iconProps: { iconName: 'Add' },
      onClick: handleCreateLinkProject,
    },
  ].filter(o => !o.hidden)

  const onClickAddHeader = () => {
    openNewItemModal(EVENT_EXTRA.CREATE_ITEM.MODE.MATRIX_TOP_BAR)
  }

  const handleDoubleClick = useCallback(() => {
    openNewItemModal(EVENT_EXTRA.CREATE_ITEM.MODE.DOUBLE_CLICK)
  }, [openNewItemModal])
  const handleClick = useSingleAndDoubleClick(() => {}, handleDoubleClick)
  const isEmpty = itemListLength === 0

  const renderDroppable = (provided, snapshot) => {
    const { isDraggingOver } = snapshot
    const list = isDraggingOver ? [...renderList, provided.placeholder] : renderList
    return (
      <ListContainer
        ref={provided.innerRef}
        background={bodyBackgroundColor}
        className={listClassName}
        onClick={readOnly ? undefined : handleClick}
        {...provided.droppableProps}
      >
        {isEmpty && <QuadrantPlaceholder count={itemListLength} isFiltering={isFiltering} readOnly={readOnly} />}
        {list.map((item, idx) => {
          // Generate a stable key for React elements based on content
          if (isValidElement(item)) {
            // For React elements like the inline add button, use a stable identifier
            // If it's the add button component, give it a specific key
            const elementType = item.type?.displayName || item.type?.name || 'unknown';
            return React.cloneElement(item, { key: `${elementType}-${idx}` });
          }
          
          if (!item || !item.get) {
            // For ghost cells, we need a more stable key than just the index
            // Since these items don't have IDs, we'll add a prefix to make the key more descriptive
            return <GhostItemCell key={`ghost-${idx}`} />
          }
          const id = item.get('id')
          return (
            <Draggable draggableId={id?.toString()} index={idx} key={id?.toString() ?? idx}>
              {(provided, snapshot) => {
                return (
                  <ItemCell
                    allItemsInList={items}
                    item={item}
                    idx={idx}
                    onClick={onClick}
                    styles={listStyles}
                    selected={id === selectedItemId}
                    narrow={narrow}
                    readOnly={readOnly}
                    innerRef={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    showOwner={showOwner}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                  />
                )
              }}
            </Draggable>
          )
        })}
      </ListContainer>
    )
  }

  const renderVirtualizedDroppable = (provided, snapshot) => {
    const { isDraggingOver } = snapshot
    return (
      <ListContainer
        background={bodyBackgroundColor}
        className={listClassName}
        onClick={readOnly ? undefined : handleClick}
      >
        {isEmpty && <QuadrantPlaceholder count={itemListLength} isFiltering={isFiltering} readOnly={readOnly} />}
        <BasicItemList
          items={renderList}
          onClickItem={onClick}
          selectedItemId={selectedItemId}
          itemCellStyles={listStyles}
          readOnly={readOnly}
          draggable
          placeholder={isDraggingOver && provided.placeholder}
          narrow={narrow}
          ref={listRef}
          showOwner={showOwner}
          followOutput={isAdding ? 'smooth' : false}
          scrollerRef={provided.innerRef}
          {...provided.droppableProps}
        />
      </ListContainer>
    )
  }
  return (
    <>
      <QuadrantContainer onContextMenu={handleShowContextualMenu}>
        <StyledQuadrantHeader
          itemCount={itemListLength}
          q={idx}
          name={name}
          background={headerBackgroundColor}
          textColor={headerTextColor}
          onClickAdd={onClickAddHeader}
          readOnly={readOnly}
          project={project}
        />
        {loading ? (
          <ListContainer background={bodyBackgroundColor}>
            <GhostItemsList shimmerColors={{ background: bodyBackgroundColor }} />
          </ListContainer>
        ) : virtualize ? (
          <Droppable
            mode="virtual"
            droppableId={`q${idx}`}
            renderClone={(provided, snapshot, rubric) => (
              <Portal>
                <ItemCell
                  item={renderList[rubric.source.index]}
                  selected={renderList[rubric.source.index].get('id') === selectedItemId}
                  narrow={narrow}
                  readOnly={readOnly}
                  innerRef={provided.innerRef}
                  isDragging={snapshot.isDragging}
                  showOwner={showOwner}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                />
              </Portal>
            )}
          >
            {renderVirtualizedDroppable}
          </Droppable>
        ) : (
          <Droppable droppableId={`q${idx}`}>{renderDroppable}</Droppable>
        )}
      </QuadrantContainer>
      <ContextualMenu
        items={contextualMenuOptions}
        hidden={!showContextualMenu}
        target={contextualMenuTarget}
        onDismiss={handleHideContextualMenu}
      />
    </>
  )
}

export const Quadrant = memo(Quadrant_)
