import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  FluentProvider,
  webLightTheme,
} from '@fluentui/react-components'
import { useMemo, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useAtom } from 'jotai'
import {
  printItemLastCommentsAtom,
  printItemNotesAtom,
  printItemOwnerFollowersAtom,
  printItemResourcesAtom,
  printItemTagsAtom,
} from '@/atoms/printItemAtoms'
import { itemHelper, stateHelper, userHelper } from '@/common/src/helpers'
import { formatDate } from '@/utils/datefns'
import { TagDetails } from '@/components/tags/TagDetails'
import { FilesSectionContent } from '@/views/ItemDetail/FilesSectionContent'
import { LinksSectionContent } from '@/views/ItemDetail/LinksSectionContent'
import { useSelector } from 'react-redux'
import { useCommentsInItem } from '@/queries/comments'
import { PeopleSection } from '@/components/item/PeopleSection'
import { useTranslation } from 'react-i18next'
import { PMChatMessage } from '@/components/chat/PMChatMessage'
import { resourceURIParser } from '@/common/src/helpers/URLHelper'
import { useMe, useUserForEmail } from '@/common/src/hooks/usersHooks'
import { EFFORT_TRANSLATION_KEYS } from '@/components/effort/commonEffort'
import { UsersList } from '@/components/users/usersList/UsersList'
import { UsersListCell } from '@/components/users/usersList/UsersListCell'

export type PrintItemDialogProps = {
  item: any
  open: boolean
  onDismiss: () => void
}

export const PrintItemDialog = ({ item, open, onDismiss }: PrintItemDialogProps) => {
  const { t } = useTranslation()
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const me = useMe()

  const [printItemNotes, setPrintItemNotes] = useAtom(printItemNotesAtom)
  const [printItemResources, setPrintItemResources] = useAtom(printItemResourcesAtom)
  const [printItemTags, setPrintItemTags] = useAtom(printItemTagsAtom)
  const [printItemLastComments, setPrintItemLastComments] = useAtom(printItemLastCommentsAtom)
  const [printItemOwnerFollowers, setPrintItemOwnerFollowers] = useAtom(printItemOwnerFollowersAtom)

  const dateFormat = itemHelper.getAllDay(item) ? 'ShortenedAllDay' : 'ShortenedNormal'

  const startDate = itemHelper.getStartDate(item)
  const dueDate = itemHelper.getDueDate(item)
  const links = useSelector(state => stateHelper.getAssociatedLinksForItem(state, itemHelper.getId(item)))
  const linksItems = useMemo(() => links.toJSON(), [links])
  const { data: commentsPages } = useCommentsInItem(itemHelper.getId(item), {
    enabled: open,
  })
  const firstThreeComments = commentsPages?.pages
    .flatMap(page => page.objects)
    .filter(comment => !comment.is_read)
    .slice(0, 3)
    .reverse()
  const ownerEmail = itemHelper.getOwnerUsername(item)
  const owner = useUserForEmail(ownerEmail)
  const followers = itemHelper.getFollowers(item)?.toArray()

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) {
          onDismiss()
        }
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('print_item_dialog.title')}</DialogTitle>
          <DialogContent>
            <div className="flex flex-col">
              <div>{t('print_item_dialog.select_fields')}</div>
              <div className="flex flex-col">
                <Checkbox
                  label={t('print_item_dialog.selector.owner_followers')}
                  checked={printItemOwnerFollowers}
                  onChange={(ev, data) => setPrintItemOwnerFollowers(data.checked as boolean)}
                />
                <Checkbox
                  label={t('print_item_dialog.selector.tags')}
                  checked={printItemTags}
                  onChange={(ev, data) => setPrintItemTags(data.checked as boolean)}
                />
                <Checkbox
                  label={t('print_item_dialog.selector.notes')}
                  checked={printItemNotes}
                  onChange={(ev, data) => setPrintItemNotes(data.checked as boolean)}
                />
                <Checkbox
                  label={t('print_item_dialog.selector.resources')}
                  checked={printItemResources}
                  onChange={(ev, data) => setPrintItemResources(data.checked as boolean)}
                />
                <Checkbox
                  label={t('print_item_dialog.selector.last_comments')}
                  checked={printItemLastComments}
                  onChange={(ev, data) => setPrintItemLastComments(data.checked as boolean)}
                />
              </div>
              <div className="hidden">
                <FluentProvider theme={webLightTheme} ref={componentRef} className="flex flex-col gap-3">
                  <h2 className="flex items-center gap-2">
                    <span className="flex-1">{itemHelper.getName(item)}</span>
                    <PeopleSection
                      item={item}
                      readOnly
                      onChangeOwner={undefined}
                      addFollowerTooltip={undefined}
                      containerRef={undefined}
                      openFollowersPanel={undefined}
                    />
                  </h2>
                  <div>
                    <div>
                      {t('print_item_dialog.page.progress', { progress: itemHelper.getCompletionPercentage(item) })}
                    </div>
                    {itemHelper.getEffort(item) !== itemHelper.EFFORT_KEYS.NO_EFFORT && (
                      <div>
                        {t('print_item_dialog.page.effort', {
                          effort: t(EFFORT_TRANSLATION_KEYS[itemHelper.getEffort(item)]),
                        })}
                      </div>
                    )}
                    {!!startDate && (
                      <div>
                        {t('print_item_dialog.page.start_date', { start_date: formatDate(dateFormat)(startDate) })}
                      </div>
                    )}
                    {!!dueDate && (
                      <div>{t('print_item_dialog.page.due_date', { due_date: formatDate(dateFormat)(dueDate) })}</div>
                    )}
                  </div>
                  {printItemOwnerFollowers && (
                    <div>
                      <h3>{t('print_item_dialog.page.owner')}</h3>
                      {/* @ts-expect-error */}
                      <UsersListCell user={owner} />
                      <h3>{t('print_item_dialog.page.followers')}</h3>
                      {/* @ts-expect-error */}
                      <UsersList usersOptions={followers?.map(follower => ({ user: follower }))} readOnly />
                    </div>
                  )}
                  {printItemTags && (
                    <div>
                      <h3>{t('print_item_dialog.page.tags')}</h3>
                      {itemHelper.getTags(item).size ? (
                        // @ts-expect-error
                        <TagDetails object={item} placeholderTagProps={{ id: 'itemDetail_addTagsPlaceholder' }} />
                      ) : (
                        t('print_item_dialog.page.no_tags')
                      )}
                    </div>
                  )}
                  {printItemNotes && (
                    <div>
                      <h3>{t('print_item_dialog.page.notes')}</h3>
                      <div>
                        {itemHelper
                          .getNotes(item)
                          ?.split('\n')
                          .map((line: string, index: number) => <p key={index}>{line}</p>)}
                      </div>
                    </div>
                  )}
                  {printItemLastComments && (
                    <div className="flex flex-col">
                      <h3>{t('print_item_dialog.page.last_comments')}</h3>
                      {firstThreeComments?.map((comment, index) => (
                        <PMChatMessage
                          key={comment.id}
                          comment={comment}
                          previousComment={
                            index > 0
                              ? { ...firstThreeComments[index - 1], type: 'comment', readReceipts: {} }
                              : undefined
                          }
                          mine={resourceURIParser(comment.author)?.id === userHelper.getID(me)}
                        />
                      ))}
                    </div>
                  )}
                  {printItemResources && (
                    <div className="w-full">
                      <h3>{t('print_item_dialog.page.resources')}</h3>
                      <FilesSectionContent item={item} readOnly className="w-screen" onAddFile={undefined} />
                      <LinksSectionContent
                        item={item}
                        readOnly
                        linksItems={linksItems}
                        selectedLink={{}}
                        className="w-screen"
                        setSelectedLink={undefined}
                        loading={undefined}
                        setLoading={undefined}
                        setShowLinkModalToEdit={undefined}
                      />
                    </div>
                  )}
                </FluentProvider>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onDismiss}>{t('modal.close')}</Button>
            <Button appearance="primary" onClick={handlePrint}>
              {t('general.print')}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
