import { memo, useCallback, useRef, useEffect } from 'react'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { Matrix } from '../../components/matrix/Matrix'
import { getRelativeURLRemovingSearchParams } from '../../helpers/routeHelper'
import { MainLayout } from '../../components/layout/MainLayout'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'
import { OnboardingView } from '../../components/onboarding/OnboardingView'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { itemHelper, projectHelper } from '../../common/src/helpers'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useReactToPrint } from 'react-to-print'
import { PrintableComponent } from '@/components/PrintableComponent'
import { useDispatch, useSelector } from 'react-redux'
import { setPrefersDarkMode } from '@/actions/uiActions'
import { getPrefersDarkMode } from '@/selectors/uiSelectors'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { useHistory } from 'react-router-dom'
// import { setItemsTextFilter } from '../../common/src/actions/filtersActions'
// Import the filter effect hook
import { useSearchFilterParamsEffect } from '../../hooks/filterHooks'

const ProjectMatrixView_ = ({
  project,
  items,
  selectedItemId,
  sortFn,
  loading,
  projectSelectorEnabled = true,
  readOnly = false,
  matrixPreviewMode = false,
  showOwner = true,
  filtersType,
}) => {
  const dispatch = useDispatch()
  const narrowWidth = useNarrowWidth()

  // Process URL parameters for filter values in project matrix view
  const history = useHistory()
  
  // Use search filter params effect to update URL when filters change
  // and apply URL parameters to filters when loading the page
  useSearchFilterParamsEffect(FILTER_REDUCER_KEYS.PROJECT_MATRIX);
  
  // Check if we should clear search parameters from the URL
  useEffect(() => {
    // Check if we've navigated from search view
    const wasInSearchView = sessionStorage.getItem('isInSearchView') === 'true' || 
                          sessionStorage.getItem('clearSearchParams') === 'true';
    
    if (wasInSearchView) {
      // Clear the flags
      sessionStorage.removeItem('clearSearchParams');
      sessionStorage.removeItem('isInSearchView');
      
      // Create a clean URL without search params
      const url = new URL(window.location.href);
      const searchParams = ['q', 'item_state', 'owners', 'tags', 'projects', 'quadrant'];
      
      // Remove search-specific parameters
      searchParams.forEach(param => {
        url.searchParams.delete(param);
      });
      
      // Update the URL without triggering a page reload
      history.replace(url.pathname + (url.search ? url.search : ''));
    }
  }, [history]);

  const onClickItem = useCallback(
    item => {
      if (matrixPreviewMode) {
        return
      }
      
      // Detect if we're in a search view by checking the URL
      const isInSearchView = window.location.pathname.includes('/search/');
      
      // Get the item ID
      const serverID = itemHelper.getId(item)
      
      // Create the item URL data
      const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, serverID)
      
      // If we're in a search view, add a special flag to the URL data
      // to indicate that we should clear search parameters when navigating
      if (isInSearchView) {
        // Store the current path in session storage to handle the redirect
        sessionStorage.setItem('clearSearchParams', 'true');
      }
      
      // Navigate to the item
      linkSubject.next({ 
        urlData, 
        source: SOURCES.MATRIX,
      })
    },
    [matrixPreviewMode]
  )

  const mainRef = useRef()
  const selectedMode = useSelector(getPrefersDarkMode)
  const previousMode = useRef(null)

  const printMain = useReactToPrint({
    content: () => mainRef.current,
    onBeforeGetContent: async () => {
      previousMode.current = selectedMode
      dispatch(setPrefersDarkMode('light'))
    },
    onAfterPrint: () => {
      dispatch(setPrefersDarkMode(previousMode.current))
    },
  })

  const showOnboardingView = !narrowWidth && !readOnly

  const main = (
    <PrintableComponent pageName={projectHelper.getName(project)} className="print:h-screen" ref={mainRef}>
      <Matrix
        items={items}
        onClickItem={onClickItem}
        project={project}
        selectedItemId={selectedItemId}
        sortFn={sortFn}
        readOnly={readOnly}
        loading={loading}
        showOwner={showOwner}
        filtersType={filtersType}
      />
      {showOnboardingView && <OnboardingView />}
    </PrintableComponent>
  )
  const detail = <RightDetailView readOnly={readOnly} />
  const topBar = (
    <ProjectLevelTopBar
      getNavigationURLForProjectId={getRelativeURLRemovingSearchParams.matrixForProjectId}
      key={'ProjectMatrixViewTopBar'}
      project={project}
      projectSelectorEnabled={projectSelectorEnabled}
      readOnly={readOnly}
      items={items}
      printMain={printMain}
    />
  )
  let subViews = { main }
  if (!matrixPreviewMode) {
    subViews = {
      ...subViews,
      rightSide: detail,
      topBar,
    }
  }

  return <MainLayout {...subViews} />
}

export const ProjectMatrixView = memo(ProjectMatrixView_)
