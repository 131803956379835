import { OwnerPanelContext } from '../../typedContexts'
import { OwnerPanel } from '../../components/item/OwnerPanel'
import { type ReactNode, useCallback, useMemo, useState } from 'react'

export const OwnerPanelSingleton = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [onSelectOwner, setOnSelectOwner] = useState<((newOwner: string) => void) | null>(null)
  const [project, setProject] = useState<any>(null)
  const contextValue = useMemo(
    () => ({
      openPanel: (onSelectOwner: (newOwner: string) => void, project: any) => {
        setOpen(true)
        setOnSelectOwner(() => onSelectOwner)
        setProject(project)
      },
    }),
    []
  )
  const handleDismiss = useCallback(() => {
    setOpen(false)
    setOnSelectOwner(null)
  }, [])

  return (
    <OwnerPanelContext.Provider value={contextValue}>
      {children}
      {/*// @ts-expect-error*/}
      <OwnerPanel isOpen={open} onChange={onSelectOwner} onDismiss={handleDismiss} project={project} />
    </OwnerPanelContext.Provider>
  )
}
