// src/views/home/useFilteredAlertItems.js
import { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { useAttentionNeededInSession } from '../../common/src/hooks/itemHooks'

// Same sorting function from useAlertItems.js
const compareItemsByMentionAndTimestamp = (a, b) => {
  // Items with unread mentions go first
  const aUnreadMention = itemHelper.hasUnreadMention(a)
  const bUnreadMention = itemHelper.hasUnreadMention(b)
  if (aUnreadMention && !bUnreadMention) return -1
  if (!aUnreadMention && bUnreadMention) return 1

  // Items with notification === 'delegation' go first
  const aNotification = itemHelper.getNotification(a)
  const bNotification = itemHelper.getNotification(b)
  if (aNotification === 'delegation' && bNotification !== 'delegation') return -1
  if (aNotification !== 'delegation' && bNotification === 'delegation') return 1

  // Items with notification === 'invitation' go first
  if (aNotification === 'invitation' && bNotification !== 'invitation') return -1
  if (aNotification !== 'invitation' && bNotification === 'invitation') return 1

  // Items with notification go first
  if (aNotification && !bNotification) return -1
  if (!aNotification && bNotification) return 1

  // Latest modified items go first
  const aTimestamp = itemHelper.getLastModifiedTimestamp(a)
  const bTimestamp = itemHelper.getLastModifiedTimestamp(b)
  if (aTimestamp > bTimestamp) return -1
  if (aTimestamp < bTimestamp) return 1
  return 0
}

/**
 * Hook to get alert items filtered by notification type
 * 
 * @param {string} filterType - Type of notification to filter by (e.g., 'delegation', 'invitation', 'mention', 'all')
 * @param {number} forceAlertId - ID of an item to force into the list
 * @returns {Array} Filtered and sorted alert items
 */
export const useFilteredAlertItems = (filterType = 'all', forceAlertId = 0) => {
  // Ensure we have a valid filter type
  const safeFilterType = filterType || 'all';
  
  const alerts = useAttentionNeededInSession()
  const allItems = useSelector(stateHelper.getAllItemsMap)
  const forced = useSelector(st => stateHelper.getItem(st, forceAlertId))
  const list = forced ? alerts.add(forced) : alerts

  const [previousOrder, setPreviousOrder] = useState([])

  // Filter alerts based on notification type
  const filteredList = useMemo(() => {
    if (safeFilterType === 'all') {
      return list;
    }
    
    // Create a new filtered list based on the filter type
    return list.filter(item => {
      const notification = itemHelper.getNotification(item);
      const hasUnreadMention = itemHelper.hasUnreadMention(item);
      const dueDate = itemHelper.getDueDate(item);
      const isOverdue = dueDate && dueDate < new Date() && !itemHelper.isCompleted(item);
      const name = itemHelper.getName(item) || '';
      const hasOverdueLabel = name.includes('Overdue');
      const isPaymentItem = name.toLowerCase().includes('payment');
      
      if (safeFilterType === 'delegation') {
        return notification === 'delegation';
      } else if (safeFilterType === 'invitation') {
        return notification === 'invitation';
      } else if (safeFilterType === 'mention') {
        return hasUnreadMention;
      } else if (safeFilterType === 'overdue') {
        return isOverdue || hasOverdueLabel;
      } else if (safeFilterType === 'other') {
        return isPaymentItem || (notification && 
               notification !== 'delegation' && 
               notification !== 'invitation' && 
               !hasUnreadMention &&
               !isOverdue &&
               !hasOverdueLabel) ||
               (!notification && !hasUnreadMention && !isOverdue && !hasOverdueLabel);
      }
      
      return true;
    });
  }, [list, safeFilterType]);

  const currentIds = useMemo(() => new Set(filteredList.map(item => itemHelper.getId(item))), [filteredList]);
  const previousIds = useMemo(() => new Set(previousOrder.map(item => itemHelper.getId(item))), [previousOrder]);

  // Check if we have the same set of IDs
  const hasSameIds = currentIds.size === previousIds.size && [...currentIds].every(id => previousIds.has(id));

  useEffect(() => {
    // Update previous order when the filtered list changes
    if (!hasSameIds) {
      const currentItems = filteredList.toIndexedSeq().sort(compareItemsByMentionAndTimestamp).toArray();
      setPreviousOrder(currentItems);
    }
  }, [filteredList, hasSameIds]);

  if (hasSameIds) {
    // Maintain previous order but use updated item data
    return previousOrder
      .filter(prevItem => currentIds.has(itemHelper.getId(prevItem)))
      .map(prevItem => {
        const id = itemHelper.getId(prevItem);
        return allItems.get(id);
      });
  } else {
    // Return the newly sorted items
    return filteredList.toIndexedSeq().sort(compareItemsByMentionAndTimestamp).toArray();
  }
}
