import { useDeferredValue, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stateHelper } from '../../common/src/helpers'
import { useQuery } from '@tanstack/react-query'
import { oneProjectHandler as init } from '../../actions/viewInitializationActions'
import { isReadOnly } from '../../helpers/routeHelper'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { 
  setProjectsTagsFilter, 
  setProjectsSortType 
} from '../../common/src/actions/filtersActions'
import { InvalidObjectComponent, useIsObjectInvalidToBeShown } from '../errorViews/InvalidObjectComponent'
import { ProjectMatrixView } from '../matrix/ProjectMatrixView'
import { PMCalendarView } from '../calendar/PMCalendarView'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { Feed } from '../feed/Feed'
import { GanttView } from '../gantt/v2/GanttView'
import { getFiltersTypeByMode, getProjectModeFromPathname, PROJECT_MODE } from '../../utils/projectModesUtils'
import { useItemSortDescriptor } from '../../common/src/hooks/filtersHooks'
import { useApplyFiltersToItems } from '../../hooks/itemHooks'
import { MatrixTableView } from '../matrixTable/MatrixTableView'
import { PMProjectAnalyticsReport } from '../reports/PMProjectAnalyticsReport'
import { withBusiness } from '../withBusiness'
import { KanbanView } from '../kanban/KanbanView'

const Gantt = withBusiness(GanttView)

const checkEmbeddedInProjectCreationModal = () => {
  try {
    const href = window.parent?.location.href ?? ''
    return href.includes('new_project')
  } catch (_err) {
    return false
  }
}

// Force the application of project tags from URL
const ForceApplyTagsFromUrl = ({ filtersType }) => {
  const dispatch = useDispatch();
  
  // Force apply URL parameters right on component mount
  useEffect(() => {
    try {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const tagParam = params.get('project_tags');
      const sortParam = params.get('project_sort');
      
      if (tagParam) {
        console.warn('[FORCE APPLY] Applying project tags from URL:', tagParam);
        
        // Validate: only allow alphanumeric characters, dashes, and underscores in tag names
        const tagArray = tagParam.split(',')
          .map(tag => tag.trim())
          .filter(tag => /^[a-zA-Z0-9_-]+$/.test(tag));
        
        // Direct dispatch with a slight delay to ensure DOM is ready
        setTimeout(() => {
          dispatch(setProjectsTagsFilter(tagArray, filtersType));
        }, 500);
      }
      
      if (sortParam) {
        console.warn('[FORCE APPLY] Applying project sort from URL:', sortParam);
        // Use a strict whitelist approach for sorting parameters
        const validSortTypes = ['name', 'index', 'timestamp', 'starred', 'pinned'];
        // Only proceed if the sort parameter exactly matches one of our valid options
        if (validSortTypes.includes(sortParam)) {
          setTimeout(() => {
            dispatch(setProjectsSortType(sortParam, filtersType));
          }, 500);
        } else {
          console.warn('Ignored invalid sort parameter:', sortParam);
        }
      }
    } catch (error) {
      console.error('Error in ForceApplyTagsFromUrl:', error);
    }
  }, [dispatch, filtersType]);
  
  return null;
}

export const OneProjectMainViewHandler = ({ match, location }) => {
  const dispatch = useDispatch()
  const pid = getMatchParameterInteger(match, 'pid')
  const projectMode = getProjectModeFromPathname(location.pathname)
  const filtersType = getFiltersTypeByMode(projectMode)
  const project = useSelector(state => stateHelper.getProject(state, pid))
  const projectItems = useSelector(state => stateHelper.getItemsInProject(state, project))
  const items = useApplyFiltersToItems(projectItems, filtersType)
  const sortFnSync = useItemSortDescriptor(filtersType)
  const sortFn = useDeferredValue(sortFnSync)
  const readOnly = isReadOnly(location)
  const matrixPreviewMode = checkEmbeddedInProjectCreationModal() && readOnly
  const token = readOnly ? queryParamsHelper.getFastProject() : void 0
  const { error, isLoading: loading } = useQuery({
    queryKey: ['oneProjectHandler', pid, token],
    queryFn: () => dispatch(init(pid, token)),
    refetchOnWindowFocus: false,
  })
  const isObjectInvalidToBeShown = useIsObjectInvalidToBeShown()
  const selectedItemId = useSelectedItemId()

  if (!loading && isObjectInvalidToBeShown(project, error)) {
    return <InvalidObjectComponent object={project} error={error} readOnly={readOnly} />
  }

  const forwardProps = {
    items,
    project,
    selectedItemId,
    sortFn,
    loading,
    projectSelectorEnabled: false,
    readOnly,
    matrixPreviewMode,
    filtersType,
  }

  switch (projectMode) {
    case PROJECT_MODE.CALENDAR:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <PMCalendarView {...forwardProps} />
        </>
      )
    case PROJECT_MODE.REPORTS:
      forwardProps.items = projectItems
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <PMProjectAnalyticsReport {...forwardProps} />
        </>
      )
    case PROJECT_MODE.FEED:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <Feed {...forwardProps} />
        </>
      )
    case PROJECT_MODE.GANTT:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <Gantt {...forwardProps} />
        </>
      )
    case PROJECT_MODE.KANBAN:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <KanbanView {...forwardProps} />
        </>
      )
    case PROJECT_MODE.LIST:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <MatrixTableView {...forwardProps} />
        </>
      )
    default:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <ProjectMatrixView {...forwardProps} />
        </>
      )
  }
}
