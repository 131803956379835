import { memo, Suspense, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { itemHelper } from '../../common/src/helpers'
import { isInitializedRequestForPMResource } from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { isEmbeddedOnTeams } from '../../helpers/queryParamsHelper'
import { BasicItemList } from '../../components/item/basicList/BasicItemList'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { useTranslation } from 'react-i18next'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { useAttentionNeededQuery } from '@/queries/items'
import { Button, Dropdown, Option } from '@fluentui/react-components'
import { getRelativeURLKeepingQuerySearch } from '@/helpers/routeHelper'
import { useHistory } from 'react-router'
import { useNotificationCounts } from '../../hooks/useNotificationCounts'
import { Filter } from '../../components/BundledIcons'

const FILTER_OPTIONS = (t) => [
  { key: 'all', text: t('alerts.all_alerts', 'All Alerts') },
  { key: 'mention', text: t('alerts.mentions', 'Mentions') },
  { key: 'delegation', text: t('alerts.assigned_to_you', 'Assignments') },
  { key: 'invitation', text: t('alerts.invited', 'Invitations') },
  { key: 'overdue', text: t('alerts.overdue_reminders', 'Overdue/Reminders') },
  { key: 'other', text: t('alerts.others', 'Others') }
]

const HomeAlerts_ = ({ onSelectItem, items, setForceAlertId, filterType, onFilterChange }) => {
  useAttentionNeededQuery()
  const { t } = useTranslation()
  const loading = useSelector(state => !isInitializedRequestForPMResource(state, PM_API_RESOURCES.ATTENTION_NEEDED))
  const selectedItemId = useSelectedItemId()
  const history = useHistory()
  const notificationCounts = useNotificationCounts()
  const [selectedFilter, setSelectedFilter] = useState(filterType || 'all')
  
  // State to track if we should use dropdown or buttons
  const [useDropdown, setUseDropdown] = useState(false)
  const buttonsContainerRef = useRef(null)
  const hiddenButtonsContainerRef = useRef(null) // Ref for hidden buttons container
  
  // Create a debounced version of setUseDropdown to prevent rapid toggling
  // Using useRef to store the timeout ID
  const timeoutRef = useRef(null);
  
  const debouncedSetUseDropdown = useCallback(value => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      setUseDropdown(value);
      timeoutRef.current = null;
    }, 100);
  }, []);
  
  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  
  // Define filterOptions here before using it
  const filterOptions = useMemo(() => FILTER_OPTIONS(t).map(option => ({
    ...option,
    count: option.key === 'all' 
      ? notificationCounts.total 
      : option.key === 'delegation'
        ? notificationCounts.delegation
        : option.key === 'invitation'
          ? notificationCounts.invitation
          : option.key === 'mention'
            ? notificationCounts.mention
            : option.key === 'overdue'
              ? notificationCounts.overdue || 0
              : notificationCounts.other
  })), [notificationCounts, t])
  
  // Determine which filters to display (all filters with count > 0)
  const filtersToDisplay = useMemo(() => 
    filterOptions.filter(option => option.count > 0)
  , [filterOptions])

  // Fixed layout detection with direct approach
  useEffect(() => {
    // Use the visible or hidden container, whichever is available
    const containerRef = useDropdown ? hiddenButtonsContainerRef : buttonsContainerRef;
    if (!containerRef.current) return;
    
    const checkLayout = () => {
      try {
        // Get the filter container element
        const containerEl = containerRef.current;
        if (!containerEl) return;
        
        // Get all buttons
        const buttons = Array.from(containerEl.querySelectorAll('button'));
        if (!buttons.length) return;
        
        // Get the container width directly
        const containerWidthVal = containerEl.offsetWidth;
        
        // Calculate total width needed for buttons
        let totalButtonWidthVal = 0;
        const GAP_WIDTH = 8; // gap between buttons
        
        buttons.forEach((button, i) => {
          totalButtonWidthVal += button.offsetWidth;
          if (i < buttons.length - 1) totalButtonWidthVal += GAP_WIDTH;
        });
        
        // Check if any buttons have different y-position (direct wrap detection)
        const firstButtonTop = buttons[0].offsetTop;
        let hasWrapping = false;
        
        for (let i = 1; i < buttons.length; i++) {
          if (Math.abs(buttons[i].offsetTop - firstButtonTop) > 1) {
            hasWrapping = true;
            break;
          }
        }
        
        // Add hysteresis to prevent oscillation at the threshold
        // Apply a small buffer of 10px to avoid flipping between states
        const HYSTERESIS_BUFFER = 10; // pixels
        
        // If we're currently showing buttons, be a bit more permissive about keeping them
        // If we're showing dropdown, require a bit more space before switching to buttons
        let shouldUseDropdown;
        if (useDropdown) {
          // When in dropdown mode, require MORE space to switch to buttons
          shouldUseDropdown = hasWrapping || (totalButtonWidthVal > containerWidthVal - HYSTERESIS_BUFFER);
        } else {
          // When in button mode, allow LESS space before switching to dropdown
          shouldUseDropdown = hasWrapping || (totalButtonWidthVal > containerWidthVal + HYSTERESIS_BUFFER);
        }
        
        // Update state only if needed, using the debounced setter
        if (shouldUseDropdown !== useDropdown) {
          // Call the debounced function which will set the state after a delay
          debouncedSetUseDropdown(shouldUseDropdown);
        }
      } catch (err) {
        // Silent fail to prevent UI disruption
      }
    };
    
    // For mobile/small screens, start with dropdown by default and use a single check
    const isMobileViewport = window.innerWidth < 640;
    if (isMobileViewport) {
      // On mobile, just use dropdown immediately and don't bother with multiple checks
      setUseDropdown(true);
    } else {
      // On larger screens, use the existing behavior with multiple checks
      // Using fewer, more strategic timeouts to reduce fluctuation chances
      const timeoutIds = [0, 100, 500].map(delay => 
        setTimeout(checkLayout, delay)
      );
      
      // Store timeoutIds for cleanup
      return () => {
        timeoutIds.forEach(id => clearTimeout(id));
      };
    }
    
    // Also check on resize
    const debouncedCheck = debounce(checkLayout, 100);
    window.addEventListener('resize', debouncedCheck);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [filterOptions, useDropdown, debouncedSetUseDropdown]);
  
  // Listen for filter options changes
  useEffect(() => {
    // Check if we're on a mobile viewport
    const isMobileViewport = window.innerWidth < 640;
    
    if (isMobileViewport) {
      // On mobile, just stay in dropdown mode
      setUseDropdown(true);
    } else {
      // On larger screens, force layout detection to rerun
      // Use direct setter, not debounced, for immediate response
      setUseDropdown(false); // Start with buttons and let detection decide
    }
  }, [filterOptions]);

  // Keep dropdown in sync with parent's filter
  useEffect(() => {
    if (filterType && filterType !== selectedFilter) {
      setSelectedFilter(filterType);
    }
  }, [filterType, selectedFilter]);

  const placeholders = useTranslatedPlaceholders('alerts.placeholder_', 7)
  const validPlaceholders = useMemo(() => {
    const allPlaceholders = placeholders.map((p, i) => (i === 6 ? { ...p, type: 3 } : p))
    return !isEmbeddedOnTeams() ? allPlaceholders.slice(0, -1) : allPlaceholders
  }, [placeholders])
  const placeholder = useRandomValueFromArray(validPlaceholders)

  const customOnSelectItem = useCallback(
    item => {
      const id = itemHelper.getId(item)
      setForceAlertId(id)
      
      // Use route helper for consistent URL generation
      const url = selectedFilter && selectedFilter !== 'all'
        ? getRelativeURLKeepingQuerySearch.homeFilter('alerts', selectedFilter, id)
        : getRelativeURLKeepingQuerySearch.homeFilter('alerts', null, id)
      
      history.push(url)
      
      onSelectItem(item)
    },
    [setForceAlertId, onSelectItem, history, selectedFilter]
  )
  
  // Helper to get the current filter's text and count
  const getFilterDisplayText = useCallback(() => {
    const option = filterOptions.find(opt => opt.key === selectedFilter);
    return option ? `${option.text} (${option.count})` : 'Select a filter';
  }, [filterOptions, selectedFilter])
  
  // Enhanced filter selection with consistent URL generation
  const selectFilter = useCallback((newFilter) => {
    // Update local state
    setSelectedFilter(newFilter);
    
    // Update parent state
    onFilterChange(newFilter);
    
    // Update URL, preserving item selection if applicable
    const currentItemId = selectedItemId;
    
    // Make sure we're using a URL-safe filter value
    const url = currentItemId
      ? getRelativeURLKeepingQuerySearch.homeFilter('alerts', newFilter === 'all' ? null : newFilter, currentItemId)
      : getRelativeURLKeepingQuerySearch.homeFilter('alerts', newFilter === 'all' ? null : newFilter, null);
    
    history.push(url);
  }, [onFilterChange, history, selectedItemId]);
  
  // Define handlers for button clicks and option selection
  const handleFilterClick = useCallback(
    (filterKey) => () => selectFilter(filterKey),
    [selectFilter]
  )
  
  const handleOptionSelect = useCallback(
    (e, data) => selectFilter(data.optionValue),
    [selectFilter]
  )
  
  const clearFilter = useCallback(() => {
    selectFilter('all');
  }, [selectFilter]);
  
  // No items to show in the current filter
  const noFilteredItems = !loading && items.length === 0 && filterType !== 'all'
  const shouldShowPlaceholder = !loading && items.length === 0 && filterType === 'all'

  if (shouldShowPlaceholder) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }
  
  // filtersToDisplay is now defined above as a useMemo
  
  return (
    <div className="flex flex-col h-full">
      {/* Hidden container used for measurements when in dropdown mode */}
      {useDropdown && (
        <div 
          ref={hiddenButtonsContainerRef}
          className="absolute opacity-0 pointer-events-none overflow-hidden"
          style={{ visibility: 'hidden', position: 'absolute', zIndex: -1000 }}
        >
          <div className="flex flex-wrap gap-2 w-full">
            {filtersToDisplay.map(option => (
              <Button
                key={option.key}
                appearance={selectedFilter === option.key ? "primary" : "outline"}
                className="compact-filter-button"
              >
                {option.text} ({option.count})
              </Button>
            ))}
          </div>
        </div>
      )}
      
      {/* Filter dropdown */}
      <div className="flex items-center pb-3 pl-3 pt-2">
        <div className="flex items-center mr-4 whitespace-nowrap">
          <Filter className="mr-2" />
          <span>{t('alerts.filter', 'Filter:')}</span>
        </div>
        
        <div className="flex-1 min-w-0">
        
        {useDropdown ? (
          <div className="min-w-[200px]">
            <Dropdown 
              placeholder="Select a filter"
              value={getFilterDisplayText()}
              selectedOptions={[selectedFilter]}
              onOptionSelect={handleOptionSelect}
              appearance="outline"
              className="w-full compact-filter-button"
            >
              {filtersToDisplay.map(option => (
                <Option
                  key={option.key}
                  value={option.key}
                  text={`${option.text} (${option.count})`}
                >
                  {option.text} ({option.count})
                </Option>
              ))}
            </Dropdown>
          </div>
        ) : (
          <div 
            className="flex flex-wrap gap-2"
            ref={buttonsContainerRef}
          >
            {filtersToDisplay.map(option => (
              <Button
              key={option.key}
              appearance={selectedFilter === option.key ? "primary" : "outline"}
              onClick={handleFilterClick(option.key)}
              className="compact-filter-button"
              >
                {option.text} ({option.count})
              </Button>
            ))}
          </div>
        )}
        </div>
      </div>
      
      {noFilteredItems ? (
        <div className="flex flex-col items-center justify-center h-full">
          <p>No {filterOptions.find(opt => opt.key === filterType)?.text.toLowerCase() || 'items'} found</p>
          <Button
            onClick={clearFilter}
            appearance="primary"
            className="compact-filter-button"
          >
            Show All Alerts
          </Button>
        </div>
      ) : (
        <BasicItemList
          items={items}
          selectedItemId={selectedItemId}
          onClickItem={customOnSelectItem}
          loading={loading}
          shouldShowProject
        />
      )}
    </div>
  )
}

export const HomeAlerts = memo(HomeAlerts_)
