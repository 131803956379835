import { useCommentsInItem, useSendCommentStatus } from '../../queries/comments'
import { Chat as FluentChat } from '@fluentui-contrib/react-chat'
import { Avatar, Button, MessageBar, MessageBarActions, MessageBarBody, Spinner } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import React from 'react'
import { motion } from 'framer-motion'
import { PMChatMessage } from './PMChatMessage'
import type { CommentWithReadReceipts, ReadReceipt } from '@/types/comment'
import { ArrowDownRegular } from '@fluentui/react-icons'
import { useMe } from '../../common/src/hooks/usersHooks'
import { resourceURIParser } from '../../common/src/helpers/URLHelper'
import { userHelper } from '../../common/src/helpers'
import { captureLinkClick } from '../../utils/externalLinkHandler'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useChatWebsocket } from './useChatWebsocket'
import { cn } from '@appfluence/classnames'
import { LookbackLimitBanner } from './LookbackLimitBanner'
import { useDispatch } from 'react-redux'
import { localMarkItemsAsReadFromIDs } from '../../common/src/actions/itemActions'
import { useTranslation } from 'react-i18next'
import { type MutationStatus, useQueryClient } from '@tanstack/react-query'
import type { Follower } from '../../types/item'
import type { List, Map } from 'immutable'
import classes from './Chat.module.css'
import { flushSync } from 'react-dom'

export type ChatProps = {
  itemId: number
  isVisible?: boolean
  className?: string
  startEditingComment: (commentId: number, startText: string) => void
  optimisticEditCommentId?: number
  optimisticEditCommentText?: string
  optimisticEditCommentStatus: MutationStatus
  itemFollowers?: List<Map<keyof Follower, string>>
  replyToComment: (commentId: number) => void
}

const Chat_ = ({
  itemId,
  isVisible = true,
  className = '',
  startEditingComment,
  optimisticEditCommentId,
  optimisticEditCommentText,
  optimisticEditCommentStatus,
  itemFollowers,
  replyToComment,
}: ChatProps) => {
  const { t } = useTranslation()

  useChatWebsocket(itemId, { isVisible })
  const sendCommentStatus = useSendCommentStatus(itemId)

  const me = useMe()
  const dispatch = useDispatch()

  const historyLimit = useMemo(() => {
    if (userHelper.hasUnlimitedHistory(me) || userHelper.getSearchLookbackInDays(me) === 0) {
      return false
    }
    const limit = new Date()
    limit.setDate(limit.getDate() - userHelper.getSearchLookbackInDays(me))
    return limit
  }, [me])

  const chatRef = useRef<HTMLDivElement>(null)
  // To restore scroll position after downloading new page of comments:
  const previousScrollHeight = useRef<number>(0)

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isPending, isError, refetch } = useCommentsInItem(
    itemId,
    {
      enabled: isVisible,
    }
  )
  const queryClient = useQueryClient()

  const [commentKeyForAnimation, setCommentKeyForAnimation] = useState(0)
  const [goToCommentId, setGoToCommentId] = useState<number | null>(null)
  const commentsRef = useRef<{ [commentId: number]: HTMLDivElement }>({})
  const goToComment = useCallback(
    async (commentId: number) => {
      const checkCommentIsAlreadyStored = async (): Promise<boolean> => {
        const queryData = queryClient.getQueryData(['comments', itemId]) as typeof data
        if (!queryData) return false
        if (queryData?.pages.flatMap(page => page.objects).some(comment => comment.id === commentId)) {
          return true
        }
        if (queryData.pages[queryData.pages.length - 1].meta?.next) {
          await fetchNextPage()
          return checkCommentIsAlreadyStored()
        }
        return false
      }
      if (!(await checkCommentIsAlreadyStored())) {
        return
      }
      // Use flushSync to ensure that the new comment is rendered before scrolling to it
      flushSync(() => {
        setCommentKeyForAnimation(key => key + 1)
        setGoToCommentId(commentId)
      })
      const commentElement = commentsRef.current[commentId]
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    },
    [fetchNextPage, itemId, queryClient]
  )

  const commentsFromNewer = useMemo(() => data?.pages.flatMap(page => page.objects), [data?.pages])

  // Compute read receipts for each comment
  const commentsWithReadReceipts = useMemo(() => {
    if (!commentsFromNewer) return undefined
    const res: CommentWithReadReceipts[] = []
    // Read receipts for each comment
    const readReceipts: { [receiver: string]: ReadReceipt } = {}
    // Read receipts to be added as a separate comment
    let readReceiptsComment: { [receiver: string]: ReadReceipt } = {}
    for (const comment of commentsFromNewer) {
      if (comment.is_read && resourceURIParser(comment.author)?.id !== userHelper.getID(me)) {
        const commentAuthorId = resourceURIParser(comment.author)?.id
        if (commentAuthorId) {
          const readReceipt = {
            timestamp: comment.timestamp,
            receiverURI: comment.author,
            receiverFullname: comment.author_fullname,
            receiverAvatar: comment.author_avatar,
          }
          readReceipts[commentAuthorId] = readReceipt
          const readReceiptsCommentWasEmpty = Object.keys(readReceiptsComment).length === 0
          readReceiptsComment[commentAuthorId] = readReceipt
          if (readReceiptsCommentWasEmpty) {
            res.unshift({
              readReceipts: { ...readReceiptsComment },
              id: comment.id,
              timestamp: comment.timestamp,
              type: 'readReceipts',
            })
          } else {
            res[0] = { ...res[0], readReceipts: { ...readReceiptsComment } }
          }
        }
      } else if (!comment.is_read) {
        readReceiptsComment = {}
        res.unshift({ ...comment, readReceipts: { ...readReceipts }, type: 'comment' })
      }
    }
    return res
  }, [commentsFromNewer, me])

  // Filter out comments older than historyLimit
  const filteredComments = useMemo(() => {
    if (!historyLimit) {
      return commentsWithReadReceipts
    }
    return commentsWithReadReceipts?.filter(comment => {
      return new Date(`${comment.timestamp}Z`) >= historyLimit
    })
  }, [commentsWithReadReceipts, historyLimit])

  const showBanner =
    !!commentsWithReadReceipts &&
    commentsWithReadReceipts.length > 0 &&
    historyLimit &&
    new Date(`${commentsWithReadReceipts[0].timestamp}Z`) < historyLimit

  const [scrolledToBottom, setScrolledToBottom] = useState(true)

  useEffect(() => {
    if (isVisible && data) {
      dispatch(localMarkItemsAsReadFromIDs([itemId]))
    }
  }, [data, dispatch, itemId, isVisible])

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
      setScrolledToBottom(true)
    }
  }, [itemId])

  useEffect(() => {
    if (data && chatRef.current && scrolledToBottom) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
    }
  }, [scrolledToBottom, data, sendCommentStatus])

  useLayoutEffect(() => {
    if (!isFetchingNextPage && chatRef.current && previousScrollHeight.current) {
      // Restore scroll position after new messages have been added above the current messages
      chatRef.current.scrollTop = chatRef.current.scrollHeight - previousScrollHeight.current
      previousScrollHeight.current = 0
    }
  }, [isFetchingNextPage])

  const handleScroll: React.UIEventHandler<HTMLDivElement> = async e => {
    // Disable autoscroll when new messages arrive if new scroll position is not at the bottom
    if (chatRef.current) {
      setScrolledToBottom(chatRef.current.scrollHeight - chatRef.current.scrollTop - chatRef.current.clientHeight < 5)
    }

    // If user has scrolled to top and "hasNextPage" is true, fetch next page
    if (chatRef.current && chatRef.current.scrollTop === 0 && hasNextPage && !isFetchingNextPage) {
      // If we are already showing the Old Comments banner, don't fetch more
      if (showBanner) {
        return
      }
      // Save scroll position before new messages are added above the current messages
      previousScrollHeight.current = chatRef.current.scrollHeight
      await fetchNextPage()
    }
  }

  return (
    <div className={cn('relative overflow-hidden', className)}>
      <FluentChat
        ref={chatRef}
        className="box-border !block !h-full !min-h-0 !w-full !overflow-auto !pb-4 !pl-0"
        id="chat"
        onScroll={handleScroll}
        // @ts-expect-error
        onClick={captureLinkClick(urlData => {
          // @ts-expect-error
          linkSubject.next({ urlData, source: SOURCES.CHAT })
        })}
      >
        {isPending && <Spinner size="large" className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: isFetchingNextPage ? 1 : 0, y: isFetchingNextPage ? 0 : -50 }}
          className="absolute left-1/2 top-4 z-50 -translate-x-1/2"
        >
          <Spinner />
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 1 }}
          animate={{ y: scrolledToBottom ? 50 : 0, opacity: 1 }}
          className="absolute bottom-4 right-4 z-50"
        >
          <Button
            onClick={() => {
              if (chatRef.current) {
                chatRef.current.scrollTo({ top: chatRef.current.scrollHeight, behavior: 'smooth' })
              }
            }}
            icon={<ArrowDownRegular />}
          />
        </motion.div>
        {showBanner && <LookbackLimitBanner />}
        {filteredComments?.map((comment, index) => {
          if (comment.type === 'comment') {
            return (
              <PMChatMessage
                ref={el => {
                  if (el) {
                    commentsRef.current[comment.id] = el
                  }
                }}
                mine={resourceURIParser(comment.author)?.id === userHelper.getID(me)}
                key={goToCommentId === comment.id ? `${comment.id}${commentKeyForAnimation}` : comment.id}
                className={goToCommentId === comment.id ? classes.HighlightMessage : ''}
                comment={comment}
                previousComment={
                  index > 0
                    ? ((filteredComments[index - 1]?.type === 'comment'
                        ? filteredComments[index - 1]
                        : filteredComments[index - 2]) as CommentWithReadReceipts & { type: 'comment' })
                    : undefined
                }
                startEditing={startEditingComment}
                optimisticEditCommentText={
                  comment.id === optimisticEditCommentId ? optimisticEditCommentText : undefined
                }
                optimisticEditCommentStatus={
                  comment.id === optimisticEditCommentId ? optimisticEditCommentStatus : undefined
                }
                itemFollowers={itemFollowers}
                replyToComment={replyToComment}
                goToComment={goToComment}
              />
            )
          }
          const previousComment = filteredComments[index - 1] as
            | (CommentWithReadReceipts & {
                type: 'comment'
              })
            | undefined
          return (
            <div
              className="mt-2 box-border flex w-full justify-end gap-1 pr-2 opacity-60 transition-opacity hover:opacity-100"
              key={comment.id}
            >
              {Object.keys(comment.readReceipts).map(receiverId => {
                if (
                  previousComment &&
                  !previousComment.is_automatic &&
                  +receiverId === resourceURIParser(previousComment.author)?.id
                ) {
                  return false
                }
                const receipt = comment.readReceipts[receiverId]
                return (
                  <Tooltip
                    key={receiverId}
                    content={t('item_chat.seen_by_at', {
                      name: comment.readReceipts[receiverId].receiverFullname,
                      date: new Date(`${receipt.timestamp}Z`).toLocaleString(),
                    })}
                    relationship="label"
                  >
                    <Avatar size={16} name={receipt.receiverFullname} image={{ src: receipt.receiverAvatar }} />
                  </Tooltip>
                )
              })}
            </div>
          )
        })}
        {sendCommentStatus
          .filter(status => status.status !== 'success')
          .map((status, index) => {
            const replyToCommentId = status.variables?.replyToCommentId
            const repliedToComment = replyToCommentId
              ? data?.pages
                  .flatMap(page => page.objects)
                  .flat()
                  .find(comment => comment.id === replyToCommentId)
              : null
            return (
              <PMChatMessage
                key={`sending-${index}`}
                mine
                comment={{
                  text: status.variables!.commentText,
                  timestamp: new Date(status.submittedAt).toISOString().slice(0, -1),
                  author: `/api/v1/user/${userHelper.getID(me)}/`,
                  author_email: userHelper.getEmail(me),
                  json: repliedToComment
                    ? {
                        reply_to_comment: {
                          id: repliedToComment.id,
                          original_text: repliedToComment.original_text,
                          author_email: repliedToComment.author_email,
                          author_fullname: repliedToComment.author_fullname,
                        },
                      }
                    : undefined,
                }}
                previousComment={
                  (filteredComments?.[filteredComments.length - 1]?.type === 'comment'
                    ? filteredComments?.[filteredComments.length - 1]
                    : filteredComments?.[filteredComments.length - 2]) as CommentWithReadReceipts & { type: 'comment' }
                }
                sendStatus={status.status === 'pending' ? 'sending' : status.status === 'error' ? 'failed' : undefined}
              />
            )
          })}
        {isError && (
          <MessageBar className="mx-4 mt-2" intent="error">
            <MessageBarBody>{t('item_chat.error_loading')}</MessageBarBody>
            <MessageBarActions>
              <Button onClick={() => refetch()}>{t('general.retry')}</Button>
            </MessageBarActions>
          </MessageBar>
        )}
      </FluentChat>
    </div>
  )
}

export const Chat = memo(Chat_)
