import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RIGHT_PANEL, setItemsSelectedInMultiSelection, setMultipleSelectionOn } from '../actions/uiActions'
import * as initializationActions from '../actions/viewInitializationActions'
import { useParams } from 'react-router'
import { graphStateHelper, itemHelper, stateHelper } from '../common/src/helpers'
import { getMultipleSelectedItems } from '@/selectors/uiSelectors.js'

// A conditional fetch is the one that may return a promise or undefined
export const useConditionalFetchOnLoad = fetchFn => {
  const [loading, setLoading] = useState(false)
  const effectFn = useCallback(async () => {
    const possiblePromise = fetchFn()
    if (possiblePromise) {
      setLoading(true)
      await possiblePromise
      setLoading(false)
    }
  }, [fetchFn])
  useEffect(() => {
    effectFn()
  }, [effectFn])
  return loading
}

export const useInitializeMsCollaborators = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initializationActions.msCollaborators())
  }, [dispatch])
}

export const useSelectedItemId = () => {
  const params = useParams()
  
  // Special case for direct digit IDs in home section URLs like /app/home/my_items/123
  const pathname = window.location.pathname
  if (pathname.includes('/app/home/') && !params.id && params.filter && !isNaN(parseInt(params.filter, 10))) {
    return +params.filter // Use the filter parameter as the item ID for backwards compatibility
  }
  
  return +params.id
}

export const useSelectedItem = () => {
  const selectedItemId = useSelectedItemId()
  return useSelector(state => stateHelper.getItem(state, selectedItemId))
}

export const useSelectedGraphItemId = () => {
  const params = useParams()
  const itemID = params.id
  return itemID?.startsWith('graph-') ? itemID.replace('graph-', '') : null
}

export const useSelectedGraphItem = () => {
  const graphItemId = useSelectedGraphItemId()
  return useSelector(state => graphStateHelper.getById(state, graphItemId))
}

export const useSelectedProjectId = () => {
  const params = useParams()
  return +params.pid
}

export const useSelectedProject = () => {
  const selectedProjectId = useSelectedProjectId()
  return useSelector(state => stateHelper.getProject(state, selectedProjectId))
}

const getRightPanel = (id, pid) => {
  if (id) {
    return id.startsWith('graph-') ? RIGHT_PANEL.GRAPH_RESOURCE : RIGHT_PANEL.ITEM
  }
  if (pid) {
    return RIGHT_PANEL.PROJECT
  }
  return RIGHT_PANEL.ITEM
}

export const useRightPanel = () => {
  const params = useParams()
  return getRightPanel(params.id, params.pid)
}

export const useSelection = () => {
  const itemID = useSelectedItemId()
  const graphResourceID = useSelectedGraphItemId()
  return useMemo(() => ({ itemID, graphResourceID }), [itemID, graphResourceID])
}

export const useItemClick = ({ allItems, onClickItem, item }) => {
  const dispatch = useDispatch()
  const multipleSelectedItemIds = useSelector(getMultipleSelectedItems)
  const selectedItemId = useSelectedItemId()

  return useCallback(
    (event, eventItem) => {
      event.stopPropagation()
      const id = itemHelper.getId(eventItem ?? item)
      if (event.shiftKey) {
        event.preventDefault()
        dispatch(setMultipleSelectionOn(true))
        let newItemsSelected = multipleSelectedItemIds
        if (newItemsSelected.size === 0 && selectedItemId) {
          newItemsSelected = newItemsSelected.add(selectedItemId)
        }
        const selectedIndexes = allItems
          .map((item, idx) => (newItemsSelected.has(itemHelper.getId(item)) ? idx : null))
          .filter(index => index !== null)
        const currentIndex = allItems.findIndex(item => itemHelper.getId(item) === id)
        if (currentIndex === -1) {
          return
        }
        const minSelectedIndex = Math.min(...selectedIndexes)
        const maxSelectedIndex = Math.max(...selectedIndexes)
        const indexesToSelect = []

        if (!selectedIndexes.length) {
          indexesToSelect.push(currentIndex)
        } else if (currentIndex < minSelectedIndex) {
          for (let i = currentIndex; i < minSelectedIndex; i++) {
            indexesToSelect.push(i)
          }
        } else if (currentIndex > maxSelectedIndex) {
          for (let i = maxSelectedIndex + 1; i <= currentIndex; i++) {
            indexesToSelect.push(i)
          }
        } else {
          indexesToSelect.push(currentIndex)
        }

        newItemsSelected = newItemsSelected.withMutations(mutator => {
          indexesToSelect.forEach(index => {
            const item = allItems[index]
            mutator.add(itemHelper.getId(item))
          })
        })
        dispatch(setItemsSelectedInMultiSelection(newItemsSelected))

        return
      }
      if (event.metaKey || event.ctrlKey) {
        event.preventDefault()
        dispatch(setMultipleSelectionOn(true))
        dispatch(
          setItemsSelectedInMultiSelection(
            multipleSelectedItemIds.has(id) ? multipleSelectedItemIds.delete(id) : multipleSelectedItemIds.add(id)
          )
        )
        return
      }
      onClickItem?.(event, eventItem ?? item)
    },
    [allItems, dispatch, item, multipleSelectedItemIds, onClickItem, selectedItemId]
  )
}
