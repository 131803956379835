import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { useSideEffectAfterMount } from '../../hooks/hooks'
import { getSearchItem } from '../../common/src/actions/searchAPI'
import { getResourcesByText } from '../../common/src/actions/graphAPI'
import { graphStateHelper } from '../../common/src/helpers'
import {
  useExtendQueryParametersToCheckBannerInfo,
  useExtendQueryParametersWithSearchLoopback,
} from '../../common/src/hooks/searchHooks'
import { useTranslation } from 'react-i18next'

export const SearchAPITextField = ({
  onChange: onChangeDelegate = _.identity,
  onLoading = _.identity,
  onChangeShouldDisplayBanner = _.identity,
  useGraph = false,
  autoFocus = false,
  searchBoxID,
  searchBoxStyles,
  ...rest
}) => {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const isGraphEnabled = useSelector(state => graphStateHelper.isGraphEnabled(state))
  const { t } = useTranslation()

  const params = useExtendQueryParametersWithSearchLoopback()
  const bannerParams = useExtendQueryParametersToCheckBannerInfo()

  //autoFocus effect
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        try {
          ref.current?.focus()
        } catch (err) {
          console.log(err)
        }
      }, 1000)
    }
  }, [autoFocus])

  const search = useCallback(
    async text => {
      if (!text) {
        setLoading(false)
        return
      }

      if (text) {
        setLoading(true)
        const promise = dispatch(
          getSearchItem({
            ...params,
            q: text,
          })
        )
        const promises = [promise]
        if (useGraph && isGraphEnabled) {
          promises.push(dispatch(getResourcesByText(text)))
        }
        try {
          await Promise.all(promises)
          const bannerInfo = dispatch(
            getSearchItem({
              ...bannerParams,
              q: text,
            })
          )
          const hasNext = !!_.get(bannerInfo, 'payload.meta.next')
          onChangeShouldDisplayBanner(hasNext)
        } finally {
          setLoading(false)
        }
      }
    },
    [dispatch, params, useGraph, isGraphEnabled, bannerParams, onChangeShouldDisplayBanner]
  )
  const debouncedSearch = useMemo(() => _.debounce(search, 250, { trailing: true }), [search])

  useSideEffectAfterMount(_.partial(onLoading, loading), [loading])
  const onChangeText = (evt, text) => {
    setText(text)
    debouncedSearch(text)
    onChangeDelegate(text)
  }
  const onSearch = () => {
    debouncedSearch(text)
  }
  return (
    <SearchBox
      id={searchBoxID}
      defaultValue={text}
      placeholder={t('search.top_bar_search_placeholder')}
      onChange={onChangeText}
      onSearch={onSearch}
      styles={searchBoxStyles}
      componentRef={ref}
      {...rest}
    />
  )
}

SearchAPITextField.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  onLoading: PropTypes.func,
}
