import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { HomeAgenda } from './HomeAgenda'
import { HomeAlerts } from './HomeAlerts'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { linkSubject } from '../../reactions/linkSubject'
import { itemHelper } from '../../common/src/helpers'
import { HomeHeader } from './HomeHeader'
import { HomeRecents } from './HomeRecents'
import {
  clearAllAttentionNeededItems,
  clearCompletedAttentionNeededItems,
  clearOneDayAlerts,
  clearSevenDaysAlerts,
} from '../../common/src/actions/itemActions'
import { useDispatch, useSelector } from 'react-redux'
import { DROPDOWN_ICONS } from './HomeConstants'
import {
  clearItemMultipleSelection,
  setHomeAgendaMode,
  setHomeMyItemsMode,
  setMultipleSelectionOn,
} from '../../actions/uiActions'
import { SECTIONS } from './HomeConstants'
import { useAttentionNeeded } from '../../hooks/useAttentionNeeded'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { navigationPaths } from '../../utils/NavPanelCommon'
import { useSearchKeyPress } from '../../hooks/useSearchKeyPress'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { HomeMyItems } from './HomeMyItems'
import { AddItemButtonWithTooltip } from '../../components/buttons/AddItemButtonWithTooltip'
import { useReactToPrint } from 'react-to-print'
import { PrintableComponent } from '../../components/PrintableComponent'
import { getHomeAgendaMode, getHomeMyItemsMode } from '../../selectors/uiSelectors'
import { HOME_DROPDOWN_OPTIONS } from '../../actions/uiActions'
import { TopBarMultiSelectButton } from '../../components/buttons/TopBarMultiSelectButton'
// Removed unused import: useAlertItems
import { useRecentItems } from '../../hooks/itemHooks'
import { useAgendaItems } from '@/queries/agenda'
import { useMyItems } from './useMyItems'
import { ChevronDownRegular } from '@fluentui/react-icons'
import {
  Button,
  makeStyles,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Overflow,
  OverflowItem,
  Spinner,
  Tab,
  TabList,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { OverflowMenu } from '../../components/overflow/OverflowMenu'
import {
  Add,
  Alert,
  CalendarAgenda,
  CalendarToday,
  CalendarWorkWeek,
  CheckboxChecked,
  Dismiss,
  History,
  Person,
  Print,
} from '../../components/BundledIcons'
import { PrintButton } from '../../components/buttons/PrintButton'
import { isPrintHotKey, useHotkey } from '../../hooks/useHotkey'
import { ShortcutTooltipContent } from '../../tooltip/ShortcutTooltipHost'
import { META_KEY } from '../../components/shortcutHelp/ShortcutHelp'
import { motion } from 'framer-motion'
import { Subject, first } from 'rxjs'
import { useFilteredAlertItems } from './useFilteredAlertItems'
import { useNotificationCounts } from '../../hooks/useNotificationCounts'

const useSubject = () => {
  const [subject] = useState(new Subject())
  return subject
}

const useFinalSection = section => {
  const subject = useSubject()
  const { count: alertsCount, initialized: alertsInitialized } = useAttentionNeeded()
  const [bestInitialSection, setBestInitialSection] = useState()
  const validSection = _.find(SECTIONS, s => s === section)

  useEffect(() => {
    let alive = true
    const result = subject.pipe(first())
    const subs = result.subscribe(opt => {
      setBestInitialSection(opt)
    })
    setTimeout(() => {
      if (alive) {
        subject.next(SECTIONS.ALERTS)
      }
    }, 1000)

    return () => {
      subs.unsubscribe()
      alive = false
    }
  }, [subject, setBestInitialSection])

  useEffect(() => {
    if (alertsInitialized) {
      subject.next(alertsCount ? SECTIONS.ALERTS : SECTIONS.AGENDA)
    }
  }, [alertsInitialized, alertsCount, subject])

  if (validSection) return validSection
  return bestInitialSection
}

const useSection = ({ history, match }) => {
  const sectionParam = _.get(match, 'params.section')
  const sectionFinal = useFinalSection(sectionParam)
  const dispatch = useDispatch()
  useEffect(() => {
    // Only redirect if section is completely missing, not when we have a valid section with item ID
    // This prevents redirecting away from URLs like /app/home/my_items/item/123
    if (sectionParam !== sectionFinal && !match.params.filter) {
      history.replace(getRelativeURLKeepingQuerySearch.homeSection(sectionFinal))
    }
  }, [sectionParam, history, sectionFinal, match.params.filter])

  //tracking
  useEffect(() => {
    if (sectionFinal) {
      dispatch(
        dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_HOME, {
          section: sectionFinal,
        })
      )
    }
  }, [sectionFinal, dispatch])
  return sectionFinal
}

const PivotItemContent = props => (
  <div
    className="relative box-border flex h-full w-full flex-1 flex-col overflow-auto p-2"
    data-is-scrollable="true"
    {...props}
  />
)

const useClasses = makeStyles({
  menuButton: {
    minWidth: '48px',
  },
  chevronIcon: {
    fontSize: '12px',
    marginTop: '2px',
  },
})

// Replace the old agendaOptionMapper with direct use of createAgendaOptionMapper
// const agendaOptionMapper = (setMode, dropdownOptions) => mode => {
//   const Icon = DROPDOWN_ICONS[mode]
//   return (
//     <MenuItemRadio key={mode} name="mode" value={mode} icon={<Icon />} onClick={() => setMode(mode)}>
//       {dropdownOptions[mode]}
//     </MenuItemRadio>
//   )
// }

const MotionButton = motion(Button)

// Helper function to parse URL parameters and determine filter type and item ID
const parseUrlParams = (match) => {
  const section = match.params.section || '';
  const filter = match.params.filter || '';
  const id = match.params.id || '';
  
  // If we're using the explicit item URL structure: /app/home/*/item/123
  if (filter === 'item' && id) {
    return {
      section,
      filterType: 'all',
      itemId: parseInt(id, 10)
    };
  }
  
  // If we're using the filter with explicit item structure: /app/home/alerts/delegation/item/{id}
  // In this case, the actual item ID is in a parameter we don't capture directly here
  if (filter && id === 'item') {
    // We'll need to extract the item ID from a different route parameter
    // For now, return just the filter type
    return {
      section,
      filterType: filter,
      itemId: 0 // We'll set this later when we have the real ID
    };
  }
  
  // Check if filter is actually an item ID (for backwards compatibility)
  const isNumericFilter = filter && !isNaN(parseInt(filter, 10));
  
  if (isNumericFilter) {
    return {
      section,
      filterType: 'all',
      itemId: parseInt(filter, 10)
    };
  }
  
  // Filter is a valid filter type
  const validFilters = ['all', 'delegation', 'invitation', 'mention', 'overdue', 'other'];
  const isValidFilter = filter && validFilters.includes(filter);
  
  if (isValidFilter) {
    const itemId = id && !isNaN(parseInt(id, 10)) ? parseInt(id, 10) : 0;
    return {
      section,
      filterType: filter,
      itemId
    };
  }
  
  // Default case - no filter, no item
  return {
    section,
    filterType: 'all',
    itemId: 0
  };
};

const motionButtonTapAnimation = { scale: 0.9 }

export const HomeMain = ({ match, history }) => {
  const classes = useClasses()
  const reactHistory = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // syncSection only for Pivot header, so that a tab change seems instant
  const syncSection = useSection({ history, match })
  const section = useDeferredValue(syncSection)
  const agendaMode = useSelector(getHomeAgendaMode)
  const myItemsMode = useSelector(getHomeMyItemsMode)
  const setAgendaMode = useCallback(mode => dispatch(setHomeAgendaMode(mode)), [dispatch])
  const setMyItemsMode = useCallback(mode => dispatch(setHomeMyItemsMode(mode)), [dispatch])
  const isMobileVersion = useNarrowWidth()
  const createItemModal = useCreateItemModal()
  const { count: alertCount } = useAttentionNeeded()
  const agendaRef = useRef()
  const [forceAlertId, setForceAlertId] = useState(0)
  
  // Define valid filters with useMemo to prevent re-renders
  const validFilters = useMemo(() => ['all', 'delegation', 'invitation', 'mention', 'overdue', 'other'], [])
  
  // Parse URL parameters to get filter type and item ID
  const urlParams = useMemo(() => parseUrlParams(match), [match])
  
  // Initialize alert filter type from URL
  const [alertFilterType, setAlertFilterType] = useState(urlParams.filterType || 'all')
  const [pendingItemId, setPendingItemId] = useState(urlParams.itemId || 0)
  
  // First effect - handle filter type and set pending item ID
  useEffect(() => {
    const params = parseUrlParams(match);
    
    // Set the alert filter type if needed
    if (params.filterType !== alertFilterType && section === SECTIONS.ALERTS) {
      setAlertFilterType(params.filterType);
    }
    
    // Set the pending item ID if present
    if (params.itemId && params.itemId !== forceAlertId) {
      setForceAlertId(params.itemId);
      // For non-alert sections, mark this ID as pending selection
      if (section !== SECTIONS.ALERTS) {
        setPendingItemId(params.itemId);
      }
    }
  }, [match, alertFilterType, forceAlertId, section])
  
  // Replace the existing alerts with the filtered version
  const notificationCounts = useNotificationCounts()
  const alerts = useFilteredAlertItems(alertFilterType, forceAlertId)
  
  const recentItems = useRecentItems()
  const { items: agendaItems } = useAgendaItems(agendaMode)
  const myItems = useMyItems(myItemsMode)
  const narrow = useNarrowWidth()
  
  // Apply URL parameters for project filters on component mount
  useEffect(() => {
    // Check if URL has project_tags or project_sort parameters
    const params = new URLSearchParams(window.location.search);
    if (params.has('project_tags') || params.has('project_sort')) {
      // Import the function dynamically to avoid circular dependencies
      import('../../hooks/filterHooks')
        .then(module => {
          const { applyProjectFiltersFromUrl } = module;
          
          // Apply immediately
          applyProjectFiltersFromUrl(dispatch);
          
          // Apply again after components are fully mounted
          setTimeout(() => {
            applyProjectFiltersFromUrl(dispatch);
            window.dispatchEvent(new Event('filterchange'));
          }, 1000);
        })
        .catch(err => {
          console.error("Error importing filterHooks:", err);
        });
    }
  }, [dispatch]);
  
  // Second effect - handle item selection when items are available
  useEffect(() => {
    if (pendingItemId <= 0 || section === SECTIONS.ALERTS) return;
    
    // Now the item arrays are available, we can try to find the selected item
    let currentItems = [];
    if (section === SECTIONS.RECENT) currentItems = recentItems;
    else if (section === SECTIONS.AGENDA) currentItems = agendaItems;
    else if (section === SECTIONS.MY_ITEMS) currentItems = myItems;
    
    const selectedItem = currentItems.find(item => itemHelper.getId(item) === pendingItemId);
    
    if (selectedItem) {
      linkSubject.next({
        urlData: itemHelper.getUriData(selectedItem),
        section,
      });
      
      // Clear the pending ID now that we've processed it
      setPendingItemId(0);
    }
  }, [section, pendingItemId, recentItems, agendaItems, myItems, setPendingItemId])

  const items = {
    [SECTIONS.ALERTS]: alerts,
    [SECTIONS.RECENT]: recentItems,
    [SECTIONS.AGENDA]: agendaItems,
    [SECTIONS.MY_ITEMS]: myItems,
  }

  const handlePrintAgenda = useReactToPrint({
    content: () => agendaRef.current,
  })

  useSearchKeyPress(
    useCallback(() => {
      reactHistory.push(navigationPaths.search)
    }, [reactHistory])
  )

  useEffect(() => {
    dispatch(clearItemMultipleSelection())
    dispatch(setMultipleSelectionOn(false))
  }, [section, dispatch])

  const onSelectItem = useCallback(
    item => {
      const id = itemHelper.getId(item);
      
      // Update URL - for non-alerts use direct ID format as that's what the useSelectedItemId hook expects
      if (section) {
        if (section === SECTIONS.ALERTS) {
          // For alerts, use the new /item/ format
          history.push(getRelativeURLKeepingQuerySearch.homeFilter(section, null, id));
        } else {
          // For all other sections, use direct numeric ID for backwards compatibility
          history.push(`/app/home/${section}/${id}/`);
        }
      }
      
      linkSubject.next({
        urlData: itemHelper.getUriData(item),
        section,
      })
    },
    [section, history]
  )

  const onSelectGraphResource = useCallback(
    resource => {
      if (narrow) {
        history.push(getRelativeURLKeepingQuerySearch.graphResourceViewInAppForID(resource.id))
      } else {
        history.replace(getRelativeURLKeepingQuerySearch.homeSection(section, `graph-${resource.id}`))
      }
    },
    [narrow, history, section]
  )

  // Update handleTabSelect to use the route helper for URL generation
  const handleTabSelect = useCallback(
    tabId => {
      if (tabId === SECTIONS.ALERTS) {
        // Use route helper for consistent URL generation
        const url = getRelativeURLKeepingQuerySearch.homeFilter(
          'alerts',
          alertFilterType === 'all' ? null : alertFilterType,
          null
        );
        history.push(url);
      } else {
        history.push(getRelativeURLKeepingQuerySearch.homeSection(tabId));
      }
      
      setForceAlertId(0);
    },
    [history, alertFilterType]
  )
  
  // Handler for alert filter changes with improved URL handling
  const handleAlertFilterChange = useCallback(
    (newFilterType) => {
      // Ensure we always have a valid filter type
      const safeFilterType = (newFilterType && validFilters.includes(newFilterType)) ? newFilterType : 'all';
      
      // Update the filter state
      if (safeFilterType !== alertFilterType) {
        setAlertFilterType(safeFilterType);
      }
      
      // Update URL, preserving item selection if applicable
      const currentItemId = forceAlertId;
      
      // Use route helper for consistent URL generation
      const url = getRelativeURLKeepingQuerySearch.homeFilter(
        'alerts',
        safeFilterType === 'all' ? null : safeFilterType,
        currentItemId || null
      );
      
      history.push(url);
    },
    [validFilters, alertFilterType, history, forceAlertId]
  )

  const tabs = useMemo(
    () => [
      {
        id: SECTIONS.ALERTS,
        name: t('home.alerts') + (alertCount ? ` (${alertCount >= 1000 ? '>999' : alertCount})` : ''),
        icon: <Alert />,
        // Add a badge or indicator if there are delegation notifications
        badge: notificationCounts.delegation > 0 ? notificationCounts.delegation : null,
      },
      {
        id: SECTIONS.MY_ITEMS,
        name: t('home.my_items'),
        icon: <Person />,
      },
      {
        id: SECTIONS.AGENDA,
        name: t('home.agenda'),
        icon: <CalendarAgenda />,
      },
      {
        id: SECTIONS.RECENT,
        name: t('home.recently_visited'),
        icon: <History />,
      },
    ],
    [t, alertCount, notificationCounts.delegation]
  )

  const dropdownOptions = useMemo(() => {
    return {
      [HOME_DROPDOWN_OPTIONS.OWNED]: t('item_filters.is_owned'),
      [HOME_DROPDOWN_OPTIONS.FOLLOWED]: t('item_filters.is_followed'),
      [HOME_DROPDOWN_OPTIONS.EVERYTHING]: t('item_filters.all_items'),
    }
  }, [t])

  // Define handlers as callbacks to avoid arrow functions in JSX
  const handleTabListSelect = useCallback(
    (event, data) => handleTabSelect(data.value),
    [handleTabSelect]
  )
  
  // Create callback handlers for the "Clear" menu items
  const handleClearCompleted = useCallback(
    () => dispatch(clearCompletedAttentionNeededItems()),
    [dispatch]
  )
  
  const handleClearSevenDays = useCallback(
    () => dispatch(clearSevenDaysAlerts()),
    [dispatch]
  )
  
  const handleClearOneDay = useCallback(
    () => dispatch(clearOneDayAlerts()),
    [dispatch]
  )
  
  const handleClearAll = useCallback(
    () => dispatch(clearAllAttentionNeededItems()),
    [dispatch]
  )
  
  // Define a function that returns a mode-setting callback
  const createModeSetterCallback = useCallback(
    (setModeFn, mode) => () => setModeFn(mode),
    []
  )

  // Create a higher-order function that returns mode-setting menu items
  const createAgendaOptionMapper = useCallback(
    (setMode, optionsMap) => {
      return mode => {
        const Icon = DROPDOWN_ICONS[mode]
        const handleModeClick = createModeSetterCallback(setMode, mode)
        return (
          <MenuItemRadio key={mode} name="mode" value={mode} icon={<Icon />} onClick={handleModeClick}>
            {optionsMap[mode]}
          </MenuItemRadio>
        )
      }
    },
    [createModeSetterCallback]
  )

  const optionMenuItems = useMemo(() => {
    switch (section) {
      case SECTIONS.ALERTS:
        return (
          <>
            <MenuItem
              onClick={handleClearCompleted}
              icon={<CheckboxChecked />}
            >
              {t('alerts.clear_finished')}
            </MenuItem>
            <MenuItem
              onClick={handleClearSevenDays}
              icon={<CalendarWorkWeek />}
            >
              {t('alerts.clear_seven_days')}
            </MenuItem>
            <MenuItem
              onClick={handleClearOneDay}
              icon={<CalendarToday />}
            >
              {t('alerts.clear_one_day')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={handleClearAll}
              icon={<Dismiss />}
            >
              {t('alerts.clear_all')}
            </MenuItem>
          </>
        )
      case SECTIONS.AGENDA:
        return (
          <>
            {[HOME_DROPDOWN_OPTIONS.OWNED, HOME_DROPDOWN_OPTIONS.FOLLOWED, HOME_DROPDOWN_OPTIONS.EVERYTHING].map(
              createAgendaOptionMapper(setAgendaMode, dropdownOptions)
            )}
          </>
        )
      case SECTIONS.MY_ITEMS:
        return (
          <>
            {[HOME_DROPDOWN_OPTIONS.OWNED, HOME_DROPDOWN_OPTIONS.FOLLOWED].map(
              createAgendaOptionMapper(setMyItemsMode, dropdownOptions)
            )}
          </>
        )
      default:
        return null
    }
  }, [section, t, setAgendaMode, dropdownOptions, setMyItemsMode,
    createAgendaOptionMapper, handleClearAll, handleClearCompleted, handleClearOneDay, handleClearSevenDays])

  // Define at the top of component with other hooks
  const handleAddItem = useCallback(() => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.HOME_TOP_BAR,
      displayProjectSelector: true,
      quadrant: 0,
      open: false,
      completion: i => {
        linkSubject.next({
          urlData: itemHelper.getUriData(i),
          section,
        })
      },
    })
  }, [createItemModal, section])

  if (!section) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  const OptionMenuIcon =
    section === SECTIONS.AGENDA
      ? DROPDOWN_ICONS[agendaMode]
      : section === SECTIONS.MY_ITEMS
        ? DROPDOWN_ICONS[myItemsMode]
        : Dismiss

  const menuButtonTooltipKey = section === SECTIONS.ALERTS ? 'home.clear_alerts_tooltip' : 'home.owner_filter_tooltip'

  return (
    <div className="box-border flex h-full w-full flex-1 flex-col bg-pm-neutral-lighter px-5 py-3">
      <HomeHeader />
      <div className="relative flex min-h-0 flex-1 flex-col rounded-[5px] bg-pm-white">
        <div className="flex">
          <div className="min-w-0 flex-1">
            <Overflow>
              <TabList selectedValue={syncSection} onTabSelect={handleTabListSelect}>
                {tabs.map(tab => (
                  <OverflowItem key={tab.id} id={tab.id} priority={tab.id === syncSection ? 2 : 1}>
                    <Tab
                      value={tab.id}
                      icon={tab.icon}
                      content={{ children: tab.name, className: 'hidden md:block' }}
                    />
                  </OverflowItem>
                ))}
                <OverflowMenu onTabSelect={handleTabSelect} tabs={tabs} />
              </TabList>
            </Overflow>
          </div>
          <div className="mr-1 flex items-center">
            {!isMobileVersion && (
              <>
                <AddItemButtonWithTooltip
                  className="mx-2"
                  id={'homeTopBar_addItemButton'}
                  key={'AddButton'}
                  text={t('item.create_item')}
                  onClick={handleAddItem}
                />
                {section === SECTIONS.AGENDA && <PrintAgendaButton onPrintAgenda={handlePrintAgenda} />}
                {section !== SECTIONS.AGENDA && <PrintButton items={items[section]} appearance="subtle" />}
              </>
            )}
            <TopBarMultiSelectButton allItems={items[section]} appearance="subtle" />
            {optionMenuItems && (
              <Menu
                checkedValues={{ mode: section === SECTIONS.MY_ITEMS ? myItemsMode : agendaMode }}
                positioning={{ autoSize: true }}
              >
                <Tooltip content={t(menuButtonTooltipKey)} relationship="label">
                  <MenuTrigger>
                    <MenuButton
                      className={classes.menuButton}
                      appearance="subtle"
                      icon={
                        <span className="flex items-center gap-1">
                          <OptionMenuIcon />
                          <ChevronDownRegular className={classes.chevronIcon} />
                        </span>
                      }
                    />
                  </MenuTrigger>
                </Tooltip>
                <MenuPopover>
                  <MenuList>{optionMenuItems}</MenuList>
                </MenuPopover>
              </Menu>
            )}
          </div>
        </div>
        <PivotItemContent>
          {section === SECTIONS.ALERTS && (
            <HomeAlerts
              onSelectItem={onSelectItem}
              setForceAlertId={setForceAlertId}
              items={alerts}
              agendaMode={agendaMode}
              filterType={alertFilterType}
              onFilterChange={handleAlertFilterChange}
            />
          )}
          {section === SECTIONS.RECENT && <HomeRecents onSelectItem={onSelectItem} items={recentItems} />}
          {section === SECTIONS.AGENDA && (
            <PrintableComponent pageName={t('home.agenda')} ref={agendaRef}>
              <HomeAgenda onSelectItem={onSelectItem} mode={agendaMode} onSelectGraphResource={onSelectGraphResource} />
            </PrintableComponent>
          )}
          {section === SECTIONS.MY_ITEMS && (
            <HomeMyItems onSelectItem={onSelectItem} mode={myItemsMode} items={myItems} />
          )}
        </PivotItemContent>
        {isMobileVersion && (
          <div className="absolute bottom-8 right-8 z-50 scale-125">
            <MotionButton
            appearance="primary"
            size="large"
            whileTap={motionButtonTapAnimation}
            icon={<Add />}
            onClick={handleAddItem}
            shape="circular"
            />
          </div>
        )}
      </div>
    </div>
  )
}

const PrintAgendaButton = ({ onPrintAgenda }) => {
  const { t } = useTranslation()
  useHotkey(isPrintHotKey, onPrintAgenda)
  return (
    <Tooltip
      content={<ShortcutTooltipContent label={t('home.print_agenda')} tooltipKeys={[META_KEY, 'P']} />}
      relationship="label"
    >
      <Button icon={<Print />} onClick={onPrintAgenda} appearance="subtle" />
    </Tooltip>
  )
}
