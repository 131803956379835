import React, { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cn } from '@appfluence/classnames'
import icons from '../../constants/icons'
import { IconCell } from './IconCell'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
} from '@fluentui/react-components'

const ICON_SIZE = 32

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const IconSelectorModal = ({ onChange, currentIcon, open, onDismiss }) => {
  const dispatch = useDispatch()

  const [iconConcepts, setIconConcepts] = useState()
  const [iconConceptsEs, setIconConceptsEs] = useState()

  useEffect(() => {
    if (open && !iconConcepts && !iconConceptsEs) {
      import('../../icons/iconConcepts.ts').then(module => setIconConcepts(module.iconsConcepts))
      import('../../icons/iconConcepts_es.ts').then(module => setIconConceptsEs(module.iconsConceptsEs))
    }
  }, [iconConcepts, iconConceptsEs, open])

  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const deferredSearchQuery = useDeferredValue(searchQuery)
  // Normalize search query to remove accents
  const normalizedSearchQuery = deferredSearchQuery.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  // Escape "?" and other special characters for regex
  const escapedSearchQuery = escapeRegExp(normalizedSearchQuery)
  const searchQueryRegex = useMemo(() => new RegExp(escapedSearchQuery, 'i'), [escapedSearchQuery])

  const iconElements = useMemo(() => {
    const onClickItem = icon => {
      const iconName = icon + '.png'
      dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.MODIFIED_ICON))
      onChange(iconName)
    }
    const filteredIcons = icons.filter(icon => {
      const iconWithoutSize = icon.replace('128', '')
      return (
        iconWithoutSize.match(searchQueryRegex) ||
        iconConcepts?.[iconWithoutSize]?.some(concept => concept.match(searchQueryRegex)) ||
        iconConceptsEs?.[iconWithoutSize]?.some(concept => concept.match(searchQueryRegex))
      )
    })
    return filteredIcons.map(icon => {
      const className = cn(
        currentIcon === icon && 'border-pm-theme-light border-2 border-solid',
        'm-1 p-1 rounded-[3px] cursor-pointer hover:bg-pm-neutral-light'
      )
      return <IconCell side={ICON_SIZE} className={className} icon={icon} onClick={onClickItem} key={icon} />
    })
  }, [dispatch, iconConcepts, iconConceptsEs, currentIcon, onChange, searchQueryRegex])

  const handleChangeSearchQuery = useCallback((event, data) => setSearchQuery(data.value), [])

  return (
    <Dialog
      open={open}
      onOpenChange={(ev, data) => {
        if (!data.open) onDismiss?.()
      }}
    >
      <DialogSurface className="!h-screen !max-h-screen md:!max-h-[30rem]">
        <DialogBody className="!h-full">
          <DialogTitle>{t('item.icon.select_icon')}</DialogTitle>
          <DialogContent className="flex flex-col">
            <Input
              value={searchQuery}
              onChange={handleChangeSearchQuery}
              placeholder={t('general.search')}
              autoFocus
              className="w-full"
            />
            <div className="flex w-full flex-wrap items-center justify-center overflow-y-auto">{iconElements}</div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger>
              <Button>{t('general.close')}</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
