import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

export const useTextSearch = ({ setTextFilter, globalTextSearch, updateURL = true, filterType }) => {
  
  // Hook for updating URLs is implemented inline to avoid conditional hook calls
  const actualSetTextFilter = useCallback((searchText) => {
    // First call the provided setter
    setTextFilter(searchText)
    
    // Then update URL if requested
    if (updateURL && filterType) {
      try {
        // Get the current URL and parse it
        const url = new URL(window.location.href)
        
        // Update URL
        if (searchText) {
          // Get search params as object 
          const searchParams = {}
          url.searchParams.forEach((value, key) => {
            searchParams[key] = value
          })
          
          // Update the q parameter
          searchParams['q'] = searchText
          
          // Build query string
          const queryString = Object.entries(searchParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
            
          // Update URL without reloading page
          const newUrl = `${url.origin}${url.pathname}?${queryString}`
          window.history.replaceState(null, '', newUrl)
        } else {
          url.searchParams.delete('q')
          window.history.replaceState(null, '', url.toString())
        }
      } catch (error) {
        console.error('Error updating URL with search term:', error)
      }
    }
  }, [setTextFilter, updateURL, filterType])
  
  const debouncedSetTextFilterRef = useRef(_.debounce(actualSetTextFilter, 250, { trailing: true }))
  
  const [textSearch, setTextSearch] = useState(globalTextSearch)

  useEffect(() => setTextSearch(globalTextSearch), [setTextSearch, globalTextSearch])

  const onSearchBoxChange = useCallback((evt, data) => {
    const t = data.value ?? ''
    setTextSearch(t)
    debouncedSetTextFilterRef.current(t)
  }, [])

  const onSearch = useCallback(
    text => {
      const searchText = text ?? '';
      actualSetTextFilter(searchText);
    },
    [actualSetTextFilter]
  )

  useEffect(
    () => () => {
      debouncedSetTextFilterRef.current.cancel()
    },
    []
  )
  
  return { textSearch, onSearchBoxChange, onSearch }
}
