import React, { useCallback, useEffect, useRef } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { PM_WS_URLS, SERVER_URLS } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { captureLinkClick } from '../../utils/externalLinkHandler'
import { useIsDarkTheme } from '../../themes'

const href = `${SERVER_URLS.FEED}?websocket&openLinksInNewTab`

const FEED_PATH = {
  GLOBAL: '/api/v1/comment/all/?format=json',
  PROJECT: '/api/v1/project/${ pid }/comments/?format=json',
  FOLLOWING_ITEMS: '/api/v1/comment/?format=json&following=1',
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

const IFrame = styled.iframe`
  flex: 1;
  border: 0;
  height: 100%;
`

export const FeedView = ({ projectID }) => {
  const isDarkTheme = useIsDarkTheme()
  const iFrameRef = useRef(null)
  const contentWindow = useCallback(() => iFrameRef.current?.contentWindow, [])

  const refresh = useCallback(() => {
    try {
      const window = contentWindow()
      if (window?.reloadComments) {
        if (projectID) {
          const path = _.template(FEED_PATH.PROJECT)({ pid: projectID })
          const wsURL = _.template(PM_WS_URLS.PROJECT)({ pid: projectID })
          window.reloadComments(path, wsURL)
        } else {
          window.reloadComments(FEED_PATH.GLOBAL, PM_WS_URLS.ITEM)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [projectID, contentWindow])

  useEffect(() => {
    try {
      const window = contentWindow()
      if (window) {
        window.addEventListener(
          'click',
          captureLinkClick(urlData => {
            linkSubject.next({ urlData, source: SOURCES.FEED })
          }),
          true
        )
      }
    } catch (err) {
      console.log(err)
    }
  }, [contentWindow])

  useEffect(() => {
    refresh()
  }, [projectID, refresh])

  useEffect(() => {
    contentWindow().setDarkMode?.(isDarkTheme)
  }, [contentWindow, isDarkTheme])

  const onLoad = () => {
    refresh()
    const cw = contentWindow()
    if (isDarkTheme) {
      cw.setDarkMode(true)
    }
  }

  return (
    <Container>
      <IFrame ref={iFrameRef} src={href} onLoad={onLoad} />
    </Container>
  )
}
